import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import {
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';

import { SharedModule } from '../shared/shared.module';
import { DnsManagerComponent } from './dns-manager.component';

@NgModule({
  declarations: [
    DnsManagerComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule
  ]
})
export class DnsManagerModule { }
