import {
  HttpClient,
  HttpErrorResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';

import {
  Observable,
  throwError,
} from 'rxjs';
import {
  catchError,
  map,
} from 'rxjs/operators';

import {
  AppId,
  RouteResolverService,
} from '@skykick/core';


import { CompleteMemberProfileRequest } from '../models/complete-member-profile-request';
import { ValidateInvitationResponse } from '../models/validate-invitation-response';
import { CompleteMemberProfileResponse } from '../models/complete-member-profile-response';

@Injectable({
    providedIn: 'root'
})
export class MemberProfileService {
    constructor(
      private http: HttpClient,
      private routeResolverService: RouteResolverService) { }

    validateInvitation(token: string): Observable<ValidateInvitationResponse> {
      return this.http.post<ValidateInvitationResponse>(
          `${this.routeResolverService.generatePipeRoute(AppId.PlatformApis)}publicauthenticationprovider/api/partner/validateinvitation?token=${encodeURIComponent(token)}`,
          null)
        .pipe(
          catchError(this.handleError)
        );
    }
  
    completeProfile(completeMemberProfileRequest: CompleteMemberProfileRequest, token: string): Observable<CompleteMemberProfileResponse> {
      return this.http.post<CompleteMemberProfileResponse>(
          `${this.routeResolverService.generatePipeRoute(AppId.PlatformApis)}publicauthenticationprovider/api/partner/completeprofile?token=${encodeURIComponent(token)}`,
          completeMemberProfileRequest)
        .pipe(
          catchError(this.handleError)
        );
    }

    private handleError(errorResponse: HttpErrorResponse): Observable<never> {
      console.error('Error occurred in MemberProfileService', errorResponse);
      return throwError(errorResponse);
    }
}
