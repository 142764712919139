import { Component, OnInit } from '@angular/core';
import { AppId, RouteResolverService } from '@skykick/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'sk-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  constructor(private routeResolverService: RouteResolverService) {}

  ngOnInit(): void {}

  getMicrosoftPartnerCenterRoute(): string {
    if (
      environment.runtimeEnvironmentName.toUpperCase() == 'prod'.toUpperCase()
    ) {
      //TODO - This is a temporary fix until MPC is ported.
      return 'https://authv1.skykick.com/partner/admin/manage-microsoft-partner-center/#/';
    } else {
      return this.routeResolverService.generateRatRoute(
        AppId.Portal,
        '/partner/admin/manage-microsoft-partner-center/#/'
      );
    }
  }
}
