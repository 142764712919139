import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { AbstractUserProvider } from '@skykick/platform-identity-auth-auth0-angular';
import { MembersRoleProvider } from '../components/members/services/members.role.provider';

@Injectable({
    providedIn: 'root'
})
export class AdminUserGuard implements CanActivate {

    constructor(
        private router: Router,
        private abstractUserProvider: AbstractUserProvider
    ) { }

    canActivate(_: ActivatedRouteSnapshot, __: RouterStateSnapshot): boolean {
        const isCurrentUserAdmin =
            this.abstractUserProvider.getCurrentUser().hasPermission(MembersRoleProvider.PartnerPortalAdmin.key);

        if (!isCurrentUserAdmin) {
            this.router.navigate(['settings']);
        }
        return isCurrentUserAdmin;
    }
}
