import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AccountRoutingModule } from './account-routing.module';
import { AccountComponent } from './account/account.component';
import { CompanyProfileComponent } from './company-profile/company-profile.component';
import { CreditCardModule, StatusSpinnerModule } from '@skykick/core';
import { ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ToastrModule } from 'ngx-toastr';
import { TranslateModule } from '@ngx-translate/core';
import { AuthenticationComponent } from './authentication/authentication.component';
import { Ms365AuthenticationComponent } from './authentication/modals/ms365-authentication/ms365-authentication.component';
import { MatStepperModule } from '@angular/material/stepper';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input'
import { ClipboardModule } from '@angular/cdk/clipboard';
import { PlatformBrandingComponent } from './platform-branding/platform-branding.component';
import { FontAwesomeModule, FaIconLibrary } from "@fortawesome/angular-fontawesome";
import { faUpload } from "@fortawesome/free-solid-svg-icons";
import { DistributorsComponent } from './distributors/distributors.component';
import { AddEditDistributorRelationshipComponent } from './distributors/modals/add-edit-distributor-relationship/add-edit-distributor-relationship.component';

@NgModule({
    declarations: [
        AccountComponent,
        CompanyProfileComponent,
        AuthenticationComponent,
        Ms365AuthenticationComponent,
        PlatformBrandingComponent,
        DistributorsComponent,
        AddEditDistributorRelationshipComponent
    ],
    imports: [
        CommonModule,
        AccountRoutingModule,
        StatusSpinnerModule,
        ReactiveFormsModule,
        NgbModule,
        ToastrModule.forRoot(),
        TranslateModule.forChild({
            extend: true
        }),
        MatStepperModule,
        MatFormFieldModule,
        MatInputModule,
        ClipboardModule,
        FontAwesomeModule,
        CreditCardModule
    ],
    providers: [
        NgbModule
    ]
})
export class AccountModule {
    constructor(private library: FaIconLibrary) {
        library.addIcons(faUpload);
    }
}
