import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { NavigationArea } from '@skykick/core';
import { CommunicationsComponent } from '../communications/communications.component';
import { DeveloperComponent } from '../developer/developer.component';
import { DnsManagerComponent } from '../dns-manager/dns-manager.component';
import { HomeComponent } from '../home/home.component';
import { SETTINGS_ROUTES } from '../settings/settings-routing.module';
import { AuthGuard } from '../utilities/AuthGuard';
import { PortalComponent } from './portal.component';

export const PORTAL_ROUTES: Routes = [
  {
    path: '',
    component: PortalComponent,
    children: [
      {
        path: '',
        component: HomeComponent,
        canActivate: [AuthGuard],
        data: {
          SkNavigationArea: NavigationArea.Home,
        },
      },
      {
        path: 'partner/dns-manager',
        component: DnsManagerComponent,
        canActivate: [AuthGuard],
        data: {
          SkNavigationArea: NavigationArea.DNS,
        },
      },
      {
        path: 'partner/developer',
        component: DeveloperComponent,
        data: {
          SkNavigationArea: NavigationArea.Developer,
        },
      },
      {
        path: 'partner/communications',
        component: CommunicationsComponent,
        canActivate: [AuthGuard],
        data: {
          SkNavigationArea: NavigationArea.Communications,
        },
      },
      ...SETTINGS_ROUTES,
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(PORTAL_ROUTES)],
  exports: [RouterModule],
})
export class PortalRoutingModule {}
