import {
    HttpClientModule,
} from '@angular/common/http';
import {
    NgModule,
} from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CardModule } from '@skykick/core';
import { SharedModule } from '../shared/shared.module';
import { SharedModule as SettingsSharedModule } from '../settings/shared/shared.module';
import { CompleteMemberProfileFormComponent } from './complete-member-profile-form.component';
 
@NgModule({
    declarations: [
        CompleteMemberProfileFormComponent
    ],
    imports: [
        CardModule,
        HttpClientModule,
        NgbModule,
        SharedModule,
        SettingsSharedModule,
        FormsModule,
        ReactiveFormsModule
    ],
    bootstrap: [CompleteMemberProfileFormComponent]
})
export class CompleteMemberProfileFormModule {}
  