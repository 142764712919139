import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import {
    catchError,
    map,
} from 'rxjs/operators';

import {
    AppId,
    RouteResolverService,
} from '@skykick/core';

import { Utils } from '../../../../../services/utils';
import { AddM365MemberRequest } from '../models/add-m365-member-request';
import { EditMemberRequest } from '../models/edit-member-request';
import { InviteMemberRequest } from '../models/invite-member-request';
import { M365User } from '../models/m365-user';
import { M365UserResult } from '../models/m365-user-result';
import { M365UserSearchFilter } from '../models/m365-user-search-filter';
import { Member } from '../models/member';
import { MemberResult } from '../models/member-result';
import { MembersSearchFilter } from '../models/members-search-filter';
import { M365UserConverter } from './m365-user.converter';
import { MembersConverter } from './members.converter';
import MemberCreatedResponse from '../models/member-created-response';

@Injectable({
    providedIn: 'root'
})
export class MembersService {
    private basePath = `${this.routeResolverService.generatePipeRoute(AppId.PlatformApis)}authenticationprovider/api/partner`;

    constructor(private http: HttpClient,
        private membersConverter: MembersConverter,
        private routeResolverService: RouteResolverService,
        private m365UserConverter: M365UserConverter) { }

    getMember(contactId: string): Observable<Member> {
        return this.http.get<MemberResult>(`${this.basePath}/${contactId}`)
            .pipe(
                map<MemberResult, Member>(
                    memberResult => this.membersConverter.convertToMember(memberResult)),
                catchError(error => Utils.handleError(error, MembersService.name))
            );
    }

    getMembers(filter: MembersSearchFilter): Observable<Member[]> {
        return this.http.post<MemberResult[]>(
            `${this.basePath}/search`, filter)
            .pipe(
                map<MemberResult[], Member[]>(
                    resultArray => resultArray.map(res => this.membersConverter.convertToMember(res))),
                catchError(error => Utils.handleError(error, MembersService.name))
            );
    }

    getM365Users(filter: M365UserSearchFilter): Observable<M365User[]> {
        return this.http.get<M365UserResult[]>(`${this.basePath}/m365/search?searchTerm=${filter.searchTerm}&topCount=${filter.topCount}`)
            .pipe(
                map<M365UserResult[], M365User[]>(
                    resultArray => resultArray.map(res => this.m365UserConverter.convertToM365User(res))),
                catchError(error => Utils.handleError(error, MembersService.name))
            );
    }

    addM365Member(addMember: AddM365MemberRequest): Observable<MemberCreatedResponse> {
        return this.http.post<any>(`${this.basePath}/m365`, addMember)
            .pipe(
                catchError(error => Utils.handleError(error, MembersService.name))
            );
    }

    editMember(editMember: EditMemberRequest): Observable<boolean> {
        return this.http.put<any>(`${this.basePath}`, editMember, { observe: 'response' })
            .pipe(
                map(response => response.ok),
                catchError(error => Utils.handleError(error, MembersService.name))
            );
    }

    deactivateMember(memberId: string): Observable<boolean> {
        return this.http.post<any>(
            `${this.basePath}/${memberId}/deactivate`,
            null,
            { observe: 'response' })
            .pipe(
                map(response => response.ok),
                catchError(error => Utils.handleError(error, MembersService.name))
            );
    }

    deleteMember(memberId: string): Observable<any> {
      return this.http.delete<any>(`${this.basePath}/${memberId}`, { observe: 'response' })
        .pipe(
            map(response => response.ok),
            catchError(error => Utils.handleError(error, MembersService.name))
        );
    }

    activateMember(memberId: string): Observable<boolean> {
        return this.http.post<any>(
            `${this.basePath}/${memberId}/activate`,
            null,
            { observe: 'response' })
            .pipe(
                map(response => response.ok),
                catchError(error => Utils.handleError(error, MembersService.name))
            );
    }

    resetPassword(userName: string): Observable<boolean> {
        return this.http.post<any>(
            `${this.basePath}/resetpassword?userName=${encodeURIComponent(userName)}`,
            null,
            { observe: 'response' })
            .pipe(
                map(response => response.ok),
                catchError(error => Utils.handleError(error, MembersService.name))
            );
    }

    inviteMember(inviteMemberRequest: InviteMemberRequest): Observable<MemberCreatedResponse> {
        return this.http.post<any>(`${this.basePath}/invite`, inviteMemberRequest)
            .pipe(
                catchError(error => Utils.handleError(error, MembersService.name))
            );
    }

    sendInvitation(memberId: string): Observable<boolean> {
        return this.http.post<any>(
            `${this.basePath}/${memberId}/sendinvitation`,
            null,
            { observe: 'response' })
            .pipe(
                map(response => response.ok),
                catchError(error => Utils.handleError(error, MembersService.name))
            );
    }

    revokeInvitation(memberId: string): Observable<boolean> {
        return this.http.post<any>(
            `${this.basePath}/${memberId}/revokeinvitation`,
            null,
            { observe: 'response' })
            .pipe(
                map(response => response.ok),
                catchError(error => Utils.handleError(error, MembersService.name))
            );
    }

    memberExists(userName: string): Observable<boolean> {
        return this.http.get<boolean>(
                `${this.basePath}/exists?userName=${encodeURIComponent(userName)}`)
            .pipe(
                catchError(error => Utils.handleError(error, MembersService.name))
            );
    }
}
