import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MigrationNotificationSettingsComponent } from './components/migration-notification-settings/migration-notification-settings.component';
import { BackupNotificationSettingsComponent } from './components/backup-notification-settings/backup-notification-settings.component';
import { AccountNotificationSettingsComponent } from './components/account-notification-settings/account-notification-settings.component';
import { NotificationSettingsFormComponent } from './components/notification-settings-form/notification-settings-form.component';
import { LayoutModule, InputModule, StatusSpinnerModule } from '@skykick/core';
import { SharedModule } from '../shared/shared.module';
import { UsersRoutingModule } from '../users/users-routing.module';
import { NotificationSettingsComponent } from './components/notification-settings.component';
import {
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ToastrModule } from 'ngx-toastr';


@NgModule({
  declarations: [
    NotificationSettingsComponent,
    NotificationSettingsFormComponent,
    MigrationNotificationSettingsComponent,
    BackupNotificationSettingsComponent,
    AccountNotificationSettingsComponent
  ],
  imports: [
    CommonModule,
    LayoutModule,
    SharedModule,
    UsersRoutingModule,
    InputModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    ToastrModule.forRoot(),
    StatusSpinnerModule,
  ]
})
export class NotificationSettingsModule { }
