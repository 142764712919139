<div class="card mt-200" id="setupo365authentication">
    <div class="card-header sk-card-header d-flex justify-content-between">
        <h4 translate="settings.O365.AUTHENTICATION"></h4>
    </div>
    <div class="card-block">

        <ngb-alert *ngIf="hasOngoingAuthFlow()" type="info" class="d-flex justify-content-between mb-100" [dismissible]="false">
            <div class="d-flex">
                <i class="material-icons md-22">info_outline</i>
                <div class="mx-100">
                    <span class="sk-alert-text" [innerHTML]="'settings.O365.INPROGRESS_MESSAGE' | translate"></span>
                </div>
            </div>
            <div clas="flex-shrink-0">
                <button id="o365-authentication-continue" type="button" class="btn btn-outline d-inline-block" translate="settings.O365.INPROGRESS_BTN"
                    (click)="openAuthFlowModal()"></button>
            </div>
        </ngb-alert>

        <ngb-alert type="danger" *ngIf="partnerAuthentication && partnerAuthentication.authenticationType === AuthenticationType.O365Auth && m365AuthConnectionStatus === M365ConnectionStatuses.reauthenticationRequired" class="d-flex justify-content-between mb-100" [dismissible]="false">
            <div class="d-flex">
                <i class="material-icons md-22">error_outline</i>
                <div class="mx-100">
                    <span class="sk-alert-text">
                        <strong translate="settings.O365.ACCOUNT_O365_BAD_STATE_AUTH_TITLE"></strong> - <span translate="settings.O365.ACCOUNT_O365_BAD_STATE_AUTH_MESSAGE"></span>
                    </span>
                </div>
            </div>
            <div clas="flex-shrink-0">
              <!-- Note: This appears to be copied over from Portal V1, but there is no longer a saveAuthenticationSettings? -->
              <button id="o365-authentication-bad-state" type="button" class="btn btn-outline d-inline-block" translate="settings.O365.ACCOUNT_O365_BAD_STATE_REENABLE_BUTTON_TEXT"
                    (click)="saveAuthenticationSettings()"></button>
            </div>
        </ngb-alert>

        <div class="row">
            <div class="col-9">
                <sk-text-spinner *ngIf="isLoading" message="Loading authentication"></sk-text-spinner>
    
                <form *ngIf="!isLoading" [formGroup]="authSettingsForm" novalidate>
                    <p class="text-muted" translate="settings.O365.AUTHENTICATION_MESSAGE"><a href='https://support.skykick.com/hc/en-us/articles/360024880214' target='_blank' translate="settings.O365.LEARN_MORE"></a></p>

                    <fieldset class="form-group">
                        <div class="custom-control custom-radio sk-radio">
                            <input formControlName="authenticationType" [value]="0"
                                type="radio" id="authenticationType1" name="authenticationType" class="custom-control-input">
                            <label class="custom-control-label" for="authenticationType1" translate="settings.O365.SKYKICK_AUTHENTICATION"></label>
                            <small class="sk-form-text text-muted" translate="settings.O365.SKYKICK_AUTHENTICATION_MESSAGE"></small>
                        </div>
                    </fieldset>
                    
                    <fieldset class="form-group sk-form-indent">
                        <div class="custom-control custom-checkbox sk-checkbox d-flex">
                            <input type="checkbox" id="isMFAEnabled" class="custom-control-input" formControlName="isMFAEnabled">
                            <label for="isMFAEnabled" class="custom-control-label font-medium" translate="settings.O365.ENABLE_MFA"></label>
                        </div>    
                        <small class="sk-form-text text-muted" translate="settings.O365.ENABLE_MFA_MESSAGE"><a href ='https://support.skykick.com/hc/en-us/articles/360024880354' target='_blank' translate="settings.O365.LEARN_MORE"></a></small>
                    </fieldset>

                    <fieldset class="form-group">
                        <div class="custom-control custom-radio sk-radio">
                            <input formControlName="authenticationType" [value]="1"
                                type="radio" id="authenticationType2" name="authenticationType" class="custom-control-input">
                            <label class="custom-control-label" for="authenticationType2" translate="settings.O365.OFFICE_365_AUTHENTICATION"></label>
                            <small class="sk-form-text text-muted" translate="settings.O365.OFFICE_365_AUTHENTICATION_MESSAGE">
                                <a href='https://support.skykick.com/hc/en-us/articles/360020368794' target='_blank' translate="settings.O365.LEARN_MORE"></a>
                            </small>
                        </div>
                    </fieldset>

                    <fieldset class="form-group sk-form-indent">
                        <div class="custom-control custom-checkbox sk-checkbox d-flex">
                            <input type="checkbox" id="groupsSyncEnabled" class="custom-control-input" formControlName="groupsSyncEnabled">
                            <label for="groupsSyncEnabled" class="custom-control-label font-medium" translate="settings.O365.ENABLE_GROUP_SYNC"></label>
                        </div>
                        <small class="sk-form-text text-muted" translate="settings.O365.OFFICE_365_AUTHENTICATION_MESSAGE"><a href='https://support.skykick.com/hc/en-us/articles/360020368794' target='_blank' translate="settings.O365.LEARN_MORE"></a></small>
                    </fieldset>

                    <button id="submitBtn" type="button" class="btn btn-primary sk-has-spinner sk-abs-spinner mr-100"  
                        [class.active]="isBusy" 
                        [disabled]="isDisabled()"
                        (click)="updateAuthentication()">
                        <div class="sk-btn-spinner">
                            <div class="sk-spin-loader loader-dark"></div>
                        </div>
                        <span class="sk-loader-label" translate="COMMON.UPDATE"></span>    
                    </button>
                    <button id="cancelBtn" 
                        [disabled]="isDisabled()" 
                        (click)="cancel()" type="button" class="btn btn-outline-secondary" translate="COMMON.CANCEL">
                    </button>
                </form>
            </div>
        </div>
    </div>
</div>
