<div id="cloudmanager_card" class="card h-100">
  <div
    class="card-header sk-card-header d-flex align-items-center justify-content-between"
  >
    <div class="d-flex align-items-center">
      <i class="material-icons md-24 icon-primary mr-100">cloud_done</i>
      <h4 translate>NAV.CLOUDMANAGER</h4>
    </div>
    <div>
      <a id="cloudmanager_card_arrow_button" class="btn sk-btn-icon sk-btn-icon pr-0" href="https://cm.skykick.com/">
        <i class="material-icons md-20 icon-muted">keyboard_arrow_right</i>
      </a>
    </div>
  </div>
  <div class="card-body">
    <div
      *ngIf="customerCountLoading || commandCountLoading; else displayCardBody"
    >
      <div class="d-flex align-items-center flex-column">
        <div class="sk-spin-loader" ng-class="size"></div>
      </div>
    </div>
    <ng-template #displayCardBody>
      <div
        *ngIf="
          viewState == ViewStates.showActivateTrialPrompts;
          else displaySubscriptionDetails
        "
      >
        <p translate>home.cards.cloudmanager_body_default</p>
        <a id="cloudmanager_watch_demo_link" href="https://support.skykick.com/hc/en-us/articles/1500000209961-Cloud-Manager-Overview-Demo" translate
          >home.cards.links.watch_demo</a
        >
      </div>
      <ng-template #displaySubscriptionDetails>
        <div class="row">
          <div *ngIf="!showBlurb" [@animateIn] class="col-md-6">
            <h1 class="display-4">{{ customerCount }}</h1>
            <a class="link-primary" translate>home.cards.customers</a>
          </div>
          <div *ngIf="!showBlurb" [@animateIn] class="col-md-6">
            <h1 class="display-4">{{ commandCount }}</h1>
            <a class="link-primary" translate>home.cards.commands_run</a>
          </div>
        </div>
      </ng-template>
    </ng-template>
  </div>
  <!--Start Information Banners-->
  <div
    *ngIf="
      !customerCountLoading &&
      !commandCountLoading &&
      viewState == ViewStates.showAddCustomersPrompts
    "
    id="sk-cloud-manager-customers-learn-more-link"
    role="button"
    class="d-flex px-100 py-50 bg-teal-100 align-items-center justify-content-between"
    (click)="onAddCustomersLearnMoreClicked()"
    [@expandIn]
  >
    <div class="d-flex align-items-center">
      <i class="material-icons md-22 icon-info pr-50">lightbulb_outline</i>
      <div class="text-dark font-weight-medium" translate>
        home.cards.add_customers_info
      </div>
    </div>
  </div>
  <div
    *ngIf="
      !customerCountLoading &&
      !commandCountLoading &&
      viewState == ViewStates.showRunCommandPrompts
    "
    id="sk-cloud-manager-customers-learn-more-link"
    role="button"
    class="d-flex px-100 py-50 bg-teal-100 align-items-center justify-content-between"
    (click)="onRunCommandsLearnMoreClicked()"
    [@expandIn]
  >
    <div class="d-flex align-items-center">
      <i class="material-icons md-22 icon-info pr-50">lightbulb_outline</i>
      <div class="text-dark font-weight-medium" translate>
        home.cards.run_commands_info
      </div>
    </div>
  </div>
  <!--End Information Banners-->
  <!--Start Action buttons-->
  <div class="card-footer d-flex align-items-center justify-content-between"style="min-height:54.5px;">
    <div class="d-flex align-items-center">
      <button
        *ngIf="viewState == ViewStates.showActivateTrialPrompts"
        id="cloudmanager_free_trial_button"
        type="button"
        class="btn btn-secondary-outline mr-100 text-truncate"
      >
        <a [href]="getFreeTrialUrl()" translate>home.cards.buttons.book_a_demo</a>
      </button>
      <button
        *ngIf="viewState == ViewStates.showAddCustomersPrompts"
        id="cloudmanager_add_customer_button"
        type="button"
        class="btn btn-secondary-outline mr-100 text-truncate"
      >
        <a [href]="getAddCustomerUrl()" translate
          >home.cards.buttons.add_customer</a
        >
      </button>
      <button
        *ngIf="
          viewState == ViewStates.showCommandCenterPrompts ||
          viewState == ViewStates.showRunCommandPrompts
        "
        id="cloudmanager_go_to_command_center_button"
        type="button"
        class="btn btn-secondary-outline mr-100 text-truncate"
      >
        <a [href]="getCommandCenterUrl()" translate
          >home.cards.buttons.go_to_command_center</a
        >
      </button>
    </div>
    <!-- TODO - <span class="small text-muted">16 days left in trial</span> -->
  </div>
  <!--End Action Buttons-->
</div>
