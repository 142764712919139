import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import { CommunicationDetails } from '../models/CommunicationDetails';

@Component({
  selector: 'sk-communications-modal',
  templateUrl: './communications-modal.component.html',
  styleUrls: ['./communications-modal.component.scss']
})
export class CommunicationsModalComponent implements OnInit {

  closeResult = '';

  html: string

  @Input() communicationDetails: CommunicationDetails;

  constructor(private modalService: NgbModal, private http: HttpClient) {

  }

  ngOnInit(): void {
    this.http
        .get('./assets/communications/templates/' + this.communicationDetails.templateFileName,
             { responseType: 'text' })
        .subscribe(data => {
            this.html = data
        }
    );

  }

  open(content) {

    this.modalService.open(
      content,
      {
        ariaLabelledBy: 'modal-basic-title',
        size:'lg',
      }
    ).result.then(
      (result) =>
      {
        this.closeResult = `Closed with: ${result}`;
      },
      (reason) =>
      {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      }
    );

  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }


}
