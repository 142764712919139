<div class="sk-flex-table">
    <div class="sk-scroll-container">
        <div class="d-flex flex-column min-width-sm max-width-lg py-200">
            <div class="row">
                <div class="col-8">
                    <h3>Ticket Mapping</h3>
                    <br/>
                    <h5>Map to ConnectWise Service Board</h5>
                    <div ngbDropdown class="btn-group" style="width: 400px">
                        <button ngbDropdownToggle [class.active]="isLoadingServiceBoards" type="button" id="menuScrollBtn" class="btn btn-outline dropdown-toggle sk-has-spinner sk-abs-spinner" style="justify-content: space-between;" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <div class="sk-btn-spinner">
                                <div class="sk-spin-loader loader-dark"></div>
                            </div>
                            <span class="sk-loader-label" translate>{{ selectedTicketMappingName ? selectedTicketMappingName : "Select Service Board" }}</span>
                        </button>
                        <div ngbDropdownMenu [style.visibility]="isLoadingServiceBoards ? 'hidden' : 'visible'" class="dropdown-menu sk-menu-input --sk-form-menu" style="width: 400px">
                            <div class="sk-input" style="max-width: none">
                                <input #searchBoardsInput class="form-control" type="text" placeholder="Search">
                            </div>
                            <div class="dropdown-divider"></div>

                            <div id="skScrollContainer" class="sk-menu-scroll">
                                <a *ngFor="let board of connectWiseServiceBoards$ | async" [ngClass]="{'active': board.name === selectedTicketMappingName}" (click)="selectBoard(board)" ngbDropdownItem class="dropdown-item">
                                    {{ board.name }}
                                </a>
                            </div>
                        </div>
                    </div>

                    <button type="button" (click)="addMapping()" [class.active]="isBusy" [disabled]="isLoadingServiceBoards || isBusy || !selectedTicketMappingName" class="btn btn-primary sk-has-spinner sk-abs-spinner ml-100">
                        <div class="sk-btn-spinner">
                            <div class="sk-spin-loader loader-dark"></div>
                        </div>
                        <span class="sk-loader-label" translate>Add Mapping</span>
                    </button>
                </div>
                <div class="col-4">
                </div>
            </div>
        </div>
    </div>
</div>
