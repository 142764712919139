import { Component, HostBinding, OnDestroy, OnInit } from '@angular/core';
import { BreadcrumbService } from '../shared/breadcrumbs/breadcrumb.service';

@Component({
    selector: 'sk-microsoft-partner-center',
    templateUrl: './microsoft-partner-center.component.html',
    styleUrls: ['./microsoft-partner-center.component.scss']
})
export class MicrosoftPartnerCenterComponent implements OnInit, OnDestroy {
    @HostBinding('class.sk-content-column') isActive = true;

    constructor(
        private breadcrumbService: BreadcrumbService,
    ) { }

    ngOnInit(): void {
        this.breadcrumbService.push('Microsoft Partner Center', '/settings/integrations/microsoftpartnercenter/details');
    }

    ngOnDestroy(): void {
        this.breadcrumbService.pop();
    }

}
