import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { AuthenticationType } from '../models/authentication-type';
import { forkJoin } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';
import { Ms365AuthenticationComponent } from './modals/ms365-authentication/ms365-authentication.component';
import { AuthenticationMethodResourcesService } from '../services/authentication-method-resources.service';
import { PartnerAuthentication } from '../models/partner-authentication';
import { SkyKickModal, SkyKickModalOptions, SkyKickModalService, SkyKickModalWarningOptions, WarningModalComponent } from '@skykick/core';
import { M365ConnectionStatuses } from '../constants/m365ConnectionStatus.constant';

@Component({
    selector: 'sk-authentication',
    templateUrl: './authentication.component.html',
    styleUrls: ['./authentication.component.scss']
})
export class AuthenticationComponent implements OnInit {
    private overlayConfig: NgbModalOptions = { backdrop: 'static', windowClass: 'modal-panel modal-right', size: 'lg' };
    AuthenticationType = AuthenticationType;
    M365ConnectionStatuses = M365ConnectionStatuses;
    debugEnabled = false;
    isLoading: boolean;
    authSettingsForm: FormGroup;
    isBusy: boolean;
    partnerAuthentication: PartnerAuthentication;
    groupSyncStatus: any;
    m365AuthConnectionStatus = null;
    

    private optionsError: SkyKickModalOptions = {
        title: this.translate.instant('ERROR'),
        body: this.translate.instant('COMMON.PLEASE_TRY_AGAIN_OR_CONTACT'),
        btnLabel: this.translate.instant('COMMON.CLOSE')
    };


    private debug(args: any) {
        if (!this.debugEnabled) return;
        const logValue = JSON.stringify(args);
        console.debug("AuthenticationComponent", logValue);
    }

    private async savePartnerAuthenticationSettings() {
        const self = this;
        self.debug("savePartnerAuthenticationSettings");
        const payload = {
            AuthenticationType: self.authSettingsForm.get('authenticationType').value,
            IsMFAEnabled: self.authSettingsForm.get('isMFAEnabled').value,
            UsersToMap: self.partnerAuthentication.unMappedUsers
        };

        this.isBusy = true;

        try {
            self.debug(payload);

            await this.authenticationMethodResourcesService.savePartnerAuthenticationSettings(payload);

            if (this.partnerAuthentication.authenticationType === AuthenticationType.O365Auth) {
                await this.authenticationMethodResourcesService.disableM365GroupSync();
            }

            // If we actually updated the value, show success.
            if (this.authSettingsForm.get('authenticationType').value !== this.partnerAuthentication.authenticationType) {
                this.toastrService.success(this.translate.instant('settings.O365.SKYKICK_AUTH_SUCCESS'));
            }
            if (this.authSettingsForm.get('isMFAEnabled').value) {
                this.toastrService.success(this.translate.instant('settings.O365.MFA_SETTING_SUCCESS'));
            }
            if (!this.authSettingsForm.get('isMFAEnabled').value && this.authSettingsForm.get('authenticationType').value === this.partnerAuthentication.authenticationType) {
                this.toastrService.success(this.translate.instant('settings.O365.MFA_SETTING_DISABLED'));
            }
    
            this.partnerAuthentication.authenticationType = this.authSettingsForm.get('authenticationType').value;
            this.partnerAuthentication.isMFAEnabled = this.authSettingsForm.get('isMFAEnabled').value;

            // Uncheck the box for group sync enabled.
            this.groupSyncStatus.groupsSyncEnabled = false;
            this.authSettingsForm.get('groupsSyncEnabled').setValue(this.groupSyncStatus.groupsSyncEnabled); 
            this.authSettingsForm.markAsPristine();       
    
        } catch (error) {
            self.debug(error);
            this.isBusy = false;
            this.toastrService.error(this.translate.instant('Error Changing O365 Login for Partner'));
        } finally {
            this.isBusy = false;
        }
    }

    constructor(
        private toastrService: ToastrService,
        private formBuilder: FormBuilder,
        private translate: TranslateService,
        private modalService: NgbModal,
        private authenticationMethodResourcesService: AuthenticationMethodResourcesService,
        private skyKickModalService: SkyKickModalService
    ) { }

    ngOnInit(): void {
        const self = this;
        this.debug("ngOnInit");
        this.authSettingsForm = this.formBuilder.group({
            authenticationType: [null, [Validators.required]], // SkyKick Authentication or Microsoft 365 Authentication
            isMFAEnabled: [null], // checkbox for enabling multi-factor authentication
            groupsSyncEnabled: [null] // checkbox for enabling Microsoft 365 group sync 
        });

        this.authSettingsForm.get('authenticationType').valueChanges.pipe(
            distinctUntilChanged(),
        ).subscribe(value => {
            // MFA only makes sense for SkyKick Authentication.
            if (value === AuthenticationType.O365Auth) {
                this.authSettingsForm.get('isMFAEnabled').disable();
            }
            if (value === AuthenticationType.SkyKickAuth) {
                this.authSettingsForm.get('isMFAEnabled').enable();
            }
            // Group sync only makes sense for Microsoft 365 Authentication.
            if (value === AuthenticationType.O365Auth) {
              this.authSettingsForm.get('groupsSyncEnabled').enable();
            }
            if (value === AuthenticationType.SkyKickAuth) {
              this.authSettingsForm.get('groupsSyncEnabled').disable();
            }
        });

        // When the form first loads
        this.isLoading = true;
        forkJoin({
            // Three calls to PAPI.
            partnerAuthSettings: this.authenticationMethodResourcesService.getPartnerAuthenticationSettings(),
            groupSyncStatus: this.authenticationMethodResourcesService.getGroupSyncStatus(),
            m365AuthConnectionStatus: this.authenticationMethodResourcesService.getM365AuthConnectionStatus()
        }).subscribe(res => {
            self.debug('forkJoin complete');
            self.debug(res);
            this.partnerAuthentication = res.partnerAuthSettings;
            this.partnerAuthentication.oAuthFlowState = this.partnerAuthentication.oAuthFlowState ? JSON.parse(this.partnerAuthentication.oAuthFlowState) : null;
            this.groupSyncStatus = res.groupSyncStatus;
            this.m365AuthConnectionStatus = res.m365AuthConnectionStatus;

            // Set the form values to what they currently are.
            this.authSettingsForm.patchValue(res.partnerAuthSettings);
            this.authSettingsForm.patchValue(res.groupSyncStatus);
            this.isLoading = false;

            // It doesn't make sense to have group sync as a form option if we aren't using O365 Auth.
            if (this.partnerAuthentication.authenticationType !== AuthenticationType.O365Auth) {
                this.authSettingsForm.get('groupsSyncEnabled').disable();
            }
            
            // Note: This was copied from portal v1 AdminManageAccountController.init() to fix an issue where this was missing.
            // If there is #setupo365authentication at the end of the URL, it is likely we need to take the user to step 3 of the O365Auth setup process.
            const hash = window.location.hash; // e.g. #setupo365authentication
            if (hash && hash.length > 1) {
                const sectionId = hash.substring(1); 
                self.locationHashFound(sectionId);
            }
        });
    }// end ngOnInit

    locationHashFound(elementId): void {
        const self = this;
        self.debug("Jumping to section " + elementId);
        const element = document.getElementById(elementId);
        if (elementId === 'setupo365authentication') {
            // We came back from AccessManagement success page.
            // Save changes and this should bring us to the third step.
            // this.model.authenticationType = this.constants.AuthenticationTypes.O365;
            // Portal v1 for reference:
            // window.setTimeout(this.scrollToId.bind(this), 2000, 'Authentication');
            // window.setTimeout(this.saveAuthenticationSettings(), 3000);
            window.setTimeout(function () {
                self.debug("scrolling to section timeout");
                self.authSettingsForm.get('authenticationType').patchValue(AuthenticationType.O365Auth);
                element.scrollIntoView();
            }, 2000);
            window.setTimeout(function () {
                self.debug("savePartnerAuthenticationSettings timeout");
                self.openAuthFlowModal();
            }, 3000);
        } else {
            // Scrolls to the specified anchor tag after page is loaded.
            window.setTimeout(function () {
                self.debug("scrolling to section timeout");
                element.scrollIntoView();
            }, 2000);
        }
    }// end jumpToSection

    updateAuthentication(): void {
        // Called when the user hits the 'update' button.
        this.debug("updateAuthentication");
        if (this.authSettingsForm.valid && !this.authSettingsForm.pristine) {

            // SkyKick Auth Case
            if (this.authSettingsForm.get('authenticationType').value === AuthenticationType.SkyKickAuth) {

                // If the authentication type matches what is already setup, mark the form as pristine, and return early.
                if (this.authSettingsForm.get('authenticationType').value === this.partnerAuthentication.authenticationType &&
                    this.authSettingsForm.get('isMFAEnabled').value === this.partnerAuthentication.isMFAEnabled) {
                    this.authSettingsForm.markAsPristine();
                    return;
                }

                //if had o365 auth on before, they are switching from  O365 to SkyKick authentication.
                if (this.partnerAuthentication.authenticationType === AuthenticationType.O365Auth) {
                    // Show a warning modal.
                    let warningModal: SkyKickModal<WarningModalComponent, void>;
                    const options: SkyKickModalWarningOptions = {
                        body: this.translate.instant('settings.O365.DISABLE_WARNING_HEADER'),
                        title: this.translate.instant('settings.O365.DISABLE_MODAL_TITLE'),
                        btnLabel: this.translate.instant('settings.O365.DISABLE_CONFIRM_BUTTON_TEXT'),
                        verifyLabel: this.translate.instant('settings.O365.DISABLE_CONFIRM_TEXT'),
                        alternative: {
                            btnLabel: this.translate.instant('settings.account.DISTRIBUTOR_CANCEL'),
                            btnCallback: () => {
                                warningModal.dismiss();
                                this.cancel();
                            }
                        }
                    };
                    warningModal = this.skyKickModalService.warning(options);
                    warningModal.result.then(res => {
                        if (res.wasClosed) {
                            // After they accept the warning, then save the settings.
                            this.savePartnerAuthenticationSettings();
                        }
                    });
                } else {
                    // No warning needed.
                    this.savePartnerAuthenticationSettings();
                }

            }

            // O365Auth Auth Case
            if (this.authSettingsForm.get('authenticationType').value === AuthenticationType.O365Auth) {

                // If the form matches what is already saved, mark the form as pristine, and return early.
                if (this.authSettingsForm.get('authenticationType').value === this.partnerAuthentication.authenticationType &&
                    this.authSettingsForm.get('groupsSyncEnabled').value === this.groupSyncStatus.groupsSyncEnabled) {
                    this.authSettingsForm.markAsPristine();
                    return;
                }

                // If they are turning off group sync...
                if (!this.authSettingsForm.get('groupsSyncEnabled').value && this.groupSyncStatus.groupsSyncEnabled) {
                    // Show a warning modal.
                    let warningModal: SkyKickModal<WarningModalComponent, void>;
                    const options: SkyKickModalWarningOptions = {          
                        body: this.translate.instant('settings.O365.DISABLE_GROUP_SYNC_WARNING'),
                        title: this.translate.instant('settings.O365.DISABLE_GROUP_SYNC_MODAL_TITLE'),
                        btnLabel: this.translate.instant('settings.O365.DISABLE_GROUP_SYNC_CONFIRM_BUTTON_TEXT'),
                        verifyLabel: this.translate.instant('settings.O365.DISABLE_GROUP_SYNC_CONFIRM_TEXT'),
                        alternative: {
                            btnLabel: this.translate.instant('settings.account.DISTRIBUTOR_CANCEL'),
                            btnCallback: () => {
                                warningModal.dismiss();
                                this.cancel();
                            }
                        }
                    };
                    warningModal = this.skyKickModalService.warning(options);
                    warningModal.result.then(res => {
                        if (res.wasClosed) {
                            async () => {
                                try {
                                    // They accepted the warning... let's go ahead and turn off group sync.
                                    await this.authenticationMethodResourcesService.disableM365GroupSync();
                                    
                                    this.groupSyncStatus.groupsSyncEnabled = this.authSettingsForm.get('groupsSyncEnabled').value;
                                    this.authSettingsForm.markAsPristine();
                                    this.toastrService.success(this.translate.instant('COMMON.SAVED_SUCCESSFULLY'));    
                                } catch (error) {
                                    this.skyKickModalService.error(this.optionsError);
                                }
                            }
                        }
                    });

                } else {
                    // We kick this off to the auth flow modal.
                    this.openAuthFlowModal();
                }    
            }
        }
    }

    openAuthFlowModal() {
        const self = this;
        self.debug("openAuthFlowModal");
        const modalRef = this.modalService.open(Ms365AuthenticationComponent, this.overlayConfig);
        modalRef.componentInstance.partnerAuthentication = this.partnerAuthentication;
        modalRef.componentInstance.groupsSyncEnabled = this.authSettingsForm.get('groupsSyncEnabled').value;
        modalRef.result.then(result => {
            switch (result.status) {
                case 'failure':
                    this.toastrService.error(this.translate.instant('settings.O365.ENABLING_ERROR'));
                    break;

                case 'warning':
                    this.partnerAuthentication.authenticationType = AuthenticationType.O365Auth;
                    this.partnerAuthentication.isMFAEnabled = false;
                    //this.showO365Warning = true; this would show at the top of the account page
                    this.authSettingsForm.markAsPristine();
                    break;

                case 'success':
                    this.partnerAuthentication.authenticationType = AuthenticationType.O365Auth;
                    this.partnerAuthentication.isMFAEnabled = false;
                    this.toastrService.success(this.translate.instant('settings.O365.ENABLING_SUCCESS'));
                    this.authSettingsForm.markAsPristine();
                    break;
                case 'cancel':
                    async () => {
                        // if the user cancelled let's revert the saved state back to null
                        await this.authenticationMethodResourcesService.saveOAuthFlowState(null);
                        this.cancel();
                        this.partnerAuthentication.oAuthFlowState = null;
                    };
                    break;
                case 'redirect':
                    this.authSettingsForm.markAsPristine();
                    window.location.href = result.url;
                    break;
            }

            return {
                m365AuthEnabled: result.status === 'success',
                adGroupSyncEnabled: result.adGroupSyncEnabled
            }
        }).then(result => {
            // make call to PAPI to enable AD Sync and begin syncing
            self.debug("make call to PAPI to enable AD Sync and begin syncing");
            if (result.m365AuthEnabled) {
                if (result.adGroupSyncEnabled) {
                    async () => {
                        try {
                            await self.authenticationMethodResourcesService.beginM365GroupSync();
                            self.groupSyncStatus.groupsSyncEnabled = true;

                            // Resetting the OAuthFlow State
                            self.debug("Resetting flow state");
                            await self.authenticationMethodResourcesService.saveOAuthFlowState(null);

                            // Remove the hashtag and reload the page.
                            // The hashtag is only there for jumping to step 3.
                            window.location.href = '/settings/account';
                            window.location.reload();                                
                        } catch (e) {
                            this.skyKickModalService.error(this.optionsError);
                        }
                    }
                } else {
                    self.debug("Finished! Reload the page.");
                    window.location.href = '/settings/account';
                    window.location.reload();
                }
            } else {
                self.debug("m365AuthEnabled is not true");
            }
        }).catch((res) => {
            self.debug(res);
            self.debug('Cancelling');
            this.cancel();
        });
    }

    hasOngoingAuthFlow() {
        let result = false;
        if (this.partnerAuthentication && this.partnerAuthentication.oAuthFlowState && typeof this.partnerAuthentication.oAuthFlowState !== 'string') {
            result = !!this.partnerAuthentication.oAuthFlowState.authorizationStarted;
        }
        this.debug("hasOngoingAuthFlow: " + result);
        return result;
    }

    isDisabled() {
        if (this.authSettingsForm.invalid || this.isBusy || this.authSettingsForm.pristine || (this.partnerAuthentication.authenticationType === AuthenticationType.O365Auth && this.m365AuthConnectionStatus === M365ConnectionStatuses.reauthenticationRequired)) {
            return true;
        }
        return false;
    }

    cancel(): void {
        this.authSettingsForm.patchValue(this.partnerAuthentication);
        this.authSettingsForm.patchValue(this.groupSyncStatus);
        this.authSettingsForm.markAsPristine();
    }
}
