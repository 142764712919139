import { Component, HostBinding, Input, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal, NgbTypeaheadSelectItemEvent } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { SkyKickModalOptions, SkyKickModalService } from '@skykick/core';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subject, of } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap, map, catchError, tap } from "rxjs/operators";
import { M365ConnectionStatuses } from '../../../constants/m365ConnectionStatus.constant';
import { AuthenticationType } from '../../../models/authentication-type';
import { AuthenticationMethodResourcesService } from '../../../services/authentication-method-resources.service';

@Component({
    selector: 'sk-ms365-authentication',
    templateUrl: './ms365-authentication.component.html',
    styleUrls: ['./ms365-authentication.component.scss']
})
export class Ms365AuthenticationComponent implements OnInit {
    @HostBinding('class') class='modal-content';
    @Input() partnerAuthentication: any;
    @Input() groupsSyncEnabled: boolean;
    M365ConnectionStatuses = M365ConnectionStatuses;

    debugEnabled = false;
    grantAccessForm: FormGroup;
    misMatchForm: FormGroup;
    isLoading: boolean;
    aadUrl: any;
    mismatchedUsers = [];
    //enableSuccess = false;
    focus$ = [];
    authorizationStep: any;
    authorizationSteps = [
        {
            name: 'SETUP_AAD_ACCESS',
            state: 'account-users.o365.setup',
            active: true,
            complete: false,
            disabled: true,
            next: 'GRANT_AAD_ACCESS',
            restricted: false
        },
        {
            name: 'GRANT_AAD_ACCESS',
            state: 'account-users.o365.grant',
            active: false,
            complete: false,
            disabled: true,
            next: 'ENABLE_O365_LOGIN',
            restricted: false
        },
        {
            name: 'ENABLE_O365_LOGIN',
            state: 'account-users.o365.map-users',
            active: false,
            complete: false,
            disabled: true,
            next: null,
            restricted: false
        }
    ];


    private debug(args:any) {
      if (!this.debugEnabled) return;
      // use built-in arguments...
      console.debug("Ms365AuthenticationComponent: ", arguments);
    }

    private optionsError: SkyKickModalOptions = {
        title: this.translate.instant('ERROR'),
        body: this.translate.instant('COMMON.PLEASE_TRY_AGAIN_OR_CONTACT'),
        btnLabel: this.translate.instant('COMMON.CLOSE')
    };

    private hasOngoingAuthFlow() {
        if (this.partnerAuthentication.oAuthFlowState) {
            return !!this.partnerAuthentication.oAuthFlowState.authorizationStarted;
        }
        return false;
    }

    private populateMfaCheck() {
        // if the partner has already started the flow, use whatever they selected before
        if (this.hasOngoingAuthFlow()) {
            return this.partnerAuthentication.oAuthFlowState.confirmProperMFAUse;
        } else {
            // otherwise, default to being on
            return false;
        }
    }

    private populateGroupAccess() {
        if (this.hasOngoingAuthFlow()) {
            return this.partnerAuthentication.oAuthFlowState.userWantedGroupSync;
        } else {
            // otherwise, default to being on
            return true;
        }
    }

    private navigateToStep(name: string) {
        this.debug("navigateToStep " + name);
        let activeState = this.authorizationSteps.find(s => s.name === name);
        let activeIndex = this.authorizationSteps.findIndex(s => s.name === name);

        if (activeState) {
            this.authorizationSteps.forEach((step, index) => {
                step.active = false;
                step.disabled = true;
                if (index < activeIndex) {
                    step.complete = true;
                }
            });

            activeState.active = true;
            activeState.disabled = false;

            this.authorizationStep = activeState;
        }
    }

    private determineStepToBeOn() {
        const self = this;
        self.debug("determineStepToBeOn");
        try {
            self.debug("checking connection");
            this.authenticationMethodResourcesService.hasValidConnection().subscribe(result => {
                self.debug("connection checked");
                
                // Jump to step 3 if we need to.
                const hash = window.location.hash; // e.g. #setupo365authentication
                if (hash && hash.length > 1) {
                    self.debug("hash found");
                    const sectionId = hash.substring(1);
                    if (sectionId === "setupo365authentication") {
                        this.navigateToStep('ENABLE_O365_LOGIN');
                        return;
                    }
                }

                if (!this.partnerAuthentication.confirmProperMFAUse || !this.grantAccessForm.get('mfaCheck').value) {
                    self.debug("Resetting to step 1 because Proper MFA use checkbox not set");

                    throw Error('Proper MFA use checkbox not set')
                }
            });
        } catch (e) {
            self.debug("caught exception");
            console.error(e);
            this.navigateToStep('SETUP_AAD_ACCESS');
        } finally {
            this.isLoading = false;
        }
    }

    private async populateADUrl() {
        var payload = {
            // #setupo365authentication is key for jumping to step 3 later in the process.
            originatingUri: window.location.origin + '/settings/account#setupo365authentication'
        };
        try {
            if (this.groupsSyncEnabled) {
                this.aadUrl = await this.authenticationMethodResourcesService.getM365OAuthGroupLink(payload);
            } else {
                this.aadUrl = await this.authenticationMethodResourcesService.getM365OAuthUserLink(payload);
            }
        } catch (e) {
            throw Error(e)
        }
    }

   private async saveFlowState() {
        this.debug("saveFlowState");
        try {
            let payload = JSON.stringify({
                authorizationStarted: true,
                userWantedGroupSync: this.grantAccessForm.get('groupAccess').value,
                confirmProperMFAUse: this.grantAccessForm.get('mfaCheck').value
            });
            await this.authenticationMethodResourcesService.saveOAuthFlowState({payload});
        } catch (e) {
            this.skyKickModalService.error(this.optionsError);
        }
    }

    constructor(
        public activeModal: NgbActiveModal,
        private formBuilder: FormBuilder,
        private authenticationMethodResourcesService: AuthenticationMethodResourcesService,
        private toastrService: ToastrService,
        private translate: TranslateService,
        private skyKickModalService: SkyKickModalService
    ) { }

    ngOnInit(): void {
        this.debug("ngOnInit");
        //for step 1
        this.grantAccessForm = this.formBuilder.group({
            mfaCheck: [this.populateMfaCheck(), Validators.requiredTrue],
            groupAccess: [this.populateGroupAccess()]
        });

        this.navigateToStep('SETUP_AAD_ACCESS');
        this.determineStepToBeOn();

        //for step 3 if there are any unmatched users
        this.misMatchForm = this.formBuilder.group(
            { mismatchedUsers: this.formBuilder.array([]) }
        );
    }

    get userMismatchMappingFormArray() {
        return this.misMatchForm.get('mismatchedUsers') as FormArray;
    }

    get user(): FormGroup {
        return this.formBuilder.group({
            SKUserName: ['null'],
            O365LoginName: [null],
            isLoading: [false]
        });
    }
    
    async submitSETUP_AAD_ACCESS() {
        this.debug("Step 1: submitSETUP_AAD_ACCESS");
        this.isLoading = true;
        try {
            await this.populateADUrl();
            await this.saveFlowState();
        } catch (e) {
            this.skyKickModalService.error(this.optionsError);
        } finally {
            this.isLoading = false;
            this.navigateToStep(this.authorizationStep.next);
        }
    }

    async submitGRANT_AAD_ACCESS() {
        const self = this;
        self.debug("Step 2: submitGRANT_AAD_ACCESS");
        self.isLoading = true;
        try {
            var isUsersValid = false;
            var isGroupsValid = false;

            var data = await this.authenticationMethodResourcesService.getO365SetupLoginInfo();
            self.debug(data);
            if (data) {
                if (data.users && data.users.hasBasicAccess) {
                    if (data.users.mismatchedUsers.length > 0) {
                        data.users.mismatchedUsers.forEach((value, index) => {
                            this.userMismatchMappingFormArray.push(this.user);
                            this.mismatchedUsers.push({SKUserName: value, O365LoginName: null });
                            this.focus$.push(index);
                        });
                        this.userMismatchMappingFormArray.patchValue(this.mismatchedUsers);
                        this.focus$ = this.focus$.map(_=>new Subject<string>());
                    }// } else {
                    //     this.enableSuccess = true;
                    // }
                    
                    this.navigateToStep(this.authorizationStep.next);
                    isUsersValid = true;
                }

                isGroupsValid = this.grantAccessForm.get('groupAccess').value ? (data.groups && data.groups.hasBasicAccess) : true;

                if (isUsersValid && isGroupsValid) {
                    self.debug('Returning early');
                    return;
                } else {
                    self.debug('Problem with isUsersValid && isGroupValid');
                    this.activeModal.close({
                        status: 'redirect',
                        url: this.aadUrl
                    });
                    throw Error('Problem with isUsersValid && isGroupsValid');
                }
            } else {
                self.debug('Problem with getO365SetupLoginInfo()');
                throw Error('Problem with getO365SetupLoginInfo()');
            }
        }
        catch (err) {
            this.activeModal.close({
                status: 'redirect',
                url: this.aadUrl
            });
        } finally {
            this.isLoading = false;
        }

    }

    async submitENABLE_O365_LOGIN() {
        this.debug("Step 3: submitENABLE_O365_LOGIN");
        try {
            this.isLoading = true;

            let UsersToMap = [];
            if (this.misMatchForm.value.length) {
                UsersToMap = this.misMatchForm.value;
                UsersToMap.filter(item => (item.O365LoginName != null && item.O365LoginName != ''));
            } 

            var response = await this.authenticationMethodResourcesService.savePartnerAuthenticationSettings({
                'AuthenticationType': AuthenticationType.O365Auth,
                'IsMFAEnabled': false, // Not sure why this is set to false?
                'UsersToMap': UsersToMap,
                'ConfirmProperMFAUse': this.grantAccessForm.get('mfaCheck').value
            });

            if (response) {
                this.activeModal.close({
                    status: 'warning'
                });    
            } else {
                this.removeHash();
                this.activeModal.close({
                    status: 'success',
                    adGroupSyncEnabled: this.grantAccessForm.get('groupAccess').value
                });
            }

            this.isLoading = false;
        }
        catch (err) {
            this.activeModal.close({
                status: 'failure'
            });
        }
    }

    private removeHash(): void {
        history.pushState("", document.title, window.location.pathname + window.location.search);
    }

    copyAuthUriToClipboard() {
        // create temp element
        var copyElement = document.createElement("span");
        copyElement.appendChild(document.createTextNode(this.aadUrl));
        copyElement.id = 'tempCopyToClipboard';
        //angular.element(document.body.append(copyElement));
        document.body.append(copyElement);

        // select the text
        var range = document.createRange();
        range.selectNode(copyElement);
        window.getSelection().removeAllRanges();
        window.getSelection().addRange(range);

        // copy & cleanup
        document.execCommand('copy');
        window.getSelection().removeAllRanges();
        copyElement.remove();

        this.toastrService.success(this.translate.instant('settings.O365.LINK_COPIED'));
    }

    setUserNameFromAutoComplete(userModel, userName) {
        if (userName) {
            userModel.o365username = userModel.searchTerm = userName;
            userModel.o365UserSearchResults = [];
        }
    }
    
    typeaheadOnSelect(evt: NgbTypeaheadSelectItemEvent) {
        if (evt.item == "No Results Found") {
            evt.preventDefault();
        }
    }

    searchType(index: number) {
        return (text$: Observable<string>) => {
            return text$.pipe(
                debounceTime(200),
                distinctUntilChanged(),
                tap(() => this.userMismatchMappingFormArray.controls[index].get('isLoading').setValue(true)),
                switchMap(term => this.authenticationMethodResourcesService.searchO365LoginUserNames(term).pipe(
                    map((users) => users.map(user => user.userPrincipalName)),
                    catchError(() => {
                        return of(["No Results found"]);
                    })
                )),
                tap(() => this.userMismatchMappingFormArray.controls[index].get('isLoading').setValue(false))
            );
        };
    }

}
