import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AppId, CustomerPaymentProfile, CustomerPaymentProfileConfig, CustomerPaymentProfileService, RouteResolverService } from '@skykick/core';
import { AbstractUserProvider } from '@skykick/platform-identity-auth-auth0-angular';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AccountResourcesService } from 'src/app/settings/account/services/account-resources.service';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'sk-account',
    templateUrl: './account.component.html',
    styleUrls: ['./account.component.scss']
})
export class AccountComponent implements OnInit {

    private onDestroy$ = new Subject();
    private customerPaymentProfile: CustomerPaymentProfile;
    config: CustomerPaymentProfileConfig = {
        acceptJSHostname: environment.apis.acceptJS,
        accountingHostname: `${this.routeResolverService.generatePipeRoute(AppId.PlatformApis)}accountingservice`
    }

    constructor(
        private routeResolverService: RouteResolverService,
        private customerPaymentProfileService: CustomerPaymentProfileService
    ) { }

    ngOnInit(): void {
        this.customerPaymentProfileService.customerPaymentProfile$.pipe(
            takeUntil(this.onDestroy$)
        ).subscribe(res => {
            this.customerPaymentProfile = res;
        });
    }

    ngOnDestroy() {
        this.onDestroy$.next();
    }
}
