import { Component, Input, OnInit } from '@angular/core';
import { CommunicationDetails } from '../models/CommunicationDetails';

@Component({
  selector: 'sk-communications-tab',
  templateUrl: './communications-tab.component.html',
  styleUrls: ['./communications-tab.component.scss']
})
export class CommunicationsTabComponent implements OnInit {

  @Input() templates: CommunicationDetails[];

  constructor() { }

  ngOnInit(): void {
  }

  viewTemplate(): void {
  }

  getFirst(): CommunicationDetails {
    return this.templates[0];
  }

}
