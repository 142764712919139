import * as _ from 'lodash';

import {
  LicenseInfo,
  LicensePermission,
} from '../models/license.info';
import { LicenseInfoResult } from '../models/member-result';

export class MembersLicensesProvider {
    public static getLicenses(licenses: LicenseInfoResult[]): LicenseInfo[]{
        return licenses?.map<LicenseInfo>(license => {
            return {
                id: license.Id,
                displayName: license.DisplayName,
                permissions: license.Permissions.map<LicensePermission>(permission => {
                    return {
                        id: permission.Id,
                        name: permission.Name,
                        description: permission.Description,
                        enabled: permission.Enabled,
                        initialEnabledState: permission.Enabled
                    };
                }),
                total: license.Total,
                assigned: license.Assigned,
                enabled: license.Enabled,
            };
        });
    }
}
