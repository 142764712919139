import { HttpClient } from '@angular/common/http';
import {
  APP_INITIALIZER,
  Injectable,
} from '@angular/core';

import { environment } from '../../environments/environment';

@Injectable()
export class ConfigurationService {
    private _config: Object
    private _env: string;

    constructor(private _http: HttpClient) { }
    load() {
        return new Promise((resolve, reject) => {
            this._env = environment.runtimeEnvironmentName;
            console.log(this._env)
            this._http.get('./assets/config/' + this._env + '.json')
            .subscribe(data => {
              this._config = data;
              resolve(true);
            });

        });
    }
    // Is app in the development mode?
    isDevmode() {
        return this._env === 'development';
    }
    // Gets API route based on the provided key
    getApi(key: string): string {
        return this._config["API_ENDPOINTS"][key];
    }
    // Gets a value of specified property in the configuration file
    get(key: any) {
        return this._config[key];
    }
}

export function ConfigurationFactory(config: ConfigurationService) {
    return () => config.load();
}

export function init() {
    return {
        provide: APP_INITIALIZER,
        useFactory: ConfigurationFactory,
        deps: [ConfigurationService],
        multi: true
    }
}

const ConfigurationModule = {
    init: init
}

export { ConfigurationModule };
