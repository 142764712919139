<div class="sk-page-header">
    <div class="sk-navbar-primary single-navbar">
        <div class="d-flex align-items-center min-w-0">
            <div class="d-flex min-w-0">
                <div class="min-w-0">
                    <sk-breadcrumbs></sk-breadcrumbs>    
                </div>             
            </div>
        </div>
        <div class="d-flex" *ngIf="isInfoButtonVisible">
            <button sk-icon-button icon="info_outline" skUniversalTaskButton [taskName]="taskType" ngbTooltip="Info" placement="bottom">
            </button>
        </div>  
    </div>
</div>

<div class="sk-page-content">

    <div class="sk-content-primary">
        <router-outlet></router-outlet>

    </div>

    <!-- PANEL RIGHT -->
    <div sk-panel></div>

    <!-- <div class="sk-page-footer">
        <div class="sk-footer-content">
            <button type="button" class="btn btn-lg btn-primary mr-100">Send Invite</button>
            <button type="button" class="btn btn-lg btn-outline mr-150">Cancel</button>
        </div>
    </div> -->
</div>