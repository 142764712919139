import { Component, OnInit } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CommunicationDetails } from './models/CommunicationDetails';

@Component({
	selector: 'sk-communications',
	templateUrl: './communications.component.html',
	styleUrls: ['./communications.component.scss']
})
export class CommunicationsComponent implements OnInit {

  	tab: Number = 0

	// 
	endUserTemplates: CommunicationDetails[] = [
		{
			title:'Email Templates', 
			topText: 'SkyKick has tested the communication process over thousands of migrations. End users receive only the essential communications required to prepare for the migration.', 
			bottomText:'Please note: These are typical and primary communications. There are dozens of variations depending on the migration scenario.', 
			iconSuperscript: 'Initial Notification', 
			timingOfEmail1: 'Order + 5 Minutes',
			timingOfEmail2: '' ,
			emailReceipient: 'End User',
			subjectOfEmail: 'SkyKick Still Needs Some of Your User Information',
			templateFileName:'end.initial.preview.html'
		},
		{
			title:'Email Templates', 
			topText: 'SkyKick has tested the communication process over thousands of migrations. End users receive only the essential communications required to prepare for the migration.', 
			bottomText:'Please note: These are typical and primary communications. There are dozens of variations depending on the migration scenario.', 
			iconSuperscript: 'Password Reminder', 
			timingOfEmail1: 'Nightly @ Midnight Until', 
			timingOfEmail2: 'Password Received' ,
			emailReceipient: 'End User',
			subjectOfEmail: "Congratulations! You're Getting Better Email",
			templateFileName:'end.reminder.preview.html'
		},
		{
			title:'Email Templates', 
			topText: 'SkyKick has tested the communication process over thousands of migrations. End users receive only the essential communications required to prepare for the migration.', 
			bottomText:'Please note: These are typical and primary communications. There are dozens of variations depending on the migration scenario.', 
			iconSuperscript: 'Migration Prep', 
			timingOfEmail1: '9 Hours Before', 
			timingOfEmail2: 'Migration Time' ,
			emailReceipient: 'End User',
			subjectOfEmail: 'Important Migration Instructions for Today',
			templateFileName:'end.prep.preview.html'
		},
		{
			title:'Email Templates', 
			topText: 'SkyKick has tested the communication process over thousands of migrations. End users receive only the essential communications required to prepare for the migration.', 
			bottomText:'Please note: These are typical and primary communications. There are dozens of variations depending on the migration scenario.', 
			iconSuperscript: 'Email Switched', 
			timingOfEmail1: '10 Min Before', 
			timingOfEmail2: 'Migration Time' ,
			emailReceipient: 'End User',
			subjectOfEmail: '[Partner] Has Scheduled Your Migration To Office 365',
			templateFileName:'end.switched.preview.html'
		},
	];

	pointOfContactTemplates: CommunicationDetails[] = [
		{
			title:'Email Templates', 
			topText: 'The Point of Contact receives emails with a bit more context and details on the migration process. Partner is also copied on all of these communications to the Point of Contact.', 
			bottomText:'Please note: These are typical and primary communications. There are dozens of variations depending on the migration scenario.', 
			iconSuperscript: 'Partner Order', 
			timingOfEmail1: 'Time of Order', 
			timingOfEmail2: '' ,
			emailReceipient: 'Point of Contact',
			subjectOfEmail: 'SkyKick Still Needs Some of Your User Information',
			templateFileName:'point.partner.preview.html'
		},
		{
			title:'Email Templates', 
			topText: 'The Point of Contact receives emails with a bit more context and details on the migration process. Partner is also copied on all of these communications to the Point of Contact.', 
			bottomText:'Please note: These are typical and primary communications. There are dozens of variations depending on the migration scenario.', 
			iconSuperscript: 'Company Password Status', 
			timingOfEmail1: 'Nightly @ Midnight', 
			timingOfEmail2: '' ,
			emailReceipient: 'Point of Contact',
			subjectOfEmail: '',
			templateFileName:'point.company.preview.html'
		},
		{
			title:'Email Templates', 
			topText: 'The Point of Contact receives emails with a bit more context and details on the migration process. Partner is also copied on all of these communications to the Point of Contact.', 
			bottomText:'Please note: These are typical and primary communications. There are dozens of variations depending on the migration scenario.', 
			iconSuperscript: 'Migration Prep', 
			timingOfEmail1: '9 Hours Before', 
			timingOfEmail2: 'Migration Time' ,
			emailReceipient: 'Point of Contact',
			subjectOfEmail: 'Important Instructions for Your Email Switchover',
			templateFileName:'point.prep.preview.html'
		},
		{
			title:'Email Templates', 
			topText: 'The Point of Contact receives emails with a bit more context and details on the migration process. Partner is also copied on all of these communications to the Point of Contact.', 
			bottomText:'Please note: These are typical and primary communications. There are dozens of variations depending on the migration scenario.', 
			iconSuperscript: 'Email Switched', 
			timingOfEmail1: '10 Min Before', 
			timingOfEmail2: 'Migration Time' ,
			emailReceipient: 'Point of Contact',
			subjectOfEmail: '10 Minutes Until Your Email Switchover',
			templateFileName:'point.switched.preview.html'
		},
	];

	partnerTemplates: CommunicationDetails[] = [
		{
			title:'Email Templates', 
			topText: 'SkyKick has tested the communication process over thousands of migrations. End users receive only the essential communications required to prepare for the migration.', 
			bottomText:'Please note: These are typical and primary communications. There are dozens of variations depending on the migration scenario.', 
			iconSuperscript: 'Order Receipt', 
			timingOfEmail1: 'Order + 5 Minutes', 
			timingOfEmail2: '' ,
			emailReceipient: 'Partner',
			subjectOfEmail: "Order Placed for [Customer's Company]",
			templateFileName:'partner.receipt.preview.html'
		},
		{
			title:'Email Templates', 
			topText: 'SkyKick has tested the communication process over thousands of migrations. End users receive only the essential communications required to prepare for the migration.', 
			bottomText:'Please note: These are typical and primary communications. There are dozens of variations depending on the migration scenario.', 
			iconSuperscript: 'Company Password Status', 
			timingOfEmail1: 'Nightly @ Midnight Until', 
			timingOfEmail2: 'Password Received' ,
			emailReceipient: 'Partner',
			subjectOfEmail: 'Your Daily Status Update of Users Passwords Gathered',
			templateFileName:'partner.company.preview.html'
		},
		{
			title:'Email Templates', 
			topText: 'SkyKick has tested the communication process over thousands of migrations. End users receive only the essential communications required to prepare for the migration.', 
			bottomText:'Please note: These are typical and primary communications. There are dozens of variations depending on the migration scenario.', 
			iconSuperscript: 'Email Switched', 
			timingOfEmail1: '10 Min Before', 
			timingOfEmail2: 'Migration Time' ,
			emailReceipient: 'Partner',
			subjectOfEmail: "Migration complete for [Customer's Company]",
			templateFileName:'partner.switched.preview.html'
		},
	];

  constructor() { }

  ngOnInit(): void {
  }

  
}
