import { Component, HostBinding, Input, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TaskManagerService } from '@skykick/core';
import { ToastrService } from 'ngx-toastr';
import { forkJoin, of, BehaviorSubject, Subject, throwError } from 'rxjs';
import { catchError, map, tap, takeUntil, distinctUntilChanged, skipWhile, debounceTime, switchMap } from 'rxjs/operators';
import { TaskType } from 'src/app/settings/models/task-type';
import { SKYKICKPRODUCTS } from '../../core/constants/skykick-products.const';
import { WizardStepComponent } from '../../core/models/WizardStepComponent';
import { ActionButtonsService } from '../../core/services/action-buttons.service';
import { ConnectwiseResourcesService } from '../../core/services/connectwise-resources.service';
import { ConnectwiseStateManagerService } from '../../core/services/connectwise-state-manager.service';

@Component({
    selector: 'sk-map-products',
    templateUrl: './map-products.component.html',
    styleUrls: ['./map-products.component.scss']
})
export class MapProductsComponent implements WizardStepComponent, OnInit, OnDestroy {
    private onDestroy$ = new Subject();
    @HostBinding('class') class='d-flex flex-column flex-grow-1';
    @Input() data: any;
    @Input() settingType: string;
    skykickProducts = SKYKICKPRODUCTS.map(product => { return {...product }; });
    productMappingForm: FormGroup;
    isLoadingProductMappings: boolean;
    productMappings$: BehaviorSubject<any> = new BehaviorSubject([]);
    hasNotUniqueDestination = false;
    searchTermBehaviorSubject$: BehaviorSubject<string> = new BehaviorSubject('*');
    searchingProducts: boolean;
    private connectWiseProducts: any[] = [];

    private updateSkyKickProducts(productMappings: Array<any>) {
        productMappings.forEach(item => {
            let productIndex = this.skykickProducts.findIndex(({source}) => source === item.source);
            this.skykickProducts[productIndex] = { ...this.skykickProducts[productIndex], ...item };
        });
    }

    private uniqueDestinationValidator(): ValidatorFn {
      return (control: AbstractControl): ValidationErrors => {
        const form = this.productMappingForm?.get('products') as FormArray;
        const controls = form.controls;
        if (controls.length) {
            const destinations = Object.keys(controls)
              .map(key => controls[key].controls.destination.value)
              .filter(product => product !== null);
            const uniqueDestinations = new Set(destinations);
            if (uniqueDestinations.size != destinations.length) {
              this.hasNotUniqueDestination = true;
              return { 'notUniqueDestination': true };
            }
        }
        this.hasNotUniqueDestination = false;
        return null;
      };
    }

    constructor(
        private formBuilder: FormBuilder,
        public activeModal: NgbActiveModal,
        private actionButtonsService: ActionButtonsService,
        private connectwiseStateManagerService: ConnectwiseStateManagerService,
        private connectwiseResourcesService: ConnectwiseResourcesService,
        private toastrService: ToastrService,
        private taskManagerService: TaskManagerService
    ) { }

    get productMappingFormArray() {
        return this.productMappingForm.get('products') as FormArray;
    }

    product(index: number): FormGroup {
        const itemToAdd = this.formBuilder.group({
            destination: [null, [this.uniqueDestinationValidator()]],
            groupIndex: index
        });
        itemToAdd.valueChanges.pipe(
            distinctUntilChanged(),
            skipWhile(val => !val)
        ).subscribe(data => this.onValueChanged(data));
        return itemToAdd;
    }

    onValueChanged(data: any): void {
        if(this.hasNotUniqueDestination){
          return;
        }
        const groupIndex = data['groupIndex'];
        let skykickProduct = this.skykickProducts[groupIndex];
        skykickProduct.destination = data.destination;
        skykickProduct.active = true;

        let payload = { partnerId: skykickProduct.partnerId, destination: skykickProduct.destination, source: skykickProduct.source };

        this.connectwiseResourcesService.createProductMapping(payload).pipe(
            catchError((error) => {
              this.toastrService.error('Update failed');              
              skykickProduct.active = false;
              return throwError(error);
            }),
            takeUntil(this.onDestroy$),
        ).subscribe(res => {
            this.skykickProducts[groupIndex] = { ...this.skykickProducts[groupIndex], ...res };
            this.skykickProducts[groupIndex].active = false;
            this.productMappings$.next([...this.productMappings$.value, res]);
            this.searchTermBehaviorSubject$.next('*');
            this.toastrService.success('Update successful');
        });
        this.taskManagerService.abortTask(TaskType.ConnectWiseInfo);
    }

    ngOnInit(): void {
        this.productMappingForm = this.formBuilder.group(
            { products: this.formBuilder.array([]) }
            );
        this.skykickProducts.forEach((item, index) => {
            this.productMappingFormArray.push(this.product(index));
        });

        //grab product mappings
        this.isLoadingProductMappings = true;
        this.skykickProducts.forEach(item => {
            item.partnerId = this.connectwiseStateManagerService.getConnectWisePartner().partnerId;
            item.active = false;
        });

        this.productMappings$.pipe(
            tap(products => {
                if (products?.length) {
                    this.connectwiseStateManagerService.markAsCompleteWizardNav(this.data.type);
                } else {
                    this.connectwiseStateManagerService.markAsIncompleteWizardNav(this.data.type);
                }
            }),
            takeUntil(this.onDestroy$)
        ).subscribe();

        this.connectwiseResourcesService.getProductMappings().pipe(
            catchError(() => of([])),
            takeUntil(this.onDestroy$)
        ).subscribe(productMappings => {
            this.productMappings$.next(productMappings);
            this.updateSkyKickProducts(productMappings);
            this.productMappingFormArray.patchValue(this.skykickProducts, { emitEvent: false });
            this.isLoadingProductMappings = false;
        });

        this.searchTermBehaviorSubject$.asObservable().pipe(
            debounceTime(500),
            distinctUntilChanged(),
            tap(() => this.searchingProducts = true),
            switchMap(searchTerm => this.connectwiseResourcesService.searchConnectWiseProducts(searchTerm).pipe(
              catchError(() => of([]))
            ))
        ).subscribe(connectWiseProducts => {
            this.connectWiseProducts = connectWiseProducts;
            this.searchingProducts = false;
        });
    }

    removeMapping(mapping: any, index: number) {
        mapping.active = true;
        this.connectwiseResourcesService.deleteProductMapping(mapping).pipe(
            takeUntil(this.onDestroy$)
        ).subscribe(() => {
            this.productMappings$.next(this.productMappings$.value.filter(p => p.destination !== mapping.destination));

            mapping.destination = mapping.mappingDate = mapping.productMappingId = null;
            mapping.active = false;
            this.toastrService.success('Update successful');
            const elem = this.productMappingFormArray.controls[index].get('destination');
            elem.patchValue(null, { emitEvent: false });
        });
        this.taskManagerService.abortTask(TaskType.ConnectWiseInfo);
    }

    getAvailableConnectWiseProducts(destination: string){
        return this.productMappings$.pipe(
            map(mappings => this.connectWiseProducts.filter(product => mappings.every(mapping => mapping.destination !== product) || product === destination))
        );
    }

    submit() {
        this.actionButtonsService.submitWizardStep(this.data);
    }

    isAtLeastOneMapping() {
        const product = this.skykickProducts.find(item => item.destination);
        if (product) {
            return false;
        }
        return true;
    }

    searchProducts(event: any): void {
      const searchTerm = event.target.value;
      this.searchTermBehaviorSubject$.next(!searchTerm ? '*' : searchTerm);
    }

    getSelectedProduct(index: number): any {
      const selectedProduct = this.productMappingFormArray.controls[index].get('destination').value;
      return selectedProduct;
    }

    selectProduct(product: any, index: number): void {
      this.productMappingFormArray.controls[index].get('destination').patchValue(product);
    }

    isProductMapped(index: number): boolean {
      return this.productMappingFormArray.controls[index].get('destination').value !== null;
    }

    clearSearch(event: any, index: number) {
        const searchInput = <HTMLInputElement>document.getElementById(`searchProductsInput${index}`);
        searchInput.value = null;
        this.searchProducts(event);
    }

    ngOnDestroy(): void {
        this.onDestroy$.next();
    }

}
