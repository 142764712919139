import { Injectable } from '@angular/core';

import { TaskType } from 'src/app/settings/models/task-type';

@Injectable({
    providedIn: 'root'
})

export class UsersComponentContext {
    public infoButtonTaskType: TaskType = TaskType.MemberDetails;

    constructor() { }

}
