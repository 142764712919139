import {
  Component,
  OnInit,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import {
  SkykickPlatformPage,
  TaskManagerService,
} from '@skykick/core';
import {
  AbstractUserProvider,
} from '@skykick/platform-identity-auth-auth0-angular';

@Component({
  selector: 'sk-notification-settings',
  templateUrl: './notification-settings.component.html',
  styleUrls: ['./notification-settings.component.scss'],
})
export class NotificationSettingsComponent
  extends SkykickPlatformPage
  implements OnInit
{
  constructor(
    activatedRoute: ActivatedRoute,
    taskManagerService: TaskManagerService,
    private abstractUserProvider: AbstractUserProvider,
  ) {
    super(activatedRoute, taskManagerService);
  }

  ngOnInit(): void {
  }

  hasPermission(tabName: string): boolean {
    const route = this.activatedRoute.snapshot.routeConfig.children.filter(x => x.data['name'] == tabName)[0];
    const permission = route.data['permission'];
    if (permission){
      return this.abstractUserProvider.getCurrentUser().hasPermission(permission);
    }
    return true;
  }
}
