import { Component, HostBinding, OnInit, OnDestroy } from '@angular/core';
import { catchError,  tap, takeUntil } from 'rxjs/operators';
import { EMPTY, Subject } from 'rxjs';
import { ConnectwiseStateManagerService } from '../core/services/connectwise-state-manager.service';
import { ConnectwiseResourcesService } from '../core/services/connectwise-resources.service';
import { SkyKickDatePipe } from '@skykick/core';
import { DatePipe } from '@angular/common';
import { PRORATIONSETTING } from '../core/constants/proration-setting.const';

@Component({
    selector: '[sk-details]',
    templateUrl: './details.component.html',
    styleUrls: ['./details.component.scss']
})
export class DetailsComponent implements OnInit, OnDestroy {
    private onDestroy$ = new Subject();
    @HostBinding('class.sk-content-primary') isActive = true;
    partnerData = null;
    connectionStatus: boolean = null;
    loadingConnectionStatus = true;
    totalRecords = 0;
    mappedRecords = 0;
    lastSyncTime: string;

    constructor(
        private connectwiseResourcesService: ConnectwiseResourcesService,
        private connectwiseStateManagerService: ConnectwiseStateManagerService) { }

    ngOnInit(): void {
        this.partnerData = this.connectwiseStateManagerService.getConnectWisePartner();
        const datePipe = new SkyKickDatePipe(new DatePipe('en-US'));
        this.lastSyncTime = datePipe.transform(this.partnerData.lastSync) ?? 'never';

        this.connectwiseResourcesService.getCompanyMappings({ searchTerm: '', resultsPerPage: 10000, pageNumber: 1 })
            .pipe(
                tap(res => {
                    this.totalRecords = res.totalRecords;
                    this.mappedRecords = res.entities.filter(x => x.agreementId && x.companyId).length;
                }),
                takeUntil(this.onDestroy$)
            ).subscribe();

        this.connectwiseResourcesService.getConnectionStatus().pipe(
            tap(res => {
                this.connectionStatus = res;
                this.loadingConnectionStatus = false;
            }),
            takeUntil(this.onDestroy$),
            catchError(() => {
                this.connectionStatus = false;
                this.loadingConnectionStatus = false;
                return EMPTY;
            })
        ).subscribe();
    }

    prorationSettingEnabled(): boolean {
        return this.partnerData?.prorationSetting !== PRORATIONSETTING.Unset;
    }

    isPartnerActive(): boolean {
        return this.partnerData && this.partnerData.prorationSetting !== PRORATIONSETTING.Unset;
    }

    ngOnDestroy(): void {
        this.onDestroy$.next();
    }

}
