import { NgModule } from '@angular/core';
import {
    RouterModule,
    Routes,
} from '@angular/router';

import {
    AddCustomerComponent,
    CustomersComponent,
    EditCustomerComponent,
} from './components/customers';
import {
    AddEditMemberFormComponent,
} from './components/members/add-edit-member-form/add-edit-member-form.component';
import { MembersComponent } from './components/members/members.component';
import { UsersComponent } from './components/users.component';
import { AdminUserGuard } from './guards/admin-user.guard';

export const USERS_ROUTES: Routes = [
    {
        path: 'users',
        redirectTo: 'users/members',
        pathMatch: 'prefix'
    },
    {
        path: 'users',
        component: UsersComponent,
        children: [
            {
                path: 'members',
                component: MembersComponent
            },
            {
                path: 'members/add',
                component: AddEditMemberFormComponent,
                canActivate: [AdminUserGuard]
            },
            {
                path: 'members/:memberId/edit',
                component: AddEditMemberFormComponent,
                canActivate: [AdminUserGuard]
            },
            {
                path: 'customers',
                component: CustomersComponent
            },
            {
                path: 'customers/add',
                component: AddCustomerComponent
            },
            {
                path: 'customers/:customerId/edit',
                component: EditCustomerComponent
            },
        ]
    }
];

@NgModule({
    imports: [RouterModule.forChild(USERS_ROUTES)],
    exports: [RouterModule]
})
export class UsersRoutingModule { }
