import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { FILTERTYPE } from '../constants/filter-type.const';

@Injectable({
    providedIn: 'root'
})
export class CustomerAccountsService {
    private filteredCustomerAccounts$: BehaviorSubject<any> = new BehaviorSubject(null);
    private selectedCustomer$: BehaviorSubject<any> = new BehaviorSubject(null);
    private _customerAccounts: any;

    getCustomerAccounts() {
        return this._customerAccounts;
    }

    setCustomerAccounts(customerAccounts: any) {
        this._customerAccounts = customerAccounts;
    }

    getFilteredCustomerAccounts(): Observable<any> {
        return this.filteredCustomerAccounts$.asObservable();
    }

    setFilteredCustomerAccounts(customerAccounts: any) {
        this.filteredCustomerAccounts$.next(customerAccounts);
    }

    filterCustomerAccounts(filterType: string, searchString: string, resultsPerPage: number) {
        if (!this._customerAccounts) {
            return;
        }
        let filteredItems = this._customerAccounts;
        if (searchString) {
            searchString = searchString.toLocaleLowerCase();
            filteredItems = filteredItems.filter(item => {
                return item.orderName.toLocaleLowerCase().includes(searchString);
            });
        }
        filteredItems = filteredItems.filter(item => {
            if (filterType === FILTERTYPE[0].value) {
                return item;
            }
            if (filterType === FILTERTYPE[1].value) {
                return item.agreementId && item.companyId;
            }
            if (filterType === FILTERTYPE[2].value) {
                return !item.agreementId && !item.companyId;
            }
        });
        filteredItems = filteredItems.slice(0, resultsPerPage);
        this.setFilteredCustomerAccounts(filteredItems);
    }

    getSelectedCustomer(): Observable<any> {
        return this.selectedCustomer$.asObservable();
    }

    setSelectedCustomer(selectedCustomer: any) {
        this.selectedCustomer$.next(selectedCustomer);
    }

}
