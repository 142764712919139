<!-- CONTENT COLUMN -->
<div class="sk-page-content">
  <!-- CONTENT PRIMARY - SCROLLABLE -->
  <div class="sk-content-primary">
      <div class="sk-flex-table">
          <!-- OPTIONAL SPIN LOADER-->
          <div class="flex-center-center pb-100" [ngClass]="{'d-none': !initialLoading}">
              <div class="sk-spin-loader"></div>
          </div>
          <!-- OPTIONAL ACTION ROW-->
          <div class="sk-table-cap py-100" [ngClass]="{'d-none': !(customers) }">
              <div class="d-inline-flex align-items-center mr-300">
                  <button type="button" class="btn btn-primary mr-100 text-truncate" routerLink="add">
                      {{ 'settings.customers.add-customer' | translate }}
                  </button>
              </div>

              <div class="d-inline-flex flex-shrink-0">
                <div class="d-inline-flex align-items-center">
                    <button id="sk-button-filters" (click)="showFilterBar = !showFilterBar"
                        class="btn btn-outline sk-btn-icon mr-100" type="button">
                        <i class="material-icons md-22">filter_list</i>
                    </button>
                </div>
                <div class="sk-input-btn input-width-sm input-group">
                    <input #searchCustomersInput type="text" class="form-control" placeholder="{{ 'settings.customers.search' | translate }}">
                    <i *ngIf="loading" class="sk-spin-loader loader-sm material-icons spin-component-custom"></i>
                    <i #clearSearchBtn class="material-icons md-18 sk-reset-search ng-star-inserted" [ngClass]="{'d-none': loading}" title="Reset and Exit Search"></i>
                    <div class="input-group-append">
                        <button #searchCustomersBtn class="btn btn-outline sk-btn-search-outline" type="button"><i class="material-icons md-18"></i></button>
                    </div>
                </div>
              </div>
          </div>

          <div class="sk-table-cap" [ngClass]="{'d-none': !(customers) || !showFilterBar }">
            <div class="d-inline-flex flex-shrink-0">
                <!-- STANDARD FILTERS -->
                <div class="d-inline-flex align-items-center mr-100">

                    <span class="text-muted pr-50">{{ 'settings.common.filterBy' | translate }}</span>

                    <ng-container *ngFor="let filterArea of filterAreas">
                        <div class="btn-group mr-50" ngbDropdown skStopPropagation autoClose="outside">
                            <button ngbDropdownToggle class="btn btn-basic">
                                {{ filterArea.displayName | translate }}
                                <span *ngIf="filterArea.selectedFiltersCount" class="badge badge-pill badge-default ml-1">
                                    {{ filterArea.selectedFiltersCount }}
                                </span>
                            </button>
                            <div ngbDropdownMenu class="dropdown-menu">
                                <ng-container *ngFor="let areaGroup of filterArea.groups">
                                  <div class="dropdown-item filter-group-item">
                                    <label class="custom-group-label">{{ areaGroup.displayName | translate }}</label>
                                  </div>
                                  <ng-container *ngFor="let filter of filterArea.filters">
                                    <div *ngIf="filter.group === areaGroup.group" class="dropdown-item">
                                        <div class="custom-control custom-checkbox sk-checkbox sk-checkbox-sm" (click)="checkFilter(filterArea, filter)">
                                            <input
                                              [disabled]="!filter.isEnabled"
                                              [checked]="filter.isSelected"
                                              type="checkbox"
                                              class="custom-control-input">
                                            <label class="custom-control-label">{{ filter.displayName | translate }}</label>
                                        </div>
                                    </div>
                                  </ng-container>
                                </ng-container>
                                <ng-container *ngIf="!filterArea.groups">
                                  <ng-container *ngFor="let filter of filterArea.filters">
                                    <div class="dropdown-item">
                                        <div class="custom-control custom-checkbox sk-checkbox sk-checkbox-sm" (click)="checkFilter(filterArea, filter)">
                                            <input
                                              [disabled]="!filter.isEnabled"
                                              [checked]="filter.isSelected"
                                              type="checkbox"
                                              class="custom-control-input">
                                            <label class="custom-control-label">{{ filter.displayName | translate }}</label>
                                        </div>
                                    </div>
                                  </ng-container>
                                </ng-container>
                            </div>
                        </div>
                    </ng-container>

                    <button *ngIf="showClearFiltersButton()" (click)="clearAllFilters()" type="button" class="btn btn-link sk-btn-combo px-0 ml-4">
                        <i class="material-icons md-22 md-blue">close</i><span>{{ 'settings.common.clear-filters' | translate }}</span>
                    </button>
                </div>
            </div>
        </div>

          <div class="sk-scroll-container" *ngIf="!initialLoading">
              <!-- TABLE -->
              <table class="table table-hover sk-standard-wrap-table sk-sortable sk-sticky-header sk-min-width-lg ">
                  <thead class="thead-white thead-sm thead-border-none">
                      <tr [ngClass]="{'header-line': showFilterBar}">
                          <th>{{ 'settings.customers.table.name' | translate }}</th>
                          <th>{{ 'settings.customers.table.orderName' | translate }}</th>
                          <th>{{ 'settings.customers.table.access' | translate }}</th>
                          <th>{{ 'settings.customers.table.permission' | translate }}</th>
                          <th>{{ 'settings.customers.table.status' | translate }}</th>
                          <th></th>
                      </tr>
                  </thead>
                  <tbody *ngIf="(customers) && (customers?.length > 0 && !loading) && !loadCustomersError">
                      <tr *ngFor="let customer of customers" (click)="selectCustomer(customer, $event)">
                          <td class="py-75">
                              <div class="d-flex align-items-center">
                                  <sk-avatar class="mr-1" [fullName]="customer.fullName"></sk-avatar>
                                  <div class="d-flex flex-column min-w-0 px-100">
                                      <span class="font-weight-medium line-height-20">{{ customer.fullName }}</span>
                                      <span class="text-muted line-height-20">{{ customer.email }}</span>
                                  </div>
                              </div>
                          </td>
                          <td>{{ customer.orderName }}</td>
                          <td>{{ getAccessLocKey(customer.access) | translate }}</td>
                          <td>{{ getPermissionLocKey(customer.permission) | translate }}</td>
                          <td>{{ getStatusLocKey(customer.status) | translate }}</td>
                          <td>
                            <sk-actions-menu
                              [ngSwitch]="customer.status">
                              <a *ngSwitchCase="'Active'"
                                (click)="resetPassword(customer)"
                                class="dropdown-item"
                                [hidden]="isResetPasswordUnavailable"
                                ngbDropdownItem>{{ 'settings.customers.actions.reset-password' | translate }}</a>
                              <a *ngSwitchCase="ActiveStatus"
                                (click)="editCustomer(customer)"
                                class="dropdown-item"
                                ngbDropdownItem>{{ 'settings.customers.actions.edit' | translate }}</a>
                              <a *ngSwitchCase="ActiveStatus"
                                (click)="deactivateCustomer(customer)"
                                class="dropdown-item text-danger"
                                ngbDropdownItem>{{ 'settings.customers.actions.deactivate' | translate }}</a>
                              <ng-container *ngSwitchCase="'Inactive'">
                                <a (click)="activateCustomer(customer)"
                                  class="dropdown-item"
                                  ngbDropdownItem>{{ 'settings.customers.actions.activate-account' | translate }}</a>
                                <a (click)="removeCustomer(customer)"
                                  class="dropdown-item text-danger"
                                  ngbDropdownItem>{{ 'settings.customers.actions.remove-account' | translate }}</a>
                              </ng-container>
                            </sk-actions-menu>
                          </td>
                      </tr>
                  </tbody>
              </table>
              <div class="row justify-content-center p-3 text-muted customers-not-found" *ngIf="customers?.length === 0 && !loadCustomersError && !loading">
                  <i class="material-icons opacity-50 md-48 icon-primary">person</i>
                  <div>{{ 'settings.customers.no-customers-found' | translate }}</div>
              </div>
              <sk-load-more *ngIf="showLoadMoreCustomers()"
                  [text]="'settings.common.loadMore' | translate"
                  (loadMoreClicked)="loadMoreCustomers()">
              </sk-load-more>
              <div class="row justify-content-center p-3 filtering-spinner" *ngIf="loading">
                  <div class="sk-spin-loader loading-spinner loader-md"></div>
              </div>
              <div class="row justify-content-center p-3 loading-more-spinner" *ngIf="loadingMore">
                  <div class="sk-spin-loader loading-spinner loader-sm"></div>
              </div>
          </div>
      </div>
  </div>
  <div sk-panel></div>
</div>
