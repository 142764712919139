<aside sk-panel-tab-view [skTitle]="panelTitle" [views]="Views">
    <footer>
        <button id="primaryButton" class="btn btn-primary sk-has-spinner sk-abs-spinner mr-100"
            [class.active]="actionButtonsService.setPrimaryIsBusy$ | async" [disabled]="(actionButtonsService.setPrimaryIsBusy$ | async) || (actionButtonsService.setPrimaryIsDisabled$ | async)"
            (click)="update()">
            <div class="sk-btn-spinner">
                <div class="sk-spin-loader loader-dark"></div>
            </div>
            <span class="sk-loader-label">Update</span>
        </button>

        <button id="cancel" type="button" class="btn btn-outline-secondary" (click)="cancel()">Cancel</button>
    </footer>
</aside>