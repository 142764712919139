<div class="modal-body" [ngClass]="{'step-update' : settingType !== 'wizard' , 'step-init' : settingType === 'wizard' }">
    
    <div class="d-flex my-4 align-items-center justify-content-center">
        <div class="d-flex align-items-center justify-content-center square-48">
            <img src="https://design.skykick.com/img/svg/skykick-badge.svg" alt="SkyKick Logo" class="img-fluid d-flex" />
        </div>
        <span class="material-icons icon-primary md-32 mx-2">autorenew</span>
        <div class="d-flex border rounded align-items-center justify-content-center square-48">
            <img src="/assets/svg/connectwise-icon.svg" alt="ConnectWise Logo" class="img-fluid d-flex" />
        </div>
    </div>

    <h3>Connect SkyKick to ConnectWise</h3>
    <p class="text-muted">Log into your connectWise account with your API keys</p>


    <form *ngIf="!isLoadingCredentials" [formGroup]="authenticateForm" novalidate>
        <fieldset class="form-group input-width-max" [ngClass]="displayFieldCss('connectWiseURL')">
            <label>ConnectWise URL</label>
            <input type="text" class="form-control" formControlName="connectWiseURL" id="connectWiseURL" />
            <small class="sk-form-text text-muted">The URL used to sign into ConnectWise Manage</small>
            <small *ngIf="isFieldValid('connectWiseURL', 'required')" class="form-control-feedback">Required field</small>
        </fieldset>
        <fieldset class="form-group input-width-max" [ngClass]="displayFieldCss('companyName')">
            <label>ConnectWise Company Name</label>
            <input type="text" class="form-control" formControlName="companyName" id="companyName" />
            <small class="sk-form-text text-muted">The Company ID used to sign into ConnectWise Manage</small>
            <small *ngIf="isFieldValid('companyName', 'required')" class="form-control-feedback">Required field</small>
        </fieldset>
        <fieldset class="form-group input-width-max" [ngClass]="displayFieldCss('publicKey')">
            <label>Public Key</label>
            <input type="password" class="form-control" formControlName="publicKey" id="publicKey" />
            <small *ngIf="isFieldValid('publicKey', 'required')" class="form-control-feedback">Required field</small>
        </fieldset>
        <fieldset class="form-group input-width-max" [ngClass]="displayFieldCss('privateKey')">
            <label>Private Key</label>
            <input type="password" class="form-control" formControlName="privateKey" id="privateKey" />
            <small *ngIf="isFieldValid('privateKey', 'required')" class="form-control-feedback">Required field</small>
        </fieldset>

        <ng-container *ngIf="authenticateForm.invalid && !authenticateForm.pristine">
            <div class="mt-3">
                <div class="form-group has-danger">
                    <small *ngIf="authenticateForm.errors?.['urlInvalid']" class="form-control-feedback">Invalid ConnectWise Url</small>
                    <small *ngIf="authenticateForm.errors?.['urlOrCompanyInvalid']" class="form-control-feedback">Invalid ConnectWise Url / Company Name</small>
                    <small *ngIf="authenticateForm.errors?.['credsInvalid']" class="form-control-feedback">Incorrect public/private key</small>
                </div>
            </div>    
        </ng-container>

        <ng-container>
          <div class="custom-control custom-checkbox sk-checkbox d-flex mt-100">
              <input type="checkbox" id="tandc" class="custom-control-input" formControlName="tandc" />
              <label for="tandc" class="custom-control-label">By clicking on this box, I accept
                  <div class="btn-group">
                      <a class="btn dropdown-toggle tandc-control"
                          (click)="$event.stopPropagation();showTandC = !showTandC">Terms & Conditions</a>
                  </div>
              </label>
          </div>
          <small *ngIf="showTandC">
            <div class="mt-25 pl-200 text-muted">
              This configuration tool will allow you to create a ConnectWise integration 
              into your SkyKick portal account and transmit certain payment information and related 
              data about your SkyKick account and your customers' accounts (collectively, "Data") 
              to ConnectWise.
            </div>
            <div class="mt-100 pl-200 text-muted">
              By clicking "I Accept" below, you acknowledge, on behalf of the 
              entity you represent, that (i) you have read, understand, and agree to SkyKick's 
              information and data collection, handling, and processing practices described in 
              the SkyKick Privacy Policy (ii) after you have setup your ConnectWise integration 
              through this configuration tool, SkyKick is no longer responsible for any Data that 
              gets transmitted through such ConnectWise integration, and (iii) the terms and conditions 
              and privacy practices of ConnectWise or other third parties, and not those of SkyKick, 
              will govern the security, confidentiality, privacy, handling, processing and disclosure 
              (collectively, "Processing") of the Data. SkyKick hereby disclaims all responsibility and 
              liability for, and makes no representation or warranties with respect to, the Processing of 
              Data once it has been transmitted to ConnectWise, including but not limited to any unauthorized 
              use or disclosure of the Data, whether by ConnectWise or any other third party.
            </div>
          </small>
        </ng-container>

        <div class="d-flex flex-row mt-150 align-items-center">
            <button *ngIf="settingType === 'wizard'" id="validateButton" class="btn btn-outline-primary sk-has-spinner sk-abs-spinner"
                [ngClass]="{'active disabled': isVerifyingCredentials}"
                [disabled]="authenticateForm.invalid" 
                (click)="validate()">
                <div class="sk-btn-spinner">
                    <div class="sk-spin-loader loader-dark"></div>
                </div>
                <span class="sk-loader-label" translate>Validate</span>
            </button>
            <i *ngIf="verificationCredsSuccessful" class="material-icons md-22 ml-50 icon-light d-flex icon-success">check_circle</i>
        </div>

    </form>
    <sk-text-spinner *ngIf="isLoadingCredentials" message="Loading credentials"></sk-text-spinner>
</div>

<div class="modal-footer justify-content-start">
    <button *ngIf="settingType === 'wizard'" id="submitBtn" (click)="submit()" [disabled]="authenticateForm.invalid || !credentials || isBusy" [class.active]="authenticateForm.disabled || isBusy"
        class="btn btn-primary sk-has-spinner sk-abs-spinner">
        <div class="sk-btn-spinner">
            <div class="sk-spin-loader loader-dark"></div>
        </div>
        <span class="sk-loader-label">Save & continue</span>
    </button>
    <button *ngIf="settingType !== 'wizard'" id="validateButton" class="btn btn-primary sk-has-spinner sk-abs-spinner"
        [ngClass]="{'active disabled': isVerifyingCredentials}"
        [disabled]="authenticateForm.invalid" 
        (click)="validate()">
        <div class="sk-btn-spinner">
            <div class="sk-spin-loader loader-dark"></div>
        </div>
        <span class="sk-loader-label" translate>Update</span>
    </button>
    <button id="cancelBtn" type="button" class="btn btn-secondary" [class.disabled]="authenticateForm.disabled" (click)="activeModal.dismiss()">Cancel</button>
</div>
