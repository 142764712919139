<!-- CONTENT COLUMN -->
<div class="sk-page-content">
    <!-- CONTENT PRIMARY - SCROLLABLE -->
    <div class="sk-content-primary">
        <div class="sk-flex-table">
            <!-- OPTIONAL SPIN LOADER-->
            <div class="flex-center-center pb-100" [ngClass]="{'d-none': !initialLoading}">
                <div class="sk-spin-loader"></div>
            </div>
            <!-- OPTIONAL ACTION ROW-->
            <div class="sk-table-cap py-100" [ngClass]="{'d-none': !(members) || initialLoading }">
                <div class="d-inline-flex align-items-center mr-300">
                    <button type="button" class="btn btn-primary mr-100 text-truncate" routerLink="add" *ngIf="isCurrentUserAdmin()">
                        {{ 'settings.members.add-member' | translate }}
                    </button>
                </div>

                <div class="d-inline-flex flex-shrink-0">
                    <div class="d-inline-flex align-items-center">
                        <button id="sk-button-filters" (click)="showFilterBar = !showFilterBar"
                            class="btn btn-outline sk-btn-icon mr-100" type="button">
                            <i class="material-icons md-22">filter_list</i>
                        </button>
                    </div>
                    <div class="sk-input-btn input-width-sm input-group">
                        <input #searchMembersInput type="text" class="form-control" placeholder="{{ 'settings.members.search' | translate }}">
                        <i *ngIf="loading" class="sk-spin-loader loader-sm material-icons spin-component-custom"></i>
                        <i #clearSearchBtn class="material-icons md-18 sk-reset-search ng-star-inserted" [ngClass]="{'d-none': loading}" title="Reset and Exit Search"></i>
                        <div class="input-group-append">
                            <button #searchMembersBtn class="btn btn-outline sk-btn-search-outline" type="button"><i class="material-icons md-18"></i></button>
                        </div>
                    </div>
                </div>
            </div>

            <div class="sk-table-cap" [ngClass]="{'d-none': !(members) || !showFilterBar }">
                <div class="d-inline-flex flex-shrink-0">
                    <!-- STANDARD FILTERS -->
                    <div class="d-inline-flex align-items-center mr-100">

                        <span class="text-muted pr-50">{{ 'settings.common.filterBy' | translate }}</span>

                        <ng-container *ngFor="let filterArea of filterAreas">
                            <div class="btn-group mr-50" ngbDropdown skStopPropagation autoClose="outside">
                                <button ngbDropdownToggle class="btn btn-basic">
                                    {{ filterArea.displayName | translate }}
                                    <span *ngIf="filterArea.selectedFiltersCount" class="badge badge-pill badge-default ml-1">
                                        {{ filterArea.selectedFiltersCount }}
                                    </span>
                                </button>
                                <div ngbDropdownMenu class="dropdown-menu">
                                    <ng-container *ngFor="let filter of filterArea.filters">
                                        <div class="dropdown-divider" *ngIf="showDivider(filter)"></div>
                                        <div class="dropdown-item">
                                            <div class="custom-control custom-checkbox sk-checkbox sk-checkbox-sm">
                                                <input [checked]="filter.isSelected" (change)="checkFilter(filterArea, filter)" type="checkbox" class="custom-control-input" [id]="filter.value">
                                                <label class="custom-control-label" [for]="filter.value">{{ filter.displayName | translate }}</label>
                                            </div>
                                        </div>
                                    </ng-container>
                                </div>
                            </div>
                        </ng-container>

                        <button *ngIf="showClearFiltersButton()" (click)="clearAllFilters()" type="button" class="btn btn-link sk-btn-combo px-0 ml-4">
                            <i class="material-icons md-22 md-blue">close</i><span>{{ 'settings.common.clear-filters' | translate }}</span>
                        </button>
                    </div>
                </div>
            </div>

            <div class="sk-scroll-container" *ngIf="!initialLoading">
                <!-- TABLE -->
                <table class="table table-hover sk-standard-wrap-table sk-sortable sk-sticky-header sk-min-width-lg ">
                    <thead class="thead-white thead-sm thead-border-none">
                        <tr [ngClass]="{'header-line': showFilterBar}">
                            <th>{{ 'settings.members.table.name' | translate }}</th>
                            <th>{{ 'settings.members.table.accountRole' | translate }}</th>
                            <th>{{ 'settings.members.table.access' | translate }}</th>
                            <th>{{ 'settings.members.table.status' | translate }}</th>
                            <th *ngIf="isCurrentUserAdmin()"></th>
                        </tr>
                    </thead>
                    <tbody *ngIf="(members) && (members?.length > 0 && !loading) && !loadMembersError">
                        <tr *ngFor="let member of members" (click)="selectMember(member, $event)">
                            <td class="py-75">
                                <div class="d-flex align-items-center">
                                    <sk-avatar class="mr-1" [fullName]="member.fullName"></sk-avatar>
                                    <div class="d-flex flex-column min-w-0 px-100">
                                        <span class="font-weight-medium line-height-20">{{ member.fullName }}</span>
                                        <span class="text-muted line-height-20">{{ member.email }}</span>
                                    </div>
                                </div>
                            </td>
                            <td>{{ getDisplayNameLocKeyForMemberRole(member.role) | translate }}</td>
                            <td>{{ formatMemberAccess(member.access) }}</td>
                            <td>{{ getDisplayNameLocKeyForMemberStatus(member.status) | translate }}</td>
                            <td *ngIf="isCurrentUserAdmin()">
                                <sk-actions-menu
                                    [member]="member"
                                    [ngSwitch]="member.status"
                                    *ngIf="member?.status">
                                    <ng-container *ngSwitchCase="'Active'">
                                        <a (click)="resetPassword(member)"
                                            class="dropdown-item"
                                            [hidden]="isResetPasswordUnavailable"
                                            ngbDropdownItem>{{ 'settings.members.actions.reset-password' | translate }}</a>
                                        <a (click)="editPermissions(member)"
                                            class="dropdown-item"
                                            ngbDropdownItem>{{ 'settings.members.actions.edit-permissions' | translate }}</a>
                                        <a *ngIf="!isOwnMember(member)"
                                            (click)="deactivateAccount(member)"
                                            class="dropdown-item text-danger"
                                            ngbDropdownItem>{{ 'settings.members.actions.deactivate-account' | translate }}</a>
                                    </ng-container>
                                    <ng-container *ngSwitchCase="'Pending'">
                                        <a (click)="sendInvitation(member)"
                                            class="dropdown-item"
                                            ngbDropdownItem>{{ 'settings.members.actions.resend-invite' | translate }}</a>
                                        <a (click)="revokeInvitation(member)"
                                            class="dropdown-item"
                                            ngbDropdownItem>{{ 'settings.members.actions.revoke-invite' | translate }}</a>
                                    </ng-container>
                                    <ng-container *ngSwitchCase="'Inactive'">
                                        <a (click)="activateAccount(member)"
                                            class="dropdown-item"
                                            ngbDropdownItem>{{ 'settings.members.actions.activate-account' | translate }}</a>
                                        <a *ngIf="!isOwnMember(member)"
                                            (click)="removeAccount(member)"
                                            class="dropdown-item text-danger"
                                            ngbDropdownItem>{{ 'settings.members.actions.remove-account' | translate }}</a>
                                    </ng-container>
                                </sk-actions-menu>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div class="row justify-content-center p-3 text-muted member-not-found" *ngIf="members?.length === 0 && !loadMembersError && !loading">
                    <i class="material-icons opacity-50 md-48 icon-primary">person</i>
                    <div>{{ 'settings.members.no-members-found' | translate}}</div>
                </div>
                <sk-load-more *ngIf="showLoadMore && !loadingMore && !loading && members?.length > 0" [text]="'settings.common.loadMore' | translate"
                    (loadMoreClicked)="loadMoreMembers()">
                </sk-load-more>
                <div class="row justify-content-center p-3 filtering-spinner" *ngIf="loading">
                    <div class="sk-spin-loader loading-spinner loader-md"></div>
                </div>
                <div class="row justify-content-center p-3 loading-more-spinner" *ngIf="loadingMore">
                    <div class="sk-spin-loader loading-spinner loader-sm"></div>
                </div>
            </div>
        </div>
    </div>

    <div sk-panel></div>
</div>
