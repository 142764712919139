import { Component, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { SkyKickPanelTabViewBase } from '@skykick/core';
import InfoViewModel from './models/info-view-model';
import { InfoPanelContext } from './models/info-panel-context';
import { WIZARDNAVS } from '../core/constants/wizard-navs.const';
import { forkJoin, of, Subject } from 'rxjs';
import { ConnectwiseResourcesService } from '../core/services/connectwise-resources.service';
import { catchError, takeUntil } from 'rxjs/operators';
import { SKYKICKPRODUCTS } from '../core/constants/skykick-products.const';

@Component({
    /* tslint:disable-next-line:component-selector */
    selector: '[sk-info-panel]',
    templateUrl: './info-panel.component.html',
    styleUrls: ['./info-panel.component.scss']
})

export class InfoPanelComponent extends SkyKickPanelTabViewBase<InfoPanelContext, void> implements OnInit, OnDestroy, OnChanges {

    info: InfoViewModel = {
        connectWiseUrl: '',
        connectWiseCompany: '',
        productMapping: [],
        isTicketingConfigured: false,
        connectionWizardHandler: () => {}
    };
    WIZARDNAVS = WIZARDNAVS;
    loading = false;

    private onDestroy$ = new Subject();

    constructor(
        private connectwiseResourcesService: ConnectwiseResourcesService
    ) {
        super();
    }

    ngOnInit(): void {
        this.loading = true;
        forkJoin([
            this.connectwiseResourcesService.getCredentials().pipe(
                catchError(() => of(undefined))
            ),
            this.connectwiseResourcesService.getProductMappings().pipe(
                catchError(() => of([]))
            ),
            this.connectwiseResourcesService.getTicketMapping().pipe(
                catchError(() => of(undefined))
            )
        ]).pipe(
            takeUntil(this.onDestroy$)
        ).subscribe(([credentials, productMappings, ticketMapping]) => {
            this.info = {
                connectWiseUrl: credentials?.connectWiseURL,
                connectWiseCompany: credentials?.companyName,
                productMapping: SKYKICKPRODUCTS.map(product => { return { skyKickProductName: product.name, isMapped: productMappings?.some(mapping => mapping.source === product.source) }; }),
                isTicketingConfigured: !!ticketMapping,
                connectionWizardHandler: this.data.connectionWizardHandler
            }
            this.loading = false;
        });
    }

    ngOnDestroy(): void {
        this.onDestroy$.next();
    }

    ngOnChanges(): void {
    }

    close() {
        this.operation.complete();
    }
}
