<aside sk-panel-view [skTitle]="'Details'">
    <div class="sk-panel-content">
        <div class="sk-panel-scroll px-150">
            <div *ngIf="loading" class="d-flex justify-content-center align-items-center">
                <div class="sk-spin-loader"></div>
            </div>
            <dl *ngIf="!loading" class="sk-dl line-height-24">
                <dt>ConnectWise URL</dt>
                <dd class="d-flex align-items-center">{{ info.connectWiseUrl }}</dd>
                <dt class="mt-50">ConnectWise Company Name</dt>
                <dd class="d-flex align-items-center">{{ info.connectWiseCompany }}</dd>
                <dt class="mt-50">Product Mapping</dt>
                <ng-container *ngFor="let productMapping of info.productMapping">
                    <dd class="d-flex align-items-center">
                        <i class="material-icons md-18 mr-50" [class]="productMapping.isMapped ? 'icon-success' : 'icon-muted'">check_circle</i>
                        {{ productMapping.skyKickProductName }}
                    </dd>
                </ng-container>
                <dt class="mt-50">Proration Sync</dt>
                <dd class="d-flex align-items-center">Enabled</dd>
                <dt class="d-flex align-items-center mt-50">
                    <i class="material-icons md-18 mr-50" [class]="info.isTicketingConfigured ? 'icon-success' : 'icon-muted'">check_circle</i>
                    Ticketing configured
                </dt>
            </dl>
        </div>
    </div>
    <footer>
        <div class="btn-group" ngbDropdown container="body">
            <button class="btn btn-primary">Settings</button>
            <button ngbDropdownToggle class="btn btn-primary dropdown-toggle dropdown-toggle-split"></button>
            <div ngbDropdownMenu>
                <button *ngFor="let navigationItem of WIZARDNAVS" ngbDropdownItem (click)="close();info.connectionWizardHandler(navigationItem.value);">{{navigationItem.name}}</button>
            </div>
        </div>
        <button class="btn btn-outline-secondary ml-100" (click)="close()">
            Cancel
        </button>
    </footer>
</aside>
