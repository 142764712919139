import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
} from '@angular/router';

import {
  AbstractUserProvider,
} from '@skykick/platform-identity-auth-auth0-angular';

@Injectable({
  providedIn: 'root'
})
export class HasPermissionGuard implements CanActivate {

  constructor(
      private router: Router,
      private abstractUserProvider: AbstractUserProvider
  ) { }

  canActivate(routeSnapshot: ActivatedRouteSnapshot): boolean {
    const permissionAbsentRedirect = routeSnapshot.data['permissionAbsentRedirect'];
    const hasPermission = this.abstractUserProvider.getCurrentUser().hasPermission(routeSnapshot.data['permission']);
    if (!hasPermission) {
      const redirect = !!permissionAbsentRedirect ? permissionAbsentRedirect : '/settings';
      this.router.navigate([redirect]);
      return false;
    }

    return hasPermission;
  }
}
