<nav class="navbar sk-portal-header">
    <a href="../"><img class=" " src="https://core.skykick.com/images/svg/skykick-logo.svg" height="28"></a>
</nav>
<div class="sk-content-primary bg-gray-50">
    <div class="sk-content-flex">
        <div *ngIf="loading && !error" class="flex-center-center pb-100">
            <div class="sk-spin-loader"></div>
        </div>
        <div *ngIf="error" class="flex-top-center pb-100">
            <sk-error-banner [title]="errorTitle" [description]="errorDescription" [contactLink]="errorContactLink"></sk-error-banner>
        </div>
        <div *ngIf="!loading" class="sk-scroll-container align-items-center">
            <div>
                <div class="col-lg col-lg-12 mt-200 mb-200 text-lg-center">
                    <h1>{{ 'complete.form.complete_your_account_profile' | translate }}</h1>
                </div>
                <form class="card mb-200" [formGroup]="completeProfileForm">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-xl mb-150 mt-50 col-xl-12" >
                                <h3>{{ 'complete.form.account' | translate }}</h3>
                            </div>
                        </div>
                        <div *ngIf="invitationInvalid" class="alert alert-dismissible sk-alert-icon-24 alert-danger d-flex align-items-center mb-100" role="alert">
                            <i class="material-icons md-22 ">error</i>
                            <div class="ml-100">
                              <span class="sk-alert-text"><strong>{{ 'complete.form.actions.invitation_link_is_invalid' | translate }}</strong> – {{ 'complete.form.actions.contact_partner' | translate }}</span>
                            </div>
                        </div>
                        <div *ngIf="invitationUsed" class="alert alert-dismissible sk-alert-icon-24 alert-info d-flex align-items-center mb-100" role="alert">
                            <i class="material-icons md-22 ">info</i>
                            <div class="ml-100">
                              <span class="sk-alert-text"><strong>{{ 'complete.form.actions.profile_is_completed' | translate }}</strong> – {{ 'complete.form.actions.you_can_login' | translate }}</span>&nbsp;<a [href]="portalUrl">{{ 'complete.form.actions.skykick_portal' | translate }}</a>
                            </div>
                        </div>
                        <div *ngIf="invitationExpired" class="alert alert-dismissible sk-alert-icon-24 alert-info d-flex align-items-center mb-100" role="alert">
                            <i class="material-icons md-22 ">info</i>
                            <div class="ml-100">
                              <span class="sk-alert-text"><strong>{{ 'complete.form.actions.invitation_link_expired' | translate }}</strong> – {{ 'complete.form.actions.check_your_email' | translate }}</span>
                            </div>
                        </div>
                        <div class="row">
                            <fieldset class="form-group col-xl mb-50 col-xl-12" [class.has-danger]="isFieldInvalid('firstName')" [disabled]="formDisabled">
                                <label class="font-bold">{{ 'complete.form.first_name' | translate }}</label>
                                <input type="text" class="form-control" name="firstName" formControlName="firstName" />
                                <small *ngIf="isFieldInvalid('firstName')" class="form-control-feedback">{{ 'complete.form.errors.field_is_empty' | translate }}</small>
                            </fieldset>
                        </div>
                        <div class="row">
                            <fieldset class="form-group col-xl mb-50 col-xl-12" [class.has-danger]="isFieldInvalid('lastName')" [disabled]="formDisabled">
                                <label class="font-bold">{{ 'complete.form.last_name' | translate }}</label>
                                <input type="text" class="form-control" name="lastName" formControlName="lastName" />
                                <small *ngIf="isFieldInvalid('lastName')" class="form-control-feedback">{{ 'complete.form.errors.field_is_empty' | translate }}</small>
                            </fieldset>
                        </div>
                        <div class="row">
                            <fieldset class="form-group col-xl mb-50 col-xl-12" >
                                <label class="font-bold">{{ 'complete.form.email_address' | translate }}</label>
                                <input type="text" class="form-control" name="emailAddress" value="{{ emailAddress }}" disabled />
                            </fieldset>
                        </div>
                        <div class="row">
                            <fieldset class="form-group col-xl mb-50 col-xl-12" [class.has-danger]="isFieldInvalid('jobTitle')" [disabled]="formDisabled">
                                <label class="font-bold">{{ 'complete.form.job_title' | translate }}</label>
                                <input type="text" class="form-control" name="jobTitle" formControlName="jobTitle" />
                                <small *ngIf="isFieldInvalid('jobTitle')" class="form-control-feedback">{{ 'complete.form.errors.field_is_empty' | translate }}</small>
                            </fieldset>
                        </div>
                        <div class="row">
                            <fieldset class="form-group col-xl mb-50 col-xl-12" [class.has-danger]="isFieldInvalid('cellPhone')" [disabled]="formDisabled">
                                <label class="font-bold">{{ 'complete.form.cell_phone' | translate }}</label>
                                <input type="text" class="form-control" name="cellPhone" formControlName="cellPhone" />
                                <small *ngIf="isFieldInvalid('cellPhone')" class="form-control-feedback">{{ 'complete.form.errors.field_is_empty' | translate }}</small>
                            </fieldset>
                        </div>
                        <div class="row">
                            <fieldset class="form-group col-sm mb-50 col-sm-8" [class.has-danger]="isFieldInvalid('businessPhone')" [disabled]="formDisabled">
                                <label class="font-bold">{{ 'complete.form.business_phone' | translate }}</label>
                                <input type="text" class="form-control" name="businessPhone" formControlName="businessPhone" />
                                <small *ngIf="isFieldInvalid('businessPhone')" class="form-control-feedback">{{ 'complete.form.errors.field_is_empty' | translate }}</small>
                            </fieldset>
                            <fieldset class="form-group col-sm mb-50 col-sm-4" [disabled]="formDisabled">
                                <label class="font-bold">{{ 'complete.form.extension' | translate }}</label>
                                <input type="text" class="form-control" name="extension" formControlName="extension" />
                            </fieldset>
                        </div>
                        <div class="row">
                            <div class="col-xl mb-150 mt-150 col-xl-12" >
                                <h3>{{ 'complete.form.password' | translate }}</h3>
                            </div>
                        </div>
                        <div class="row">
                            <fieldset class="form-group col-xl mb-50 col-xl-12" [class.has-danger]="isFieldInvalid('password') || isPasswordInvalid()" [disabled]="formDisabled">
                                <label class="font-bold">{{ 'complete.form.create_password' | translate }}</label>
                                <div class="sk-input-icon sk-input-group">
                                    <input
                                      [type]="showPassword ? 'text' : 'password'"
                                      class="form-control"
                                      name="password"
                                      formControlName="password"
                                      [ngbPopover]="passwordPopover"
                                      placement="bottom"
                                      [autoClose]="'outside'"
                                      popoverClass="password-popover" />
                                    <button type="button" class="btn sk-btn-icon-basic sk-password-toggle">
                                        <i class="material-icons md-18 sk-password-show" (click)="showPassword = !showPassword">{{showPassword ? 'visibility' : 'visibility_off'}}</i>
                                    </button>
                                </div>
                                <small *ngIf="isFieldInvalid('password') && completeProfileForm.controls.password.hasError('required')" class="form-control-feedback">{{ 'complete.form.errors.field_is_empty' | translate }}</small>
                                <ng-template #passwordPopover>
                                  <div class="password-popover-title">{{'platform.password.requirements' | translate}}:</div>
                                  <div class="password-rule-error" [class.password-rule-valid]="isPasswordRuleValid('minlength') && isPasswordRuleValid('maxlength')">{{ 'platform.password.length' | translate }}</div>
                                  <div class="password-rule-error" [class.password-rule-valid]="!hasPasswordPatternErrors()">{{ 'platform.password.pattern' | translate }}</div>
                                  <div class="password-pattern-error" [class.password-pattern-valid]="isPasswordRuleValid('hasLowerCase')">{{ 'platform.password.lowercase' | translate }}</div>
                                  <div class="password-pattern-error" [class.password-pattern-valid]="isPasswordRuleValid('hasUpperCase')">{{ 'platform.password.uppercase' | translate }}</div>
                                  <div class="password-pattern-error" [class.password-pattern-valid]="isPasswordRuleValid('hasNumber')">{{ 'platform.password.number' | translate }}</div>
                                  <div class="password-pattern-error" [class.password-pattern-valid]="isPasswordRuleValid('hasSpecialCharacters')">{{ 'platform.password.special_character' | translate }}</div>
                                </ng-template>
                            </fieldset>
                        </div>
                        <div class="row">
                            <fieldset class="form-group col-xl mb-50 col-xl-12" [class.has-danger]="isFieldInvalid('reenteredPassword') || isPasswordInvalid()" [disabled]="formDisabled">
                                <label class="font-bold">{{ 'complete.form.reenter_password' | translate }}</label>
                                <div class="sk-input-icon sk-input-group">
                                    <input [type]="showReenteredPassword ? 'text' : 'password'" class="form-control" name="reenteredPassword" formControlName="reenteredPassword" />
                                    <button type="button" class="btn sk-btn-icon-basic sk-password-toggle">
                                        <i class="material-icons md-18 sk-password-show" (click)="showReenteredPassword = !showReenteredPassword">{{showReenteredPassword ? 'visibility' : 'visibility_off'}}</i>
                                    </button>
                                </div>
                                <small *ngIf="isFieldInvalid('reenteredPassword')" class="form-control-feedback">{{ 'complete.form.errors.field_is_empty' | translate }}</small>
                                <small *ngIf="isPasswordInvalid()" class="form-control-feedback">{{ 'complete.form.errors.passwords_do_not_match' | translate }}</small>
                            </fieldset>
                        </div>
                        <div class="row">
                            <div class="col-xl mb-50 mt-50 col-xl-12" >
                                <button type="button" (click)="completeMemberProfile()" class="btn btn-lg btn-primary btn-block sk-has-spinner sk-abs-spinner" [disabled]="formDisabled">{{ 'complete.form.actions.save_and_login' | translate }}</button>
                            </div>
                        </div>
                    </div>
                </form>
                <div>
                    <div class="mb-100">
                        <div ngbDropdown class="btn-group dropup m-b-1" [ngClass]="{'open': openLanguageDropdown}">
                            <button ngbDropdownToggle type="button" (click)="toggleLanguageDropdown($event)" class="btn btn-basic dropdown-toggle">{{ 'complete.form.language' | translate }}</button>
                            <div ngbDropdownMenu class="dropdown-menu">
                                <a ngbDropdownItem class="dropdown-item" [ngClass]="{'active': language === selectedLanguage}" (click)="setLanguage(language)" *ngFor="let language of languages">
                                    {{ 'platform.languages.' + language | translate }}
                                </a>
                            </div>
                        </div>&nbsp;&nbsp;
                        <span>{{ 'complete.form.copyright' | translate }} {{ year }} {{ 'complete.form.all_rights_reserved' | translate }}</span>&nbsp;&nbsp;
                        <a href="https://www.skykick.com/partner-terms-conditions">{{ 'complete.form.terms_and_conditions' | translate }}</a>&nbsp;&nbsp;
                        <a href="https://www.skykick.com/privacy-policy">{{ 'complete.form.privacy_policy' | translate }}</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
