import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { NavigationArea } from '@skykick/core';
import { INTEGRATIONS_ROUTES } from './integrations/integrations-routing.module';
import { UserProfileComponent } from './user-profile/user-profile.component';
import { NOTIFICATIONS_ROUTES } from './notifications/notifications-routing.module';
import { ACCOUNT_ROUTES } from './account/account-routing.module';
import { SettingsComponent } from './settings.component';
import { USERS_ROUTES } from './users/users-routing.module';
import { AuthGuard } from '../utilities/AuthGuard';

export const SETTINGS_ROUTES: Routes = [
  {
    path: 'settings',
    redirectTo: 'settings/users/members',
    pathMatch: 'prefix',
  },
  {
    path: 'partner/admin/user-profile',
    redirectTo: 'settings/users/members',
    pathMatch: 'prefix',
  },
  {
    path: 'settings',
    component: SettingsComponent,
    canActivate: [AuthGuard],
    children: [
      ...USERS_ROUTES,
      ...NOTIFICATIONS_ROUTES,
      ...ACCOUNT_ROUTES,
      ...INTEGRATIONS_ROUTES,
      {
        path: 'profile',
        component: UserProfileComponent,
      },
    ],
    data: {
      SkNavigationArea: NavigationArea.Admin,
    },
  },
];

@NgModule({
  imports: [RouterModule.forChild(SETTINGS_ROUTES)],
  exports: [RouterModule],
})
export class SettingsRoutingModule {}
