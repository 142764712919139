import { Injectable } from '@angular/core';

import { CloudManagerOdataResult } from '../models/CloudManagerOdataResult';

@Injectable({
  providedIn: 'root',
})
export class OdataParsingService {
  constructor() {}

  parseCloudManagerOdataCountQueryResult(result: any): CloudManagerOdataResult {
    if (result === null) {
      var parsedResult: CloudManagerOdataResult = {
        count: null,
        isSubscriptionActive: false,
      };

      return parsedResult;
    } else {
      var parsedResult: CloudManagerOdataResult = {
        count: result['@odata.count'],
        isSubscriptionActive: true,
      };

      return parsedResult;
    }
  }
}
