import {
  Component,
  OnInit,
} from '@angular/core';

import { PartnerInfo } from './models/PartnerInfo';
import { DeveloperService } from './services/developer.service';

@Component({
  selector: 'sk-developer',
  templateUrl: './developer.component.html',
  styleUrls: ['./developer.component.scss'],
})
export class DeveloperComponent implements OnInit {
  partnerInfo: PartnerInfo;
  loading: boolean = true;
  failedToGetPartnerInfo: boolean = false;

  constructor(private developerService: DeveloperService) {}

  ngOnInit(): void {
    this.developerService.getPartnerInfo().subscribe(
      (x) => {
        this.partnerInfo = x.result;
        this.loading = false;
      },
      (err) => {
        this.failedToGetPartnerInfo = true;
        this.loading = false;
      }
    );
  }
}
