<div *ngIf="!isLoadingProductMappings">
    <form [formGroup]="productMappingForm" novalidate>
        <ng-container formArrayName="products">
            <table *ngIf="!isLoadingProductMappings" class="table sk-standard-wrap-table">
                <thead class="thead-white thead-sm thead-border-none">
                    <tr>
                        <th>Product Name</th>
                        <th>ConnectWise Service Name</th>
                        <th class="text-center">Mapping</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let item of skykickProducts; let index = index">
                        <ng-container [formGroupName]="index">
                            <td>{{item.name}}</td>
                            <td>
                                <input id="typeaheadConnectWiseProduct{{index}}" type="text" class="form-control" 
                                    formControlName="destination"
                                    [ngbTypeahead]="searchType(index)"
                                    [editable]="false"
                                    (focus)="focus$[index].next($any($event).target.value)"
                                    #instance="ngbTypeahead" 
                                    placeholder="Type name" />
                            </td>
                            <td class="text-center">
                                <i *ngIf="item.productMappingId" class="material-icons md-22 icon-success">check_circle</i>
                                <span *ngIf="!item.productMappingId" class="badge badge-default">Not mapped</span>
                            </td>
                            <td class="text-center">
                                <button (click)="removeMapping(item)" *ngIf="item.productMappingId" 
                                    class="btn btn-outline-none sk-has-spinner sk-abs-spinner sk-btn-icon"
                                    [class.active]="item.active" [disabled]="item.active">
                                    <div class="sk-btn-spinner">
                                        <div class="sk-spin-loader loader-dark"></div>
                                    </div><span class="sk-loader-label"><i class="material-icons md-22">delete</i></span>
                                </button>
                            </td>
                        </ng-container>
                    </tr>
                </tbody>
            </table>

            <div *ngIf="productMappingForm.hasError('atLeastOneRequired')" class="mt-3">
                <div class="form-group has-danger">
                    <small class="form-control-feedback">Please map at least one product.</small>
                </div>
            </div>

            <button id="updateProductMappingButton" class="btn btn-primary sk-has-spinner sk-abs-spinner mt-150" [class.active]="false"
                [disabled]="productMappingForm.invalid" (click)="createProductMappings()">
                <div class="sk-btn-spinner">
                    <div class="sk-spin-loader loader-dark"></div>
                </div>
                <span class="sk-loader-label" translate>Update</span>
            </button>

        </ng-container>
    </form>
</div>
<sk-text-spinner *ngIf="isLoadingProductMappings" message="Loading product mappings"></sk-text-spinner>
