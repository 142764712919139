<div class="p-150 border-bottom">
    <div class="form-group sk-input-icon">
        <div class="sk-input-group">
            <input type="text" class="form-control" placeholder="Search" />
            <i class="material-icons md-18"></i>
        </div>
    </div>
</div>
<div class="px-150">
    <form [formGroup]="customerAccountsForm" novalidate>
        <div formArrayName="accounts">
            <ngb-accordion #a="ngbAccordion" [closeOthers]="true" activeIds="panel-0">
                <ng-container *ngFor="let item of accountsFormArray.controls; let index = index">
                    <ngb-panel [formGroupName]="index" id="panel-{{index}}" cardClass="accordion-overwrite">
                        <ng-template ngbPanelHeader [attr.let-opened]="index === 0 ? 'opened' : null">

                            <div class="d-flex flex-row align-items-center justify-content-between">
                                <h5>{{customerAccounts[index].name}}</h5>
                                <div class="justify-content-between">
                                    <button type="button" class="btn sk-btn-icon">
                                        <i class="material-icons md-22 icon-success"
                                            [class.icon-success]="customerAccounts[index].isActive">check_circle</i>
                                    </button>
                                    <button ngbPanelToggle type="button" class="btn sk-btn-icon">
                                        <i class="material-icons md-22">keyboard_arrow_down</i>
                                    </button>
                                </div>
                            </div>

                        </ng-template>
                        <ng-template ngbPanelContent>
                            <fieldset class="form-group">
                                <label>ConnectWise Account Name</label>
                                <select class="form-control custom-select" formControlName="accountNameSelect">
                                    <option id="accountNameOption" [ngValue]="null" [disabled]="true">Select
                                    </option>
                                </select>
                            </fieldset>

                            <fieldset class="form-group">
                                <label>Agreement Name <i
                                        class="material-icons md-18 icon-muted align-middle">help_outline</i></label>
                                <select class="form-control custom-select"
                                    formControlName="agreementNameSelect">
                                    <option id="agreementNameOption" [ngValue]="null" [disabled]="true">Select
                                    </option>
                                </select>
                            </fieldset>

                            <fieldset class="form-group">
                                <div class="custom-control custom-checkbox sk-checkbox mt-50">
                                    <input type="checkbox" class="custom-control-input" id="includeProration"
                                        formControlName="includeProration" />
                                    <label class="custom-control-label" for="includeProration">Include Proration
                                        <i
                                            class="material-icons md-18 icon-muted align-middle">help_outline</i></label>
                                </div>
                            </fieldset>

                            <fieldset class="form-group mb-0">
                                <div class="custom-control custom-checkbox sk-checkbox">
                                    <input type="checkbox" class="custom-control-input" id="includeUsernames"
                                        formControlName="includeUsernames" />
                                    <label class="custom-control-label" for="includeUsernames">Include Usernames
                                        <i
                                            class="material-icons md-18 icon-muted align-middle">help_outline</i></label>
                                </div>
                            </fieldset>
                        </ng-template>
                    </ngb-panel>
                </ng-container>
            </ngb-accordion>
        </div>
    </form>
</div>
