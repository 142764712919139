import { Component, HostBinding, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { SkyKickModalOptions, SkyKickModalService, TaskManagerService } from '@skykick/core';
import { AbstractUserProvider } from '@skykick/platform-identity-auth-auth0-angular';
import { ToastrService } from 'ngx-toastr';
import { EMPTY, Subject, throwError } from 'rxjs';
import { takeUntil, catchError, tap, switchMap, finalize } from 'rxjs/operators';
import { TaskType } from 'src/app/settings/models/task-type';
import { WizardStepComponent } from '../../core/models/WizardStepComponent';
import { ActionButtonsService } from '../../core/services/action-buttons.service';
import { PartnerResourcesService } from '../../core/services/partner-resources.service';
import { ConnectwiseResourcesService } from '../../core/services/connectwise-resources.service';
import { ConnectwiseStateManagerService } from '../../core/services/connectwise-state-manager.service';

@Component({
    selector: 'sk-authenticate',
    templateUrl: './authenticate.component.html',
    styleUrls: [ './authenticate.component.scss' ]
})
export class AuthenticateComponent implements WizardStepComponent, OnInit {
    private onDestroy$ = new Subject();
    private errorOptions: SkyKickModalOptions = {
        body: `<p class="mb-200">There was a problem processing your request</p>`,
        title: 'Error',
        btnLabel: 'OK'
    };
    @HostBinding('class') class='d-flex flex-column flex-grow-1';
    @Input() data: any;
    @Input() settingType: string;
    authenticateForm: FormGroup;
    showTandC: boolean;
    isLoadingCredentials: boolean;
    isVerifyingCredentials: boolean;
    verificationCredsSuccessful: boolean;
    credentials: any;
    errors: Array<string> = [];
    partnerData = this.connectwiseStateManagerService.getConnectWisePartner();
    isBusy: boolean = false;

    private validateAllFormFields(formGroup: FormGroup) {
        Object.keys(formGroup.controls).forEach(field => {
            const control = formGroup.get(field);
            if (control instanceof FormControl) {
                control.markAsDirty({ onlySelf: true });
            } else if (control instanceof FormGroup) {
                this.validateAllFormFields(control);
            }
        });
    }

    private showErrorInfo(res) {
        if (res) {
            if (res.status === 400 && res.error?.startsWith('HTTP 401') === true) {
                this.authenticateForm.setErrors({ credsInvalid: true});
            } else if (res.status === 404 || res.status === 400) {
                if (res.error?.includes('Invalid connectWise URL / companyName provided')) {
                    this.authenticateForm.setErrors({ urlOrCompanyInvalid: true});
                } else {
                    this.authenticateForm.setErrors({ urlInvalid: true});
                }
            } else {
                this.skyKickModalService.error(this.errorOptions);
            }
        }
    }

    private createConnectWisePartner(partnerName: string) {
        const payload = { partnerId: this.abstractUserProvider.getCurrentUser().partnerId, name: partnerName, enabled: true };
        this.connectwiseResourcesService.createPartner(payload).pipe(
            takeUntil(this.onDestroy$),
            switchMap(() => this.connectwiseResourcesService.getPartner().pipe(
                tap(res => {
                    this.connectwiseStateManagerService.setConnectWisePartner(res);
                    this.partnerData = res;
                }),
                catchError(res => {
                    this.skyKickModalService.error(this.errorOptions);
                    return EMPTY;
                })
            )),
            switchMap(res => this.connectwiseResourcesService.saveCredentials(this.credentials).pipe(
                catchError(res => {
                    this.skyKickModalService.error(this.errorOptions);
                    return EMPTY;
                })
            )),
            catchError(res => {
                this.skyKickModalService.error(this.errorOptions);
                return EMPTY;
            }),
            finalize(() => this.isBusy = false)
        ).subscribe(res => {
            console.log(res);
            this.connectwiseStateManagerService.markAsCompleteWizardNav(this.data.type);
            this.actionButtonsService.submitWizardStep(this.data);
        });
    }

    constructor(
        private formBuilder: FormBuilder,
        public activeModal: NgbActiveModal,
        private actionButtonsService: ActionButtonsService,
        private connectwiseResourcesService: ConnectwiseResourcesService,
        private toastrService: ToastrService,
        private connectwiseStateManagerService: ConnectwiseStateManagerService,
        private abstractUserProvider: AbstractUserProvider,
        private skyKickModalService: SkyKickModalService,
        private taskManagerService: TaskManagerService,
        private partnerResourcesService: PartnerResourcesService
    ) {}

    ngOnInit(): void {
        this.authenticateForm = this.formBuilder.group({
            companyName: ['', [Validators.required]],
            connectWiseURL: ['', [Validators.required]],
            partnerId: this.abstractUserProvider.getCurrentUser().partnerId,
            privateKey: ['', [Validators.required]],
            publicKey: ['', [Validators.required]],
            tandc: ['', Validators.requiredTrue]
        });

        if (this.partnerData) {
            //if CW Partner exists
            this.isLoadingCredentials = true;
            this.connectwiseResourcesService.getCredentials().pipe(
                takeUntil(this.onDestroy$),
                tap(res => {
                    this.credentials = res;
                    res.privateKey = '';
                    this.authenticateForm.patchValue(res);
                    this.isLoadingCredentials = false;
                    this.connectwiseStateManagerService.markAsCompleteWizardNav(this.data.type);
                }),
                catchError(() => {
                    this.isLoadingCredentials = false;
                    return EMPTY;
                })
            ).subscribe();
        }
    }

    isFieldValid(field: string, type?: string) {
        if (type) {
            return this.authenticateForm.get(field).hasError(type) && !this.authenticateForm.get(field).pristine && !this.authenticateForm.disabled;
        }
        return !this.authenticateForm.get(field).valid && !this.authenticateForm.get(field).pristine && !this.authenticateForm.disabled;
    }

    displayFieldCss(field: string) {
        return {
            'has-danger': this.isFieldValid(field)
        };
    }

    validate() {
        this.errors.length = 0;
        if (this.authenticateForm.valid) {
            let payload = this.authenticateForm.value;
            this.isVerifyingCredentials = true;
            this.verificationCredsSuccessful = false;

            if (this.credentials && this.partnerData) {
                //creds already existed and partner existed, validate and update creds
                this.connectwiseResourcesService.validateCredentials(payload).pipe(
                    switchMap(res => {
                        return this.connectwiseResourcesService.updateCredentials(payload).pipe(
                            tap(() => {
                                this.isVerifyingCredentials = false;
                                this.toastrService.success('Update successful');
                            }),
                            catchError(res => {
                                this.isVerifyingCredentials = false;
                                let error = JSON.parse(res.error);
                                this.errors.push(error.Message);
                                return throwError(error);
                            })
                        )
                    }),
                    takeUntil(this.onDestroy$),
                    catchError(error => {
                        this.showErrorInfo(error);
                        this.isVerifyingCredentials = false;
                        return throwError(error);
                    })
                ).subscribe();
            } else {
                //set credentials for the first time
                this.connectwiseResourcesService.validateCredentials(payload).pipe(
                    takeUntil(this.onDestroy$)
                ).subscribe(() => {
                    this.credentials = payload;
                    this.isVerifyingCredentials = false;
                    this.verificationCredsSuccessful = true;
                    this.toastrService.success('Validation successful');
                }, res => {
                    this.showErrorInfo(res);
                    this.isVerifyingCredentials = false;
                });
            }
        } else {
            this.validateAllFormFields(this.authenticateForm);
        }
    }

    submit() {
        this.isBusy = true;
        if (!this.partnerData) {
            this.partnerResourcesService.getPartnerInfo()
                .subscribe(partnerInfo => {
                    this.createConnectWisePartner(partnerInfo.name);
                });
        } else {
            this.actionButtonsService.submitWizardStep(this.data);
        }
        this.taskManagerService.abortTask(TaskType.ConnectWiseInfo);
    }

    ngOnDestroy(): void {
        this.onDestroy$.next();
    }

}