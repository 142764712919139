import { Component, EventEmitter, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { LogoUpload } from '../models/logo-upload';
import { UploaderService } from '../services/uploader.service';

@Component({
    selector: 'sk-platform-branding',
    templateUrl: './platform-branding.component.html',
    styleUrls: ['./platform-branding.component.scss']
})
export class PlatformBrandingComponent implements OnInit {
    refreshLogo = new EventEmitter();
    progress: number;
    isUploading: boolean;
    file: File;
    imageError: string;
    cardImageBase64: string;
    logoUpload: LogoUpload;

    constructor(
        private toastrService: ToastrService,
        private translate: TranslateService,
        private uploader: UploaderService
    ) { }

    ngOnInit(): void {
        this.uploader.progressSource.subscribe(progress => {
            this.progress = progress;
        });
    }

    onChange(file: File) {
        this.removeImage();

        if (file) {
            const allowed_types = ['image/png', 'image/jpeg', 'image/gif'];
            const max_size = 1000000;

            if (file.size > max_size) {
                this.imageError = 'Maximum size allowed is ' + max_size / 1000 + 'Mb';
                return false;
            }

            const fileType = allowed_types.find((x) => x === file.type);
            if (!fileType) {
                this.imageError = 'Only Images are allowed ( JPG | PNG | GIF )';
                return false;
            }

            this.file = file;

            const reader = new FileReader();
            reader.readAsDataURL(file);

            reader.onload = (event: any) => {

                this.cardImageBase64 = event.target.result;

                this.logoUpload = new LogoUpload(
                    reader.result.toString().split(',')[1],
                    file.type,
                    file.name
                );
            }
        }
    }
    
    removeImage() {
        this.cardImageBase64 = null;
        this.progress = 0;
        this.file = undefined;
        this.imageError = null;
    }
    
    onUpload() {
        this.progress = 0;
        this.isUploading = true;

        this.uploader.upload(this.file, this.logoUpload).subscribe(() => {
            this.isUploading = false;
            this.toastrService.success(this.translate.instant('COMMON.SUCCESS'));
            this.removeImage();
        }, res => {
            if (res.error?.message) {
                this.imageError = res.error.message;
            } else {
                this.toastrService.error(this.translate.instant('settings.common.error.request-issue'));
            }
            this.isUploading = false;
        });
    }
    
}
