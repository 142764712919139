import { Injectable } from '@angular/core';

import {
  Customer,
  CustomerResult,
} from '../models';
import { CustomersAccessProvider } from './customers.access.provider';
import { CustomersPermissionProvider } from './customers.permission.provider';
import { CustomersPermissionSummaryProvider } from './customers.permission.summary.provider';
import { CustomerStatusProvider } from './customers.status.provider';

@Injectable({
    providedIn: 'root'
})
export class CustomersConverterService {
    public convertToCustomer(customerResult: CustomerResult): Customer{
        const accessRight = CustomersAccessProvider.getAccessValueByKey(customerResult.Access);
        const accessPermission = CustomersPermissionProvider.getPermissionValueByKey(customerResult.Permission);
        const customer: Customer = {
            id: customerResult.Id,
            username: customerResult.Username,
            email: customerResult.EmailAddress,
            fullName: customerResult.FirstName + ' ' + customerResult.LastName,
            orderName: customerResult.OrderName,
            permission: accessPermission,
            status: CustomerStatusProvider.getStatusValueByKey(customerResult.Status),
            access: accessRight,
            permissionScopes:
                CustomersPermissionSummaryProvider.getPermissionScopes(accessRight, accessPermission)
        };
        return customer;
    }
}
