import { CommonModule } from '@angular/common';
import {
  HttpClient,
  HttpClientModule,
  HTTP_INTERCEPTORS,
} from '@angular/common/http';
import { APP_INITIALIZER, NgModule, ErrorHandler } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { IntercomModule } from 'ng-intercom';
import { CookieService } from 'ngx-cookie-service';
import { environment } from 'src/environments/environment';

import {
  TranslateLoader,
  TranslateModule,
  TranslateService,
  TranslateStore,
} from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import {
  BannerModule,
  HeaderModule,
  LayoutModule,
  NavigationModule,
  UtilityModule,
} from '@skykick/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CommunicationsModalComponent } from './communications/communications-modal/communications-modal.component';
import { CommunicationsTabComponent } from './communications/communications-tab/communications-tab.component';
import { CommunicationsComponent } from './communications/communications.component';
import { CompleteMemberProfileFormModule } from './complete-member-profile-form/complete-member-profile-form.module';
import { DeveloperModule } from './developer/developer.module';
import { DnsManagerModule } from './dns-manager/dns-manager.module';
import { HomeModule } from './home/home.module';
import { PortalModule } from './portal/portal.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { BuiltWithLoveComponent } from './built-with-love/built-with-love.component';
import { LogoutComponent } from './logout/logout.component';
import { ErrorHandlerService } from './services/error-handler.service';
import { MpnRegistrationModule } from './mpn-registration/mpn-registration.module';
import { AuthGuard } from './utilities/AuthGuard';
//import { RouteInterceptor } from './interceptors/route-interceptor';
// Note: If reactive forms module is not imported in the app.module.ts, then you may get this error:
// error NG8002: Can't bind to 'formGroup' since it isn't a known property of 'form'.

export function LanguageLoader(
  translate: TranslateService,
  cookieService: CookieService
): () => Promise<void> {
  let promise = new Promise<void>((resolve) => {
    const cookieLang = cookieService.get('skLanguageCode');
    const browserLang = cookieLang || translate.getBrowserLang();
    const lang = browserLang.match(/en|ja|sq|de/) ? browserLang : 'en';
    translate.use(lang);
    resolve();
  });
  return async () => promise;
}

export function TranslationLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    AppComponent,
    CommunicationsComponent,
    CommunicationsTabComponent,
    CommunicationsModalComponent,
    BuiltWithLoveComponent,
    LogoutComponent,
  ],
  imports: [
    CommonModule,
    BannerModule,
    BrowserModule,
    BrowserAnimationsModule,
    NavigationModule,
    HeaderModule,
    LayoutModule,
    AppRoutingModule,
    HttpClientModule,
    NgbModule,
    PortalModule,
    CompleteMemberProfileFormModule,
    MpnRegistrationModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: TranslationLoaderFactory,
        deps: [HttpClient],
      },
      isolate: true,
    }),
    UtilityModule.forRoot({ sewrSystemDomain: environment.sewrSystemDomain }),
    HomeModule,
    DeveloperModule,
    DnsManagerModule,
    IntercomModule.forRoot({
      appId: 'itg40p5r',
      updateOnRouterChange: true,
      horizontal_padding: 20,
      vertical_padding: 20,
    }),
  ],
  providers: [
    TranslateStore,
    {
      provide: APP_INITIALIZER,
      useFactory: LanguageLoader,
      multi: true,
      deps: [TranslateService, CookieService],
    },
    CookieService,
    { provide: ErrorHandler, useClass: ErrorHandlerService },
    AuthGuard
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
