<aside sk-panel-view [skTitle]="getDisplayName()">
    <div class="sk-panel-content">
        <div class="sk-panel-scroll px-150">
            <div class="mb-150">
                <h4 class="mb-50">{{ 'settings.members.account-info' | translate }}</h4>
                <dl class="row list-light line-height-24">
                    <dt class="col-sm-3 text-truncate">{{ 'settings.members.email-address' | translate }}</dt>
                    <dd class="col-sm-9 text-truncate">{{ member.email }}</dd>
                    <dt class="col-sm-3 text-truncate">{{ 'settings.members.display-name' | translate }}</dt>
                    <dd class="col-sm-9 text-truncate">{{ getDisplayName() }}</dd>
                    <dt class="col-sm-3 text-truncate">{{ 'settings.members.permission' | translate }}</dt>
                    <dd class="col-sm-9 text-truncate">{{ getPermissionLocKey(member.role) | translate }}</dd>
                </dl>
            </div>
            <hr>
            <div class="mb-150">
                <h4 class="mb-50">{{ 'settings.members.access.access' | translate }}</h4>
                <dl class="sk-dl line-height-24">
                    <ng-container *ngFor="let scope of member.permissionScopes">
                        <dt>{{ scope.displayNameLocKey | translate }}</dt>
                        <ng-container *ngFor="let permissionDisplayNameLocKey of scope.permissionDisplayNameLocKeys">
                            <dd class="d-flex align-items-center">
                                <i class="material-icons md-18 icon-success mr-50">check_circle</i>
                                {{ permissionDisplayNameLocKey | translate }}
                            </dd>
                        </ng-container>
                    </ng-container>
                </dl>
            </div>
            <div class="mb-150" *ngIf="member.partnerClaims">
                <h4 class="mb-50">{{ 'settings.common.authentication.authentication' | translate }}</h4>
                <div class="d-inline-flex">
                    <p class="badge badge-default" [class.badge-info]="isM365Authentication()">
                        {{ getAuthTypeLocKey() | translate }}
                    </p>
                </div>
                <small *ngIf="isCurrentUserAdmin()" class="sk-form-text" [innerHtml]="'settings.common.authentication.to-change-auth' 
                    | translate: { accountSettingsPageUrl: getAccountSettingsPageUrl() }">
                </small>
            </div>
        </div>
    </div>
    <footer *ngIf="showFooter() && isCurrentUserAdmin()">
        <button type="button" class="btn btn-outline-secondary" (click)="editPermissions()">
            {{ 'settings.members.actions.edit-permissions' | translate }}
        </button>
    </footer>
</aside>
