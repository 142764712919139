<div class="modal-header bg-white pb-0">
    <div class="d-flex flex-column">
        <div class="d-flex align-items-center">
            <h3 class="modal-title font-normal">{{pageTitle}}</h3>
        </div>
    </div>

    <button type="button" class="close" (click)="activeModal.dismiss()" id="closeBtn">
        <i class="material-icons md-22">close</i>
    </button>
</div>
<div class="modal-body">
    <form [formGroup]="form">
        <fieldset class="form-group">
            <label translate="settings.account.DISTRIBUTOR_NAME"></label>
            <div class="sk-input-group">
                <select id="distributorId" class="form-control custom-select"
                    formControlName="distributorId">
                    <option id="distributorIdOption" [ngValue]="null" [disabled]="true" 
                        translate="settings.account.DISTRIBUTOR_SELECT_AVAILABLE_DISTRIBUTOR"></option>
                    <option id="distributorIdOption{{index}}" *ngFor="let item of availableDistributors$ | async; let index = index" [ngValue]="item.id">{{item.name}}</option>
                </select>
                <div *ngIf="isLoading" class="sk-spin-loader loader-sm"></div>
            </div>
        </fieldset>
    
        <fieldset class="form-group input-width-max" [ngClass]="displayFieldCss('resellerId')">
            <label translate="settings.account.DISTRIBUTOR_PROVIDED_ID"></label>
            <input type="text" class="form-control" formControlName="resellerId" id="resellerId" />
            <small class="sk-form-text text-muted" [innerHTML]="'settings.account.DISTRIBUTOR_CONTACT_DISTRIBUTOR' | translate: {url: 'https://support.skykick.com/hc/en-us/articles/360014078573' }"></small>
            <small *ngIf="isFieldValid('resellerId', 'required')" class="form-control-feedback">Required field</small>
        </fieldset>
    </form>

    <ng-container *ngIf="errors">
        <div class="mt-3">
            <div class="form-group has-danger">
                <small class="form-control-feedback" *ngFor="let error of errors" [innerText]="error"></small>
            </div>
        </div>    
    </ng-container>

</div>
<div class="modal-footer justify-content-start">
    <button (click)="submit()" id="submitBtn" [disabled]="isLoading || form.invalid || isUpdating" [class.active]="isUpdating"  class="btn btn-primary sk-has-spinner sk-abs-spinner">
        <div class="sk-btn-spinner">
            <div class="sk-spin-loader loader-dark"></div>
        </div>
        <span class="sk-loader-label">{{positiveButton}}</span>
    </button>
    <button id="cancelBtn" type="button" class="btn btn-outline-secondary" (click)="activeModal.dismiss()" [disabled]="isUpdating">{{negativeButton}}</button>
</div>