import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import {
  AbstractUserProvider,
  ISkyKickPartnerPortalUser,
} from '@skykick/platform-identity-auth-auth0-angular';

@Injectable({
  providedIn: 'root'
})
export class AppInsightsMonitorService {

  appInsights: ApplicationInsights;
  constructor(
    private userProvider: AbstractUserProvider
  ) {
    this.appInsights = new ApplicationInsights({
      config: {
        enableAutoRouteTracking: true, // option to log all route changes
        enableCorsCorrelation: true,
        instrumentationKey: environment.appInsightsInstrumentationKey,
        correlationHeaderExcludedDomains: [
          "*.zdassets.com",
          "*.authorize.net",
        ]
      }
    });
    this.appInsights.loadAppInsights();
    this.setUserContext();
  }

  logPageView(name?: string, url?: string) { // option to call manually
    this.appInsights.trackPageView({
      name: name,
      uri: url
    });
  }

  logEvent(name: string, properties?: { [key: string]: any }) {
    this.appInsights.trackEvent({ name: name }, properties);
  }

  logMetric(name: string, average: number, properties?: { [key: string]: any }) {
    this.appInsights.trackMetric({ name: name, average: average }, properties);
  }

  logException(exception: Error, severityLevel?: number) {
    this.appInsights.trackException({ exception: exception, severityLevel: severityLevel });
  }

  logTrace(message: string, properties?: { [key: string]: any }) {
    this.appInsights.trackTrace({ message: message }, properties);
  }

  setAuthenticatedUserContext(contactId: string, partnerId?: string, storeInCookie: boolean = false) {
    this.appInsights.setAuthenticatedUserContext(contactId, partnerId, storeInCookie);
  }

  clearAuthenticatedUserContext() {
    this.appInsights.clearAuthenticatedUserContext();
  }

  private setUserContext(): void {
    let user: ISkyKickPartnerPortalUser;
    try {
      user = this.userProvider.getCurrentUser();
      this.appInsights.setAuthenticatedUserContext(user.userId, user.partnerId, true);
    }
    catch (e) {
      // This method will fail unless user is logged in
      // If the exception is not caught, login redirect will break
    }
  }
}
