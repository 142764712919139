<!--
	Icon with super- and sub-scripts.
-->

<div class="">

  <!-- text above the icon -->
  <div class="ellipsis">
    {{communicationDetails.iconSuperscript}}
  </div>

  <!-- icon -->
  <div>
      <a id="0" (click)="open(content)">
        <img alt="" class="cursorPointer" src="./assets/communications/images/pageicon2.jpg">
      </a>
  </div>

  <!-- text below the icon -->
  <div class="ellipsis">
    <small>
      {{communicationDetails.timingOfEmail1}}
      <br>
      {{communicationDetails.timingOfEmail2}}
    </small>
  </div>

</div>







<!--
	Modal popup with corresponding content.
-->


<ng-template #content let-modal class="modal-sm">

      <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Communication Emails Preview</h4>
        <button sk-id-gen="SkModalClose" 
                type="button" 
                class="close" 
                data-dismiss="modal" 
                aria-label="Close" 
                (click)="modal.dismiss('Cross click')" 

                aria-hidden="false"> 
          <span aria-hidden="true">
            <i class="material-icons"></i>
          </span> 
        </button>
      </div>



      <div class="modal-body">
        <div class="sk-communications-legacy-info p-3 ng-scope">
          <div>
              <span class="sk-comms-label text-muted">Timing of email:  </span>
              <span class="value ng-binding">
                {{communicationDetails.timingOfEmail1}} {{communicationDetails.timingOfEmail2}}
              </span>
          </div>
          <div>
              <span class="sk-comms-label text-muted">Email recipient:  </span>
              <span class="value ng-binding">{{communicationDetails.emailReceipient}}</span>
          </div>
          <div>
              <span class="sk-comms-label text-muted">Subject of email:  </span>
              <span class="value ng-binding">{{communicationDetails.subjectOfEmail}}</span>
          </div>
      </div>

      <div  style="height:550px; overflow-y:scroll;">
          <div [innerHTML]="html">
          
          </div>
      </div>

      </div>

      <div class="modal-footer">
        <button type="button" class="btn btn-primary" (click)="modal.close('Save click')">Ok</button>
      </div>

  </ng-template>

















