import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { RouteResolverService, AppId } from '@skykick/core';
import { AuthService } from '../auth.service';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(
    private authService: AuthService,
    private router: Router,
    private routeResolverService: RouteResolverService
  ) {}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean | Promise<boolean> {

    var isCustomerUser = this.authService.isCustomerUser();
    if (isCustomerUser) {
      if (this.authService.hasRole('Cloud_Backup_Full_Access')) {
        window.location.href = this.routeResolverService.generateRatRoute(
          AppId.Backup
        );
      } else if (this.authService.hasRole('Migrations_Full_Access')) {
        window.location.href = this.routeResolverService.generateRatRoute(
          AppId.Migrate
        );
      } else if (this.authService.hasRole('manager_view')) {
        window.location.href = this.routeResolverService.generateRatRoute(
          AppId.Manager
        );
      }
      this.router.navigate(['/login']);
      return false;
    }

    //If you are not a customer user you are allowed through
    return true;
  }
}
