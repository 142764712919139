import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ConnectwiseResourcesService } from '../services/connectwise-resources.service';

@Injectable()
export class ConnectWisePartnerResolver implements Resolve<any> {

    constructor(
        private connectwiseResourcesService: ConnectwiseResourcesService
    ) { }

    resolve() {
        return this.connectwiseResourcesService.getPartner().pipe(
            catchError((res) => {
                return of(null);
            })
        );
    }
}
