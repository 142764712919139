<div class="modal-body">
    <h3>Ticket Mapping</h3>
    <p class="mt-100">Tickets generated in case of a data push failure will be part of the service board you choose below. 
        <a href="https://support.skykick.com/hc/en-us/articles/360003975094#IntegrateCBintoCW" target="_blank">Learn more</a>
    </p>

    <form [formGroup]="ticketMappingForm" novalidate>
        <table class="table sk-standard-wrap-table">
            <thead class="thead-white thead-sm thead-border-none">
                <tr>
                    <th></th>
                    <th>ConnectWise Service Board</th>
                    <th class="text-center">Mapping</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>Ticket Mapping</td>
                    <td>
                        <div class="sk-input-group">
                            <select id="connectWiseServiceBoard" class="form-control custom-select"
                                formControlName="connectWiseServiceBoardSelect">
                                <option id="connectWiseServiceBoardOption" [ngValue]="null" [disabled]="true">Select Service Board</option>
                                <option id="connectWiseServiceBoardOption{{index}}" 
                                    *ngFor="let item of connectWiseServiceBoards$ | async; let index = index" 
                                    [ngValue]="item">{{item.name}}</option>
                            </select>
                            <div *ngIf="isLoadingServiceBoards" class="sk-spin-loader loader-sm"></div>
                        </div>
                    </td>
                    <td class="text-center">
                        <i *ngIf="ticketMapping" class="material-icons md-22 icon-success">check_circle</i>
                        <span *ngIf="!ticketMapping" class="badge badge-default">Not mapped</span>
                    </td>
                    <td class="text-center">
                        <button (click)="deleteMapping()" *ngIf="ticketMapping" 
                            class="btn btn-outline-none sk-has-spinner sk-abs-spinner sk-btn-icon"
                            [class.active]="isLoadingServiceBoards" [disabled]="isLoadingServiceBoards || isBusy">
                            <div class="sk-btn-spinner">
                                <div class="sk-spin-loader loader-dark"></div>
                            </div><span class="sk-loader-label"><i class="material-icons md-22">delete</i></span>
                        </button>
                    </td>
                </tr>
            </tbody>
        </table>
            
        <ng-container *ngIf="errors">
            <div class="mt-3">
                <div class="form-group has-danger">
                    <small class="form-control-feedback" *ngFor="let error of errors" [innerText]="error"></small>
                </div>
            </div>    
        </ng-container>

    </form>
</div>

<div class="modal-footer justify-content-start">
    <button *ngIf="settingType === 'wizard'" id="submitBtn" (click)="submit()" [disabled]="isLoadingServiceBoards || isBusy" [class.active]="isLoadingServiceBoards || isBusy"
        class="btn btn-primary sk-has-spinner sk-abs-spinner">
        <div class="sk-btn-spinner">
            <div class="sk-spin-loader loader-dark"></div>
        </div>
        <span class="sk-loader-label">Save & continue</span>
    </button>
    <button id="cancelBtn" type="button" class="btn btn-secondary" [class.disabled]="ticketMappingForm.disabled" (click)="activeModal.dismiss()">Cancel</button>
</div>
