import {
  animate,
  style,
  transition,
  trigger,
} from '@angular/animations';
import {
  Component,
  OnInit,
} from '@angular/core';

import {
  AppId,
  RouteResolverService,
} from '@skykick/core';

import { CloudManagerViewState } from '../../models/CloudManagerViewStateEnum';
import { CardsResourcesService } from '../../services/cards-resources.service';
import { OdataParsingService } from '../../services/odata-parsing.service';

@Component({
  selector: 'sk-cloud-manager-card',
  templateUrl: './cloud-manager-card.component.html',
  styleUrls: ['./cloud-manager-card.component.scss'],
  animations: [
    trigger('animateIn', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('.5s ease-out', style({ opacity: 1 })),
      ]),
    ]),
    trigger('expandIn', [
      transition(':enter', [
        style({ height: 0, opacity: 0 }),
        animate('.5s ease-out', style({ height: 35, opacity: 1 })),
      ]),
    ]),
  ],
})
export class CloudManagerCardComponent implements OnInit {
  customerCountLoading: boolean = true;
  commandCountLoading: boolean = true;
  dataQueryError: boolean = false;
  customerCount: number;
  commandCount: number;
  isSubscriptionActive: boolean;

  ViewStates = CloudManagerViewState;
  viewState: CloudManagerViewState;

  constructor(
    private routeResolverService: RouteResolverService,
    private parsingService: OdataParsingService,
    private cardService: CardsResourcesService
  ) {}

  ngOnInit(): void {
    this.cardService.getCloudManagerCustomerCount().subscribe(
      (x) => {
        var parsedOdataResult =
          this.parsingService.parseCloudManagerOdataCountQueryResult(x);
        this.customerCount = parsedOdataResult.count;

        this.isSubscriptionActive = parsedOdataResult.isSubscriptionActive;

        this.customerCountLoading = false;
        this.determineUIState();
      },
      (error) => {
        console.log('Error: Failed to fetch CM customer count.');
        this.customerCountLoading = false;
        this.dataQueryError = true;
        this.determineUIState();
      }
    );
    this.cardService.getCloudManagerCommandCount().subscribe(
      (x) => {
        var parsedOdataResult =
          this.parsingService.parseCloudManagerOdataCountQueryResult(x);
        this.commandCount = parsedOdataResult.count;

        this.isSubscriptionActive = parsedOdataResult.isSubscriptionActive;

        this.commandCountLoading = false;
        this.determineUIState();
      },
      (error) => {
        console.log('Error: Failed to fetch CM command count.');
        this.commandCountLoading = false;
        this.dataQueryError = true;
        this.determineUIState();
      }
    );
  }

  determineUIState(): void {
    if (this.dataQueryError || !this.isSubscriptionActive) {
      //If we get an error fetching this information
      this.viewState = CloudManagerViewState.showActivateTrialPrompts;
      return;
    }

    //Wait for API calls to finish
    if (this.customerCountLoading || this.commandCountLoading) {
      return;
    }

    if (this.customerCount == 0) {
      this.viewState = CloudManagerViewState.showAddCustomersPrompts;
    } else if (this.customerCount > 0 && this.commandCount == 0) {
      this.viewState = CloudManagerViewState.showRunCommandPrompts;
    } else {
      this.viewState = CloudManagerViewState.showCommandCenterPrompts;
    }
  }

  getFreeTrialUrl(): string {
    return this.routeResolverService.generateRatRoute(
      AppId.CloudManager,
      '/introduction'
    );
  }

  getAddCustomerUrl(): string {
    return this.routeResolverService.generateRatRoute(
      AppId.CloudManager,
      '/organizations'
    );
  }

  getCommandCenterUrl(): string {
    return this.routeResolverService.generateRatRoute(
      AppId.CloudManager,
      '/actions'
    );
  }

  getRunCommandUrl(): string {
    return this.routeResolverService.generateRatRoute(
      AppId.CloudManager,
      '/console'
    );
  }

  onAddCustomersLearnMoreClicked(): void {
    window.open(this.getAddCustomerUrl(), '_blank');
  }

  onRunCommandsLearnMoreClicked(): void {
    window.open(this.getRunCommandUrl(), '_blank');
  }
}
