import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { CompleteMemberProfileFormComponent } from './complete-member-profile-form/complete-member-profile-form.component';
import { PORTAL_ROUTES } from './portal/portal-routing.module';
import { NavigationArea } from '@skykick/core';
import { CommunicationsComponent } from './communications/communications.component';

import { DeveloperComponent } from './developer/developer.component';
import { BuiltWithLoveComponent } from './built-with-love/built-with-love.component';
import { LogoutComponent } from './logout/logout.component';
import { MpnRegistrationComponent } from './mpn-registration/component/mpn-registration.component';
import { AuthGuard } from './utilities/AuthGuard';

const routes: Routes = [
  {
    path: '',
    children: [...PORTAL_ROUTES],
  },
  {
    path: 'complete',
    component: CompleteMemberProfileFormComponent,
  },
  {
    path: 'accountsetup',
    component: MpnRegistrationComponent,
  },
  {
    path: 'partner/developer',
    component: DeveloperComponent,
    data: {
      SkNavigationArea: NavigationArea.Developer,
    },
  },
  {
    path: 'partner/communications',
    component: CommunicationsComponent,
    canActivate: [AuthGuard],
    data: {
      SkNavigationArea: NavigationArea.Communications,
    },
  },
  {
    path: 'platform/builtwithlove',
    component: BuiltWithLoveComponent,
  },
  {
    path: 'logout',
    component: LogoutComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
