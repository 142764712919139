<div class="card mt-200">
    <div class="card-header sk-card-header d-flex justify-content-between">
        <h4>SkyKick Platform Branding</h4>
    </div>
    <div class="card-block">
        <p>Customize your SkyKick Platform with your brand.</p>
        <div class="row">
            <div class="col-7">
                <div class="form-group">
                    <label translate="settings.account.UPLOAD_NEW_COMPANY_LOGO"></label>
                    <div class="custom-file">
                        <input #fileInput type="file" class="custom-file-input" id="selectedFile"
                            accept="image/x-png,image/gif,image/jpeg, image/jpg"
                            (change)="onChange(fileInput.files[0])" />
                        <label class="custom-file-label" for="selectedFile">
                            <div class="custom-file-name text-truncate">{{ file?.name ||  ('settings.account.CHOOSE_A_FILE' | translate) }}</div>
                        </label>
                    </div>
                    <small id="logoHelp" class="sk-form-text text-muted">JPG, GIF or PNG type files only. <br/>Maximum width 180px. Maximum height 44px.</small>
                </div>
                <p><ngb-progressbar type="success" textType="white" [value]="progress" [showValue]="true"></ngb-progressbar></p>
            </div>
            <div class="col-5">
                <div class="form-group">
                    <label>Preview</label>
                    <div class="sk-partner-brand">
                        <img *ngIf="!cardImageBase64" class="partner-logo" src="/assets/images/180x44.gif" />
                        <img *ngIf="cardImageBase64" class="partner-logo" [src]="cardImageBase64" />
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-7">
                <div *ngIf="imageError" class="form-group has-danger">
                    <small class="form-control-feedback">{{imageError}}</small>
                </div>
                
                <button type="button" id="uploadImageBtn" class="btn btn-primary mr-100" (click)="onUpload()" [disabled]="!file?.name || isUploading">Upload</button>
                <button *ngIf="cardImageBase64" type="button" id="removeImageBtn" class="btn btn-outline-secondary" (click)="removeImage()" [disabled]="isUploading">Cancel</button>
            </div>
        </div>
    </div>
</div>