import {
  Component,
  Input,
  OnInit,
} from '@angular/core';
import {
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';

import { CookieService } from 'ngx-cookie-service';

import { TranslateService } from '@ngx-translate/core';

import {
  PasswordValidatorService,
} from '../shared/services/password-validator.service';
import { PartnerInfoModel } from './models/PartnerInfoSave';
import { UserProfileService } from './services/user-profile.service';

@Component({
  selector: 'sk-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss']
})
export class UserProfileComponent implements OnInit {

  @Input()
  showOutline: boolean;
  status
  toggleKeys: Boolean[] = [];
  readonly keyMask: string  = "XXXXXXXXXXXXXXXXXXXXXXXXXXX";

  languageOptions = [];
  chosenLanguage: string;

  validationPatter: string = "*";
  savingPersonalInfo: boolean = false;
  savingPassword: boolean = false;

  personalInfoForm: FormGroup = new FormGroup(
    {
      firstNameInput: new FormControl('', Validators.required),
      lastNameInput: new FormControl('', Validators.required),
      emailInput: new FormControl('', Validators.required),
      phoneInput: new FormControl('', Validators.required),
      cellPhoneInput: new FormControl('', Validators.required),
      usernameInput: new FormControl('', Validators.required),
    }
  );
  changePasswordForm: FormGroup = new FormGroup(
    {
      oldPassword: new FormControl('', Validators.required),
      newPassword: new FormControl('', [
        Validators.required,
        Validators.minLength(8),
        Validators.maxLength(64),
        this.passwordValidatorService.passwordPatternsMatch()
      ]),
    }
  );

  partnerInfoModel: PartnerInfoModel = new PartnerInfoModel();

  showOldPassword: Boolean;
  showNewPassword: Boolean;

  constructor(
    private userProfileService: UserProfileService,
    private cookieService: CookieService,
    private translateService: TranslateService,
    private passwordValidatorService: PasswordValidatorService) {



    }

  ngOnInit(): void {
    this.savingPersonalInfo = true;
    this.userProfileService.getInfo()
      .subscribe( partnerInfo => this.updatePersonalInfoForm(partnerInfo.result) )
      .add(_ => this.savingPersonalInfo = false);

      this.languageOptions = [
        {
          key: "English",
          value: "en"
        },
        {
          key: "German",
          value: "de"
        },
        {
          key: "Japanese",
          value: "ja"
        }
      ];

      var languageCode: string = this.cookieService.get('skLanguageCode');
      var languageChoice = this.languageOptions.find((o) => { return o.value === languageCode; });
      if(languageChoice === undefined)
      {
        this.chosenLanguage = this.translateService.instant('LANGUAGES_TRANSLATED.ENGLISH');
      }
      else
      {
        this.chosenLanguage = languageChoice.key;
      }
  }

  updatePersonalInfoForm(model: PartnerInfoModel): void {

    this.partnerInfoModel = model;

    this.personalInfoForm.patchValue({
      firstNameInput:  this.partnerInfoModel.FirstName,
      lastNameInput: this.partnerInfoModel.LastName,
      emailInput: this.partnerInfoModel.Email,
      phoneInput:  this.partnerInfoModel.Phone,
      cellPhoneInput: this.partnerInfoModel.Cell,
      usernameInput: this.partnerInfoModel.Username,
    });

    if(this.toggleKeys == null)
    {

      if(model.SubscriptionList != null)
      {
        // two boolean flags per subscription,
        // one for primary key, one for secondary key
        let iMax = model.SubscriptionList.length * 2;
        for (let i = 0; i < iMax; i++) {
          this.toggleKeys.push(false);
        }
      }
    }

  }

  onCancel() {
    this.updatePersonalInfoForm(this.partnerInfoModel)
    this.personalInfoForm.markAsPristine();
  }

  onSubmitPersonalInfoForm() {
    try
    {

      this.savingPersonalInfo = true;

      this.partnerInfoModel.FirstName = this.personalInfoForm.value.firstNameInput
      this.partnerInfoModel.LastName = this.personalInfoForm.value.lastNameInput
      this.partnerInfoModel.Email = this.personalInfoForm.value.emailInput
      this.partnerInfoModel.Phone = this.personalInfoForm.value.phoneInput
      this.partnerInfoModel.Cell = this.personalInfoForm.value.cellPhoneInput
      this.partnerInfoModel.Username = this.personalInfoForm.value.usernameInput

      this.userProfileService.saveUserInfo(this.partnerInfoModel)
        .subscribe( _ => console.log(this.partnerInfoModel) )
        .add( _ => {
            this.savingPersonalInfo = false;
            this.personalInfoForm.markAsPristine();
          }
        );

    } catch (ex) {

      this.savingPersonalInfo = false;
      console.log(ex)

    }
  }

  onChangeLanguage(selectedLanguage: string) {
    this.cookieService.set('skLanguageCode',selectedLanguage, 9999, "/", ".skykick.com", false, "Lax");
    window.location.reload();
  }

  hasPasswordPatternErrors(): boolean {
    return this.passwordValidatorService.hasPasswordPatternErrors();
  }

  isPasswordRuleValid(ruleName: string): boolean {
    return this.passwordValidatorService.isPasswordRuleValid(this.changePasswordForm.controls.newPassword, ruleName);
  }

  onSubmitSavePassword() {
    try {

      this.savingPassword = true;
      this.userProfileService.changePassword(this.changePasswordForm.value)
          .subscribe(
            x => console.log(x)
          )
          .add( _ => this.savingPassword = false);

    }
    catch (ex) { console.log(ex) }
  }
}
