<div class="modal-body">
    <h3>Cloud Backup</h3>
    <p>Map all SkyKick Cloud Backup products to the ConnectWise account name for them. If you have not already
        created SkyKick products in ConnectWise, you will need to do so before enabling mapping.</p>

    <div *ngIf="!isLoadingProductMappings">
        <form [formGroup]="productMappingForm" novalidate>
            <ng-container formArrayName="products">
                <table *ngIf="!isLoadingProductMappings" class="table sk-standard-wrap-table">
                    <thead class="thead-white thead-sm thead-border-none">
                        <tr>
                            <th>Product Name</th>
                            <th>ConnectWise Service Name</th>
                            <th class="text-center">Mapping</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of skykickProducts; let index = index">
                            <ng-container [formGroupName]="index">
                                <td>{{item.name}}</td>
                                <td>
                                    <div ngbDropdown class="btn-group width-fill">
                                        <button ngbDropdownToggle 
                                            type="button" 
                                            id="productSelectBtn{{index}}" 
                                            class="productSelectBtn btn btn-outline dropdown-toggle sk-has-spinner sk-abs-spinner" 
                                            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                                            [disabled]="isProductMapped(index)"
                                            (click)="clearSearch($event, index)">
                                                <div class="sk-btn-spinner">
                                                    <div class="sk-spin-loader loader-dark"></div>
                                                </div>
                                                <span class="sk-loader-label" translate>{{ getSelectedProduct(index) ?? 'Select Service Name' }}</span>
                                        </button>
                                        <div *ngIf="item.active" class="sk-spin-loader loader-sm spin-component-saving"></div>
                                        <div ngbDropdownMenu class="dropdown-menu sk-menu-input --sk-form-menu width-fill">
                                            <div class="sk-input" style="max-width: none">
                                                <input (keyup)="searchProducts($event)" id="searchProductsInput{{index}}" class="form-control" type="text" placeholder="Search">
                                                <i *ngIf="searchingProducts" class="sk-spin-loader loader-sm material-icons spin-component-search"></i>
                                            </div>
                                            <div class="dropdown-divider"></div>
                                            <div id="skScrollContainer" class="sk-menu-scroll">
                                                <a *ngFor="let product of getAvailableConnectWiseProducts(item.destination) | async" 
                                                    (click)="selectProduct(product, index)" 
                                                    ngbDropdownItem class="dropdown-item">
                                                    {{ product }}
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </td>
                                <td class="text-center">
                                    <i *ngIf="item.productMappingId" class="material-icons md-22 icon-success">check_circle</i>
                                    <span *ngIf="!item.productMappingId" class="badge badge-default">Not mapped</span>
                                </td>
                                <td class="text-center">
                                    <button (click)="removeMapping(item, index)" *ngIf="item.productMappingId"
                                        class="btn btn-outline-none sk-has-spinner sk-abs-spinner sk-btn-icon"
                                        [class.active]="item.active" [disabled]="item.active">
                                        <div class="sk-btn-spinner">
                                            <div class="sk-spin-loader loader-dark"></div>
                                        </div><span class="sk-loader-label"><i class="material-icons md-22">delete</i></span>
                                    </button>
                                </td>
                            </ng-container>
                        </tr>
                    </tbody>
                </table>

                <div *ngIf="productMappingForm.hasError('atLeastOneRequired')" class="mt-3">
                    <div class="form-group has-danger">
                        <small class="form-control-feedback">Please map at least one product.</small>
                    </div>
                </div>
                <div *ngIf="hasNotUniqueDestination" class="mt-3">
                  <div class="form-group has-danger">
                      <small class="form-control-feedback">ConnectWise Service Name has already been mapped.</small>
                  </div>
                </div>

            </ng-container>
        </form>
    </div>
    <sk-text-spinner *ngIf="isLoadingProductMappings" message="Loading product mappings"></sk-text-spinner>
</div>

<div class="modal-footer justify-content-start">
    <button *ngIf="settingType === 'wizard'" id="submitBtn" (click)="submit()" [disabled]="isAtLeastOneMapping()" [class.active]="productMappingForm.disabled"
        class="btn btn-primary sk-has-spinner sk-abs-spinner">
        <div class="sk-btn-spinner">
            <div class="sk-spin-loader loader-dark"></div>
        </div>
        <span class="sk-loader-label">Save & continue</span>
    </button>
    <button id="cancelBtn" type="button" class="btn btn-secondary" [class.disabled]="productMappingForm.disabled" (click)="activeModal.dismiss()">Cancel</button>
</div>
