<div class="sk-page header-light py-300">
    <div class="row ">
        <div class="col-md-10">
            <h2><i class="material-icons md-22 ng-binding ng-scope">dns</i> DNS Manager</h2>
        </div>
        <div class="col-md-">
            <a class="pull-xs-right btn btn-link sk-btn-combo" href="https://support.skykick.com/hc/en-us/articles/115005325008-DNS-Manager">
                <i class="material-icons md-22 md-blue">help_outline</i> <span>Get Help</span>
            </a>
        </div>
    </div>
    <div class="sk-page-block">
        <div class="py-50">
            <h3 class="sk-title-block">Partner Managed DNS Zones</h3>
        </div>
        <div class="row">
            <div class="col-md-4">
                <div ngbDropdown class="d-inline-block">
                    <button type="button" class="btn btn-outline-secondary" id="dropdownBasic1" ngbDropdownToggle>
                        {{selectedOrderDomain}} 
                        <div *ngIf="gettingOrders" id="spinnerInput" style="margin-left:10px" class="sk-spin-loader loader-sm"></div>
                    </button>
                    <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                    <button (click)="getRecordsForOrder(order)" *ngFor="let order of orders;" ngbDropdownItem>{{order.domain}}</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="orderRecords">
        <div class="sk-page-block">
            <div class="sk-table-title pull-xs-left mb-4">
                <h3>A Records</h3>
            </div>
            <div class="sk-table-cap m-b-1 clearfix">
                <button type="button" data-bs-toggle="modal" (click)="open(content, RecordType.A, false)" title="Add A Record" class="btn btn-secondary sk-btn-icon mb-4">
                    <i class="material-icons md-22">add</i>
                </button>
            </div>
            <table class="table">
                <thead>
                    <tr>
                      <th scope="col">Type</th>
                      <th scope="col">Name</th>
                      <th scope="col">IP Address</th>
                      <th scope="col">TTL</th>
                      <th scope="col"></th>
                    </tr>
                </thead>
                <tbody>
                    <ng-container *ngIf="orderRecords.aRecords.length < 1 ">
                        <tr><td class="no-results" colspan="6">No Results Found</td></tr>
                    </ng-container>
                    <ng-container *ngIf="orderRecords.aRecords.length > 0">
                        <tr *ngFor="let record of orderRecords.aRecords">
                            <td>{{(RecordType[record.recordType])}}</td>
                            <td><a class="link-primary" role="button" (click)="open(content, RecordType.A, true, record)">{{record.name}}</a></td>
                            <td>{{record.value}}</td>
                            <td>{{record.timeToLive}}</td>
                            <td>
                                <button (click)="open(content, RecordType.A, true, record)" class="btn sk-btn-icon-basic pull-xs-right"><i class="material-icons md-22">edit</i></button>
                                <button (click)="destructiveModal(record, RecordType.A)" class="btn sk-btn-icon-basic pull-xs-right"><i class="material-icons md-22">delete</i></button>
                            </td>
                        </tr>
                    </ng-container>
                </tbody>
            </table>
        </div>
        <div class="sk-page-block">
            <div class="sk-table-title pull-xs-left mb-4">
                <h3>AAAA Records (IPv6 Addresses)</h3>
            </div>
            <button type="button" data-bs-toggle="modal" (click)="open(content, RecordType.AAAA, false)" title="Add A Record" class="btn btn-secondary sk-btn-icon mb-4">
                <i class="material-icons md-22">add</i>
            </button>
            <table class="table">
                <thead>
                    <tr>
                      <th scope="col">Type</th>
                      <th scope="col">Name</th>
                      <th scope="col">IPv6 Address</th>
                      <th scope="col">TTL</th>
                      <th scope="col"></th>
                    </tr>
                </thead>
                <tbody>
                    <ng-container *ngIf="orderRecords.aaaaRecords.length < 1 ">
                        <tr><td class="no-results" colspan="6">No Results Found</td></tr>
                    </ng-container>
                    <ng-container *ngIf="orderRecords.aaaaRecords.length > 0 ">
                        <tr *ngFor="let record of orderRecords.aaaaRecords">
                            <td>{{(RecordType[record.recordType])}}</td>
                            <td><a class="link-primary" role="button" (click)="open(content, RecordType.AAAA, true, record)">{{record.name}}</a></td>
                            <td>{{record.iPv6Address}}</td>
                            <td>{{record.timeToLive}}</td>
                            <td>
                                <button (click)="open(content, RecordType.AAAA, true, record)" class="btn sk-btn-icon-basic pull-xs-right"><i class="material-icons md-22">edit</i></button>
                                <button (click)="destructiveModal(record, RecordType.AAAA)" class="btn sk-btn-icon-basic pull-xs-right"><i class="material-icons md-22">delete</i></button>
                            </td>
                        </tr>
                    </ng-container>
                </tbody>
            </table>
        </div>
        <div class="sk-page-block">
            <div class="sk-table-title pull-xs-left mb-4">
                <h3>MX Records</h3>
            </div>
            <button type="button" data-bs-toggle="modal" (click)="open(content, RecordType.MX, false)" title="Add A Record" class="btn btn-secondary sk-btn-icon mb-4">
                <i class="material-icons md-22">add</i>
            </button>
            <table class="table">
                <thead>
                    <tr>
                      <th scope="col">Type</th>
                      <th scope="col">Name</th>
                      <th scope="col">Domain</th>
                      <th scope="col">Priority</th>
                      <th scope="col">TTL</th>
                      <th scope="col"></th>
                    </tr>
                </thead>
                <tbody>
                    <ng-container *ngIf="orderRecords.mxRecords.length < 1 ">
                        <tr><td class="no-results" colspan="7">No Results Found</td></tr>
                    </ng-container>
                    <ng-container *ngIf="orderRecords.mxRecords.length > 0 ">
                        <tr *ngFor="let record of orderRecords.mxRecords">
                            <td>{{(RecordType[record.recordType])}}</td>
                            <td><a class="link-primary" role="button" (click)="open(content, RecordType.MX, true, record)">{{record.name}}</a></td>
                            <td>{{record.domainName}}</td>
                            <td>{{record.priority}}</td>
                            <td>{{record.timeToLive}}</td>
                            <td>
                                <button (click)="open(content, RecordType.MX, true, record)" class="btn sk-btn-icon-basic pull-xs-right"><i class="material-icons md-22">edit</i></button>
                                <button (click)="destructiveModal(record, RecordType.MX)" class="btn sk-btn-icon-basic pull-xs-right"><i class="material-icons md-22">delete</i></button>
                            </td>
                        </tr>
                    </ng-container>
                </tbody>
            </table>
        </div>
        <div class="sk-page-block">
            <div class="sk-table-title pull-xs-left mb-4">
                <h3>CNAME Records</h3>
            </div>
            <button type="button" data-bs-toggle="modal" (click)="open(content, RecordType.CNAME, false)" title="Add A Record" class="btn btn-secondary sk-btn-icon mb-4">
                <i class="material-icons md-22">add</i>
            </button>
            <table class="table">
                <thead>
                    <tr>
                      <th scope="col">Type</th>
                      <th scope="col">Name</th>
                      <th scope="col">Domain</th>
                      <th scope="col">TTL</th>
                      <th scope="col"></th>
                    </tr>
                </thead>
                <tbody>
                    <ng-container *ngIf="orderRecords.cNameRecords.length < 1 ">
                        <tr><td class="no-results" colspan="6">No Results Found</td></tr>
                    </ng-container>
                    <ng-container *ngIf="orderRecords.cNameRecords.length > 0 ">
                        <tr *ngFor="let record of orderRecords.cNameRecords">
                            <td>{{(RecordType[record.recordType])}}</td>
                            <td><a class="link-primary" role="button" (click)="open(content, RecordType.CNAME, true, record)">{{record.name}}</a></td>
                            <td>{{record.domainName}}</td>
                            <td>{{record.timeToLive}}</td>
                            <td>
                                <button (click)="open(content, RecordType.CNAME, true, record)" class="btn sk-btn-icon-basic pull-xs-right"><i class="material-icons md-22">edit</i></button>
                                <button (click)="destructiveModal(record, RecordType.CNAME)" class="btn sk-btn-icon-basic pull-xs-right"><i class="material-icons md-22">delete</i></button>
                            </td>
                        </tr>
                    </ng-container>
                </tbody>
            </table>
        </div>
        <div class="sk-page-block">
            <div class="sk-table-title pull-xs-left mb-4">
                <h3>SRV Records</h3>
            </div>
            <button type="button" data-bs-toggle="modal" (click)="open(content, RecordType.SRV, false)" title="Add A Record" class="btn btn-secondary sk-btn-icon mb-4">
                <i class="material-icons md-22">add</i>
            </button>
            <table class="table">
                <thead>
                    <tr>
                      <th scope="col">Type</th>
                      <th scope="col">Name</th>
                      <th scope="col">Priority</th>
                      <th scope="col">Weight</th>
                      <th scope="col">Port</th>
                      <th scope="col">Domain</th>
                      <th scope="col">TTL</th>
                      <th scope="col"></th>
                    </tr>
                </thead>
                <tbody>
                    <ng-container *ngIf="orderRecords.srvRecords.length < 1 ">
                        <tr><td class="no-results" colspan="9">No Results Found</td></tr>
                    </ng-container>
                    <ng-container *ngIf="orderRecords.srvRecords.length > 0 ">
                        <tr *ngFor="let record of orderRecords.srvRecords">
                            <td>{{(RecordType[record.recordType])}}</td>
                            <td><a class="link-primary" role="button" (click)="open(content, RecordType.SRV, true, record)">{{record.name}}</a></td>
                            <td>{{record.priority}}</td>
                            <td>{{record.weight}}</td>
                            <td>{{record.port}}</td>
                            <td>{{record.domainName}}</td>
                            <td>{{record.timeToLive}}</td>
                            <td>
                                <button (click)="open(content, RecordType.SRV, true, record)" class="btn sk-btn-icon-basic pull-xs-right"><i class="material-icons md-22">edit</i></button>
                                <button (click)="destructiveModal(record, RecordType.SRV)" class="btn sk-btn-icon-basic pull-xs-right"><i class="material-icons md-22">delete</i></button>
                            </td>
                        </tr>
                    </ng-container>
                </tbody>
            </table>
        </div>
        <div class="sk-page-block">
            <div class="sk-table-title pull-xs-left mb-4">
                <h3>TXT Records</h3>
            </div>
            <button type="button" data-bs-toggle="modal" (click)="open(content, RecordType.TXT, false)" title="Add A Record" class="btn btn-secondary sk-btn-icon mb-4">
                <i class="material-icons md-22">add</i>
            </button>
            <table class="table">
                <thead>
                    <tr>
                      <th scope="col">Type</th>
                      <th scope="col">Name</th>
                      <th scope="col">Value</th>
                      <th scope="col">TTL</th>
                      <th scope="col"></th>
                    </tr>
                </thead>
                <tbody>
                    <ng-container *ngIf="orderRecords.txtRecords.length < 1 ">
                        <tr><td class="no-results" colspan="6">No Results Found</td></tr>
                    </ng-container>
                    <ng-container *ngIf="orderRecords.txtRecords.length > 0 ">
                        <tr *ngFor="let record of orderRecords.txtRecords">
                            <td>{{(RecordType[record.recordType])}}</td>
                            <td><a class="link-primary" role="button" (click)="open(content, RecordType.TXT, true, record)">{{record.name}}</a></td>
                            <td>{{record.value}}</td>
                            <td>{{record.timeToLive}}</td>
                            <td>
                                <button (click)="open(content, RecordType.TXT, true, record)" class="btn sk-btn-icon-basic pull-xs-right"><i class="material-icons md-22">edit</i></button>
                                <button (click)="destructiveModal(record, RecordType.TXT)" class="btn sk-btn-icon-basic pull-xs-right"><i class="material-icons md-22">delete</i></button>
                            </td>
                        </tr>
                    </ng-container>
                </tbody>
            </table>
        </div>
        <div class="sk-page-block">
            <div class="sk-table-title pull-xs-left mb-4">
                <h3>PTR Records</h3>
            </div>
            <button type="button" data-bs-toggle="modal" (click)="open(content, RecordType.PTR, false)" title="Add A Record" class="btn btn-secondary sk-btn-icon mb-4">
                <i class="material-icons md-22">add</i>
            </button>
            <table class="table">
                <thead>
                    <tr>
                      <th scope="col">Type</th>
                      <th scope="col">Name</th>
                      <th scope="col">Domain</th>
                      <th scope="col">TTL</th>
                      <th scope="col"></th>
                    </tr>
                </thead>
                <tbody>
                    <ng-container *ngIf="orderRecords.ptrRecords.length < 1 ">
                        <tr><td class="no-results" colspan="6">No Results Found</td></tr>
                    </ng-container>
                    <ng-container *ngIf="orderRecords.ptrRecords.length > 0 ">
                        <tr *ngFor="let record of orderRecords.ptrRecords">
                            <td>{{(RecordType[record.recordType])}}</td>
                            <td><a class="link-primary" role="button" (click)="open(content, RecordType.PTR, true, record)">{{record.name}}</a></td>
                            <td>{{record.domainName}}</td>
                            <td>{{record.timeToLive}}</td>
                            <td>
                                <button (click)="open(content, RecordType.PTR, true, record)" class="btn sk-btn-icon-basic pull-xs-right"><i class="material-icons md-22">edit</i></button>
                                <button (click)="destructiveModal(record, RecordType.PTR)" class="btn sk-btn-icon-basic pull-xs-right"><i class="material-icons md-22">delete</i></button>
                            </td>
                        </tr>
                    </ng-container>
                </tbody>
            </table>
        </div>
        <div class="sk-page-block">
            <div class="sk-table-title pull-xs-left mb-4">
                <h3>SOA Records</h3>
            </div>
            <table class="table">
                <thead>
                    <tr>
                      <th scope="col">Type</th>
                      <th scope="col">Name</th>
                      <th scope="col">Authority Domain</th>
                      <th scope="col">Admin Domain</th>
                      <th scope="col">Serial #</th>
                      <th scope="col">Refresh</th>
                      <th scope="col">Retry</th>
                      <th scope="col">Expire</th>
                      <th scope="col">Min. TTL</th>
                      <th scope="col">TTL</th>
                    </tr>
                </thead>
                <tbody>
                    <ng-container *ngIf="orderRecords.soaRecords.length < 1 ">
                        <tr><td class="no-results" colspan="10">No Results Found</td></tr>
                    </ng-container>
                    <ng-container *ngIf="orderRecords.soaRecords.length > 0 ">
                        <tr *ngFor="let record of orderRecords.soaRecords">
                            <td>{{(RecordType[record.recordType])}}</td>
                            <td>{{record.name}}</td>
                            <td>{{record.primaryAuthorityDomainName}}</td>
                            <td>{{record.zoneAdministratorDomainName}}</td>
                            <td>{{record.zoneSerialNumber}}</td>
                            <td>{{record.refreshTime}}</td>
                            <td>{{record.retryTime}}</td>
                            <td>{{record.expireTime}}</td>
                            <td>{{record.minimumTimeToLive}}</td>
                            <td>{{record.timeToLive}}</td>
                        </tr>
                    </ng-container>
                </tbody>
            </table>
        </div>
        <div class="sk-page-block">
            <div class="sk-table-title pull-xs-left mb-4">
                <h3>NS Records</h3>
            </div>
            <table class="table">
                <thead>
                    <tr>
                      <th scope="col">Type</th>
                      <th scope="col">Name</th>
                      <th scope="col">Domain</th>
                      <th scope="col">TTL</th>
                    </tr>
                </thead>
                <tbody>
                    <ng-container *ngIf="orderRecords.nsRecords.length < 1 ">
                        <tr><td class="no-results" colspan="4">No Results Found</td></tr>
                    </ng-container>
                    <ng-container *ngIf="orderRecords.nsRecords.length > 0 ">
                        <tr *ngFor="let domain of orderRecords.nsRecords[0].domains">
                            <td>{{(RecordType[orderRecords.nsRecords[0].recordType])}}</td>
                            <td>{{orderRecords.nsRecords[0].name}}</td>
                            <td>{{domain}}</td>
                            <td>{{orderRecords.nsRecords[0].timeToLive}}</td>
                        </tr>
                    </ng-container>
                </tbody>
            </table>
        </div>
    </div>
</div>

<!--Modal-->
<ng-template #content let-modal>
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title"><span *ngIf="!editModal" >Create</span><span *ngIf="editModal">Update</span> {{(RecordType[modalRecordType])}} Record</h4>
      <button type="button" class="close" (click)="modalDismiss()"><i class="material-icons md-22">close</i></button>
    </div>
    <div class="modal-body">
      <form [formGroup]="modalForm" (ngSubmit)="onSubmit()">
        <div class="form-group mb-3">
          <label >Record Type</label>
          <div class="input-group">
            {{(RecordType[modalRecordType])}}
          </div>
        </div>
        <div *ngIf="editModal"  class="form-group mb-3">
            <label >Record Name</label>
            <div class="input-group">
              {{modalForm.value.recordName}}
            </div>
          </div>
        <div *ngIf="!editModal" class="form-group mb-3">
            <label for="recordName">Record Name</label>
            <div class="input-group">
                <input id="name" class="form-control" type="text" formControlName="recordName">
            </div>
        </div>
        <div class="form-group mb-3" *ngIf="(modalRecordType === RecordType.A)">
            <label for="ip">IP Address</label>
            <div class="input-group">
                <input required class="form-control" id="name" type="text" formControlName="ip">
            </div>
        </div>
        <div *ngIf="(modalRecordType === RecordType.AAAA)" class="form-group mb-3">
            <label for="ipv6">IPv6 Address</label>
            <div class="input-group">
                <input class="form-control" id="name" type="text" formControlName="ipv6">
            </div>
        </div>
        <div *ngIf="(modalRecordType === RecordType.MX || modalRecordType === RecordType.SRV)" class="form-group mb-3">
            <label for="priority">Priority</label>
            <div class="input-group">
                <input class="form-control" id="name" type="number" formControlName="priority">
            </div>
        </div>
        <div class="form-group mb-3"
            *ngIf="(modalRecordType === RecordType.CNAME || modalRecordType === RecordType.PTR || modalRecordType === RecordType.MX || modalRecordType === RecordType.SRV)">
            <label for="domain">Domain</label>
            <div class="input-group form-group">
                <input class="form-control" id="name" type="text" formControlName="domain">
            </div>
        </div>
        <div *ngIf="(modalRecordType === RecordType.SRV)" class="form-group mb-3">
            <label for="weight">Weight</label>
            <div class="input-group">
                <input class="form-control" id="name" type="number" formControlName="weight">
            </div>
        </div>
        <div *ngIf="(modalRecordType === RecordType.SRV)" class="form-group mb-3">
            <label for="port">Port</label>
            <div class="form-group input-group">
                <input class="form-control" id="name" type="number" formControlName="port">
            </div>
        </div>
        <div *ngIf="(modalRecordType === RecordType.TXT)" class="mb-3 form-group">
            <label for="value">Value</label>
            <div class="input-group">
                <input class="form-control" id="name" type="text" formControlName="value">
            </div>
        </div>
        <div class="form-group mb-3">
            <label for="ttl">TTL</label>
            <div class="input-group">
                <select class="form-control" formControlName="ttl">
                    <option *ngFor="let time of ttlList" value="{{time.value}}">{{time.name}}</option>
                </select>
            </div>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-primary" type="submit">
                Save
                <div *ngIf="modalSpinner" id="spinnerInput" style="margin-left:10px;"  class="sk-spin-loader loader-sm"></div>
            </button>
          </div>
      </form>
    </div>
</ng-template>
  
