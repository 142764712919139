import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { TaskManagerService } from '@skykick/core';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TaskType } from 'src/app/settings/models/task-type';
import { ConnectwiseResourcesService } from '../../core/services/connectwise-resources.service';

@Component({
    selector: 'sk-credentials',
    templateUrl: './credentials.component.html',
    styleUrls: ['./credentials.component.scss']
})
export class CredentialsComponent implements OnInit, OnDestroy {
    private onDestroy$ = new Subject();
    @Input() isWizard: boolean;
    authenticateForm: FormGroup;
    isLoadingCredentials: boolean;
    credentials: any;
    errors: Array<string> = [];

    private validateAllFormFields(formGroup: FormGroup) {
        Object.keys(formGroup.controls).forEach(field => {
            const control = formGroup.get(field);
            if (control instanceof FormControl) {
                control.markAsDirty({ onlySelf: true });
            } else if (control instanceof FormGroup) {
                this.validateAllFormFields(control);
            }
        });
    }

    constructor(
        private formBuilder: FormBuilder,
        private connectwiseResourcesService: ConnectwiseResourcesService,
        private toastrService: ToastrService,
        private taskManagerService: TaskManagerService
    ) { }

    ngOnInit(): void {
        this.authenticateForm = this.formBuilder.group({
            companyName: ['', [Validators.required]],
            connectWiseURL: ['', [Validators.required]],
            partnerId: null,
            privateKey: ['', [Validators.required]],
            publicKey: ['', [Validators.required]]
        });
        if (this.isWizard) {
            this.authenticateForm.addControl('tandc', new FormControl(false, Validators.requiredTrue));
        }

        this.isLoadingCredentials = true;
        this.connectwiseResourcesService.getCredentials().pipe(
            takeUntil(this.onDestroy$)
        ).subscribe(res => {
            this.credentials = res;
            this.authenticateForm.patchValue(res);
            this.isLoadingCredentials = false;
        });
    }

    isFieldValid(field: string, type?: string) {
        if (type) {
            return this.authenticateForm.get(field).hasError(type) && !this.authenticateForm.get(field).pristine && !this.authenticateForm.disabled;
        }
        return !this.authenticateForm.get(field).valid && !this.authenticateForm.get(field).pristine && !this.authenticateForm.disabled;
    }

    displayFieldCss(field: string) {
        return {
            'has-danger': this.isFieldValid(field)
        };
    }

    updateCredentials() {
        this.errors.length = 0;
        if (this.authenticateForm.valid) {
            let payload = this.authenticateForm.value;
            this.authenticateForm.disable();
            this.connectwiseResourcesService.updateCredentials(payload).pipe(
                takeUntil(this.onDestroy$)
            ).subscribe(res => {
                this.toastrService.success('Update successful');
                this.authenticateForm.enable();
            }, res => {
                let error = JSON.parse(res.error);
                this.errors.push(error.Message);
                this.authenticateForm.enable();
            });
        } else {
            this.validateAllFormFields(this.authenticateForm);
        }
        this.taskManagerService.abortTask(TaskType.ConnectWiseInfo);
    }

    ngOnDestroy(): void {
        this.onDestroy$.next();
    }

}
