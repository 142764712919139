<div class="partPortalHeader bg-white">
    <div class="partPortalMainBody">
        <div class="colmask leftmenu">
            <div class="colleft">
                <div class="col1">
                    <div class="ng-scope">

                        
                        <div class="sk-page-title">
                            <h2>
                                <i class="material-icons md-30 align-middle">mail</i>
                        
                                <!-- <i _ngcontent-yvd-107="" class="material-icons md-36">email</i>  -->
                                Migration Communications
                            </h2> 
                        </div>


                            <ul id="contentSubMenu" class="nav nav-tabs sk-tabs px-5">
                                <li class="nav-item">
                                    <a (click)="tab=0"  class="nav-link tabA" [ngClass]="{'active':tab==0}">End User</a>
                                </li>
                                <li class="nav-item">
                                    <a (click)="tab=1" class="nav-link tabB" [ngClass]="{'active':tab==1}">Point of Contact</a>
                                </li>
                                <li class="nav-item">
                                    <a (click)="tab=2" class="nav-link tabC" [ngClass]="{'active':tab==2}">Partner</a>
                                </li>
                            </ul>


                        <div>
                            <div *ngIf="tab==0">
                                <sk-communications-tab [templates]="endUserTemplates"></sk-communications-tab>
                            </div>
                            <div *ngIf="tab==1">
                                <sk-communications-tab [templates]="pointOfContactTemplates"></sk-communications-tab>
                            </div>
                            <div *ngIf="tab==2">
                                <sk-communications-tab [templates]="partnerTemplates"></sk-communications-tab>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
