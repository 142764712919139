<div class="d-flex flex-row align-items-center justify-content-between min-width-sm max-width-lg my-100 px-200">
    <div class="d-flex flex-row align-items-center">
        <div class="d-flex mr-100 border rounded align-items-center justify-content-center square-56">
            <img class="img-fluid d-flex" src="/assets/svg/connectwise-icon.svg">
        </div>

        <div class="d-flex flex-column">
            <div class="d-inline-flex align-items-center">
                <h2 class="font-weight-normal">ConnectWise</h2>
                <span *ngIf="isPartnerActiveAndEnabled()" class="badge badge-success ml-100">Active</span>
                <span *ngIf="isPartnerInactive()" class="badge badge-warning ml-100">Inactive</span>
            </div>
            <small class="text-muted">Map products, tickets and companies between SkyKick and Connectwise to manage your invoices.</small>
        </div>
    </div>
    <button *ngIf="!partnerData || partnerData.prorationSetting === 0" type="button" class="add-integration btn btn-primary text-nowrap" (click)="connectionWizard()">Add Integration</button>
    <div *ngIf="partnerData && partnerData.prorationSetting !== 0" ngbDropdown class="btn-group">
        <button type="button" class="btn sk-btn-combo btn-outline dropdown-toggle" id="moreOptions" ngbDropdownToggle>
            <i class="material-icons md-22">settings</i>
            <span>Settings</span>
        </button>
        <div ngbDropdownMenu class="dropdown-menu">
            <button *ngFor="let item of WIZARDNAVS" type="button" ngbDropdownItem class="dropdown-item" id="{{item.value}}Button" (click)="connectionWizard(item.value)">{{item.name}}</button>
            <div class="dropdown-divider"></div>
            <button type="button" ngbDropdownItem class="dropdown-item" id="disableIntegration" (click)="disableIntegration()">Disable Integration</button>
        </div>
    </div>

</div>

<div class="sk-navbar-tertiary sk-navbar-panel">
    <ul class="nav sk-tabs-panel d-flex flex-nowrap text-truncate">
        <ng-container *ngFor="let item of connectWiseNavs;let i = index">
            <li *ngIf="!item.disabled" class="nav-item">
                <a id="connectWiseLink{{i}}" class="nav-link" routerLink="/settings/integrations/connectwise/{{item.value}}"
                    routerLinkActive="active">{{item.name}}</a>
            </li>
        </ng-container>
    </ul>
</div>

<router-outlet></router-outlet>