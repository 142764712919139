import { Component, ViewChild } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';

import { filter, map, mergeMap } from 'rxjs/operators';

import { HelpCenterService, PlatformNavigationComponent } from '@skykick/core';
import { AppInsightsMonitorService } from './services/app-insights-monitor.service';

@Component({
  selector: '[app-root]',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  @ViewChild(PlatformNavigationComponent)
  public skPlatformNavigation: PlatformNavigationComponent;

  routeData: any;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private helpCenter: HelpCenterService,
    private appInsightsMonitor: AppInsightsMonitorService
  ) {
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => this.rootRoute(this.route)),
        filter((route: ActivatedRoute) => route.outlet === 'primary'),
        mergeMap((route: ActivatedRoute) => route.data)
      )
      .subscribe((event: { [name: string]: any }) => {
        if (event['SkNavigationArea']) {
          this.skPlatformNavigation.SkNavigationArea =
            event['SkNavigationArea'];
        }
    });

    // Initialize the Zendesk help center embeddable.
    this.helpCenter.init();
  }

  private rootRoute(route: ActivatedRoute): ActivatedRoute {
    while (route.firstChild) {
      route = route.firstChild;
    }
    return route;
  }
}
