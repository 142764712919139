<form *ngIf="!isLoadingProrationSettings" [formGroup]="syncSettingsForm" novalidate>
    <fieldset class="form-group mt-150">
        <div class="custom-control custom-radio sk-radio">
            <input type="radio" id="proratedSyncAlways" name="prorationSetting" formControlName="prorationSetting" class="custom-control-input" value="{{PRORATIONSETTING.AlwaysPush}}" />
            <label class="custom-control-label" for="proratedSyncAlways">Always sync prorated agreements</label>
            <div class="sk-form-text">Always sync prorated agreements regardless of how they are configured in ConnectWise.</div>
        </div>
    </fieldset>
    <fieldset class="form-group">
        <div class="custom-control custom-radio sk-radio">
            <input type="radio" id="proratedSyncCustom" name="prorationSetting" formControlName="prorationSetting" class="custom-control-input" value="{{PRORATIONSETTING.CustomPush}}" />
            <label class="custom-control-label" for="proratedSyncCustom">Customize sync by customer</label>
            <div class="sk-form-text">Sync prorated data only when an agreement in ConnectWise is configured to do so.</div>
        </div>
    </fieldset>
    <fieldset class="form-group">
        <div class="custom-control custom-radio sk-radio">
            <input type="radio" id="proratedSyncNever" name="prorationSetting" formControlName="prorationSetting" class="custom-control-input" value="{{PRORATIONSETTING.NeverPush}}" />
            <label class="custom-control-label" for="proratedSyncNever">Never sync prorated agreements</label>
            <div class="sk-form-text">Description text needed here</div>
        </div>                       
    </fieldset>

    <ng-container *ngIf="errors">
        <div class="mt-3">
            <div class="form-group has-danger">
                <small class="form-control-feedback" *ngFor="let error of errors" [innerText]="error"></small>
            </div>
        </div>    
    </ng-container>

    <button id="updateProrationButton" class="btn btn-primary sk-has-spinner sk-abs-spinner mt-150" 
        [class.active]="syncSettingsForm.disabled" [disabled]="syncSettingsForm.invalid" (click)="updateProrationSettings()">
        <div class="sk-btn-spinner">
            <div class="sk-spin-loader loader-dark"></div>
        </div>
        <span class="sk-loader-label" translate>Update</span>
    </button>
</form>
<sk-text-spinner *ngIf="isLoadingProrationSettings" message="Loading product mappings"></sk-text-spinner>
