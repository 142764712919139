import { Component, OnInit } from '@angular/core';
import {
  PlatformNavigationService,
  SkyKickProductPage,
  TaskManagerService,
} from '@skykick/core';
import { ProductType } from '../../models/ProductTypeEnum';

@Component({
  selector: 'sk-account-notification-settings',
  templateUrl: './account-notification-settings.component.html',
  styleUrls: ['./account-notification-settings.component.scss'],
})
export class AccountNotificationSettingsComponent
  extends SkyKickProductPage
  implements OnInit
{

  ProductType = ProductType;

  constructor(
    private platformNavigationService: PlatformNavigationService,
    taskManagerService: TaskManagerService
  ) {
    super(taskManagerService);
  }

  ngOnInit(): void {}
}
