import { Component, HostBinding, OnDestroy, OnInit } from '@angular/core';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { BreadcrumbService } from '../shared/breadcrumbs/breadcrumb.service';
import { ConnectionWizardComponent } from './connection-wizard/connection-wizard.component';
import { ConnectwiseResourcesService } from './core/services/connectwise-resources.service';
import { catchError, takeUntil, tap } from 'rxjs/operators';
import { EMPTY, Subject } from 'rxjs';
import { ConnectwiseStateManagerService } from './core/services/connectwise-state-manager.service';
import { ActivatedRoute, Router } from '@angular/router';
import { WIZARDNAVS } from './core/constants/wizard-navs.const';
import { SkyKickModalOptions, SkyKickModalService, TaskManagerService } from '@skykick/core';
import { ToastrService } from 'ngx-toastr';
import { TaskType } from '../../models/task-type';
import { InfoPanelContext } from './info/models/info-panel-context';
import { InfoPanelComponent } from './info/info-panel.component';
import { PRORATIONSETTING } from '../connectwise/core/constants/proration-setting.const';

@Component({
    selector: '[sk-connectwise]',
    templateUrl: './connectwise.component.html',
    styleUrls: ['./connectwise.component.scss']
})
export class ConnectwiseComponent implements OnInit, OnDestroy {
    private onDestroy$ = new Subject();
    @HostBinding('class.sk-content-column') isActive = true;
    WIZARDNAVS = WIZARDNAVS;
    connectWiseNavs: any[] = [
        { name: 'Details', value: 'details', disabled: false },
        { name: 'Customers', value: 'customers', disabled: false },
        { name: 'Tickets', value: 'tickets', disabled: false }
        // { name: 'Settings', value: 'settings', disabled: false }
    ];
    partnerData = null;
    private errorOptions: SkyKickModalOptions = {
        body: `<p class="mb-200">There was a problem processing your request</p>`,
        title: 'Error',
        btnLabel: 'OK'
    };

    constructor(
        private breadcrumbService: BreadcrumbService,
        private modalService: NgbModal,
        private connectwiseResourcesService: ConnectwiseResourcesService,
        private connectwiseStateManagerService: ConnectwiseStateManagerService,
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private skyKickModalService: SkyKickModalService,
        private toastrService: ToastrService,
        private taskManagerService: TaskManagerService
    ) { }

    ngOnInit(): void {
        this.breadcrumbService.push('Connectwise', '/settings/integrations/connectwise/details');

        this.activatedRoute.data.pipe(
          takeUntil(this.onDestroy$),
          tap(res => {
            this.initializeNavigation(res.partnerData);
          })
        ).subscribe();

        this.taskManagerService.configureTask(
            TaskType.ConnectWiseInfo,
            new InfoPanelContext(this.connectionWizard.bind(this)),
            InfoPanelComponent
        );
    }

    connectionWizard(settingType: string = 'wizard') {
        const overlayConfig: NgbModalOptions = {
          backdrop: 'static',
          windowClass: 'modal-panel modal-right',
          size: settingType === 'authenticate'
            ? 'md'
            : settingType === 'wizard' ? 'xl' : 'lg'
        };
        const modalRef = this.modalService.open(ConnectionWizardComponent, overlayConfig);
        modalRef.componentInstance.settingType = settingType;
        modalRef.result.then((anyValue: any) => {
          this.partnerData = this.connectwiseStateManagerService.getConnectWisePartner();
          this.initializeNavigation(this.partnerData);
        });
    }

    disableIntegration() {
      this.connectwiseResourcesService.deletePartner().pipe(
        catchError((res) => {
          this.skyKickModalService.error(this.errorOptions);
          return EMPTY;
        }))
        .subscribe(() => {
          this.connectwiseStateManagerService.setConnectWisePartner(null);
          this.partnerData = this.connectwiseStateManagerService.getConnectWisePartner();
          this.WIZARDNAVS.forEach(item => {
            item.active = false;
            item.complete = false;
          });
          this.initializeNavigation(this.partnerData);
          this.toastrService.success('Integration disabled');
        });
    }

    initializeNavigation(partnerData: any){
      if (partnerData) {
        this.partnerData = partnerData;
        this.connectwiseStateManagerService.setConnectWisePartner(partnerData);
      }

      if (this.isPartnerActive()) {
        this.connectWiseNavs.forEach((item, index) => {
            if (index !== 0) {
                item.disabled = false;
            }
        });
      } else {
          this.connectWiseNavs.forEach((item, index) => {
              if (index !== 0) {
                  item.disabled = true;
              }
          });
          this.router.navigate(['settings', 'integrations', 'connectwise', 'details']);
      }
    }

    isPartnerActive(): boolean {
      return !!this.partnerData && this.partnerData.prorationSetting !== PRORATIONSETTING.Unset;
    }

    isPartnerInactive(): boolean {
      return !!this.partnerData && this.partnerData.prorationSetting === PRORATIONSETTING.Unset;
    }

    isPartnerActiveAndEnabled(): boolean {
      return this.isPartnerActive() && this.partnerData.enabled;
    }

    ngOnDestroy(): void {
        this.breadcrumbService.pop();
        this.onDestroy$.next();
    }

}
