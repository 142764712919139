import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { TranslateModule } from '@ngx-translate/core';
import { ButtonsModule, ModalsModule } from '@skykick/core';

import {
  ErrorBannerComponent,
} from './components/error-banner/error-banner.component';
import { LoadMoreComponent } from './components/load-more/load-more.component';
import { ErrorModalComponent } from './components/error-modal/error-modal.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ActionsMenuComponent } from './components/actions-menu/actions-menu.component';

@NgModule({
  declarations: [
    LoadMoreComponent,
    ErrorBannerComponent,
    ErrorModalComponent,
    ActionsMenuComponent
  ],
  imports: [
    CommonModule,
    TranslateModule,
    ButtonsModule,
    ModalsModule,
    NgbModule,
  ],
  exports: [
    CommonModule,
    LoadMoreComponent,
    ErrorBannerComponent,
    TranslateModule,
    ButtonsModule,
    ActionsMenuComponent,
    NgbModule
  ]
})
export class SharedModule { }
