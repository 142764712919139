import {
  Component,
  OnInit,
} from '@angular/core';
import { Router } from '@angular/router';

import {
  RelativeUrlsProvider,
} from 'src/app/settings/shared/services/relative.urls.provider';
import {
  AuthenticationType,
} from 'src/app/settings/users/models/partner-portal-user-claims';

import {
  AppId,
  RouteResolverService,
  SkyKickPanelTabViewBase,
} from '@skykick/core';

import { CustomersAccessProvider } from '../services/customers.access.provider';
import {
  CustomerDetailsPanelContext,
} from './models/customer-details-panel-context';
import CustomerDetailsViewModel from './models/customer-details-view-model';

@Component({
  /* tslint:disable-next-line:component-selector */
  selector: '[sk-customer-details-panel]',
  templateUrl: './customer-details-panel.component.html',
  styleUrls: ['./customer-details-panel.component.scss']
})
export class CustomerDetailsPanelComponent extends SkyKickPanelTabViewBase<CustomerDetailsPanelContext, void> implements OnInit {

  customer: CustomerDetailsViewModel;

  constructor(
    private router: Router,
    private routeResolverService: RouteResolverService) {
    super();
  }

  ngOnInit(): void {
    this.customer = this.data.viewModel;
  }

  getAuthTypeLocKey(): string {
    let authTypeLocKey = 'settings.common.authentication.skykick';
    if (this.isM365Authentication()){
      authTypeLocKey = 'settings.common.authentication.m365';
    }
    else if (this.customer.partnerClaims.isMFAEnabled){
      authTypeLocKey = 'settings.common.authentication.skykick-mfa';
    }
    return authTypeLocKey;
  }

  isM365Authentication(): boolean{
    return this.customer.partnerClaims.authenticationType === AuthenticationType.O365Auth;
  }

  getAccountSettingsPageUrl(): string {
    return this.routeResolverService.generateRatRoute(AppId.Portal, RelativeUrlsProvider.ManageAccountPageUrl);
  }

  getAccessLocKey(customerAccessKey: string): string {
    return CustomersAccessProvider.getAccessDisplayNameLocKeyByValue(customerAccessKey);
  }

  showFooter(): boolean {
    return this.customer.status === 'Active';
  }

  editPermissions(): void {
    this.router.navigate(['settings', 'users', 'customers', this.customer.id, 'edit']);
  }

}
