<div class="sk-flex-table">
    <div class="sk-scroll-container">
        <div class="d-flex flex-column min-width-sm max-width-lg py-200">
            <div class="row">
                <div class="col-8">
                    
                    <!-- credentials -->
                    <div class="card ">
                        <div class="card-header sk-card-header d-flex justify-content-between">
                            <h4>ConnectWise Credentials</h4>
                        </div>
                        <div class="card-block">
                            <sk-credentials isWizard="false"></sk-credentials>
                        </div>
                    </div>
                    

                    <!-- product mapping -->
                    <div class="card  mt-200">
                        <div class="card-header sk-card-header d-flex justify-content-between">
                            <h4>Product Mapping</h4>
                        </div>
                        <div class="card-block">
                            <sk-product-mapping isWizard="false"></sk-product-mapping>
                        </div>
                    </div>
                    
                    <!-- sync proration -->
                    <div class="card  mt-200">
                        <div class="card-header sk-card-header d-flex justify-content-between">
                            <h4>Proration Credentials</h4>
                        </div>
                        <div class="card-block">
                            <p>Please select a setting to start syncing SkyKick data with your ConnectWise account. <a href="">Learn more</a></p>
                            <sk-proration-settings isWizard="false"></sk-proration-settings>
                        </div>
                    </div>

                </div>

                <div class="col-4">
                </div>
            </div>
        </div>
    </div>
</div>