import {
  AbstractControl,
  AsyncValidatorFn,
  ValidationErrors,
} from '@angular/forms';

import { Observable } from 'rxjs';
import {
  catchError,
  map,
} from 'rxjs/operators';

import { MembersService } from './members.service';

export class MemberEmailValidator {
    static createValidator(membersService: MembersService): AsyncValidatorFn {
        return (control: AbstractControl): Observable<ValidationErrors> => {
            return membersService
                .memberExists(control.value)
                .pipe(
                    map((result: boolean) => result ? { memberEmailExists: true } : null),
                    catchError((error) => error)
                );
        };
    }
}
