import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

import {
  AppId,
  RouteResolverService,
} from '@skykick/core';

import { Utils } from '../../../services/utils';

@Injectable({
    providedIn: 'root'
})
export class UsersService {
    private basePath = `${this.routeResolverService.generatePipeRoute(AppId.PlatformApis)}authenticationprovider/api/user`;

    constructor(private http: HttpClient, private routeResolverService: RouteResolverService) { }

    userExists(userName: string): Observable<boolean> {
        return this.http.get<boolean>(
                `${this.basePath}/exists?userName=${encodeURIComponent(userName)}`)
            .pipe(
                catchError(error => Utils.handleError(error, UsersService.name))
            );
    }
}
