<sk-modal type="danger">
    <sk-modal-header (dismiss)="dismiss()">{{ 'settings.members.remove-member.title' | translate }}</sk-modal-header>

    <sk-modal-body>
        <p class="display-as-is">{{ 'settings.members.remove-member.description' | translate }}</p>
    </sk-modal-body>

    <sk-modal-footer>
        <button sk-button color="outline-secondary" (click)="close()">{{ 'settings.members.remove-member.cancel' | translate }}</button>
        <button type="button" class="btn btn-danger sk-has-spinner sk-abs-spinner" (click)="remove()" [class.active]="isDeleteRunning" [disabled]="isDeleteRunning">
            <div class="sk-btn-spinner">
                <div class="sk-spin-loader loader-dark"></div>
            </div>
            <span class="sk-loader-label">{{ 'settings.members.remove-member.yes' | translate }}</span>
        </button>
    </sk-modal-footer>
</sk-modal>
