<div class="d-flex flex-row align-items-center justify-content-between min-width-lg max-width-lg my-100 px-200">
    <div class="d-flex flex-row align-items-center">
        <div class="d-flex mr-100 border rounded align-items-center justify-content-center square-56">
            <img class="img-fluid d-flex" src="/assets/svg/icon-microsoft.svg">
        </div>

        <div class="d-flex flex-column">
            <div class="d-inline-flex align-items-center">
                <h2 class="font-weight-normal">Microsoft Partner Center</h2>
            </div>
            <small class="text-muted">Connect to Microsoft 365 Partner Center</small>
        </div>
    </div>
</div>

<router-outlet></router-outlet>