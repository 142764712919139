import { Component, HostBinding, OnInit } from '@angular/core';

@Component({
    selector: 'sk-settings',
    templateUrl: './settings.component.html',
    styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit {
    @HostBinding('class.sk-content-primary') isActive = true;
    
    constructor() { }

    ngOnInit(): void {
    }
    
}
