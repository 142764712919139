<form *ngIf="!isLoadingCredentials" [formGroup]="authenticateForm" novalidate>
    <fieldset class="form-group input-width-max" [ngClass]="displayFieldCss('connectWiseURL')">
        <label>ConnectWise URL</label>
        <input type="text" class="form-control" formControlName="connectWiseURL" id="connectWiseURL" />
        <small class="sk-form-text text-muted">The URL used to sign into ConnectWise Manage</small>
        <small *ngIf="isFieldValid('connectWiseURL', 'required')" class="form-control-feedback">Required field</small>
    </fieldset>
    <fieldset class="form-group input-width-max" [ngClass]="displayFieldCss('companyName')">
        <label>ConnectWise Company Name</label>
        <input type="text" class="form-control" formControlName="companyName" id="companyName" />
        <small class="sk-form-text text-muted">The Company ID used to sign into ConnectWise Manage</small>
        <small *ngIf="isFieldValid('companyName', 'required')" class="form-control-feedback">Required field</small>
    </fieldset>
    <fieldset class="form-group input-width-max" [ngClass]="displayFieldCss('publicKey')">
        <label>Public Key</label>
        <input type="password" class="form-control" formControlName="publicKey" id="publicKey" />
        <small *ngIf="isFieldValid('publicKey', 'required')" class="form-control-feedback">Required field</small>
    </fieldset>
    <fieldset class="form-group input-width-max" [ngClass]="displayFieldCss('privateKey')">
        <label>Private Key</label>
        <input type="password" class="form-control" formControlName="privateKey" id="privateKey" />
        <small *ngIf="isFieldValid('privateKey', 'required')" class="form-control-feedback">Required field</small>
    </fieldset>
    <ng-container *ngIf="errors">
        <div class="mt-3">
            <div class="form-group has-danger">
                <small class="form-control-feedback" *ngFor="let error of errors" [innerText]="error"></small>
            </div>
        </div>    
    </ng-container>
            
    <button *ngIf="!isWizard" id="validateButton" class="btn btn-primary sk-has-spinner sk-abs-spinner mt-150" 
        [ngClass]="{'active disabled': authenticateForm.disabled}" [disabled]="authenticateForm.disabled" (click)="updateCredentials()">
        <div class="sk-btn-spinner">
            <div class="sk-spin-loader loader-dark"></div>
        </div>
        <span class="sk-loader-label" translate>Update</span>
    </button>

    <ng-container *ngIf="isWizard">
        <div class="custom-control custom-checkbox sk-checkbox d-flex mt-200">
            <input type="checkbox" id="tandc" class="custom-control-input" formControlName="tandc" />
            <label for="tandc" class="custom-control-label">By clicking on this box, I accept
                <div class="btn-group">
                    <button type="button" class="btn btn-basic dropdown-toggle"
                        (click)="$event.stopPropagation();showTandC = !showTandC">Terms & Conditions</button>
                </div>
            </label>
        </div>
        <div *ngIf="showTandC" class="pl-200 mt-25">
            This configuration will allow you to create a ConnectWise integration with your SkyKick account and
            transmit certain payment information and related data about your SkyKick account
            and your customers' accounts (collectively "Data") to ConnectWise.
        </div>

        <button id="validateButton" class="btn btn-outline-primary sk-has-spinner sk-abs-spinner mt-150"
            [ngClass]="{'active disabled': authenticateForm.disabled}"
            [disabled]="authenticateForm.invalid">
            <div class="sk-btn-spinner">
                <div class="sk-spin-loader loader-dark"></div>
            </div>
            <span class="sk-loader-label" translate>Validate</span>
        </button>
    </ng-container>
</form>
<sk-text-spinner *ngIf="isLoadingCredentials" message="Loading credentials"></sk-text-spinner>
