import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AbstractUserProvider } from '@skykick/platform-identity-auth-auth0-angular';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Utils } from 'src/app/services/utils';
import { environment } from 'src/environments/environment';
import { O365SetupLoginInfo } from '../models/o365-setup-login-info';
import { PartnerAuthentication } from '../models/partner-authentication';

import {
    AppId,
    RouteResolverService,
} from '@skykick/core';

@Injectable({
    providedIn: 'root'
})
export class AuthenticationMethodResourcesService {

    constructor(
        private http: HttpClient,
        private abstractUserProvider: AbstractUserProvider,
        private routeResolverService: RouteResolverService
    ) { }

    // Authentication
    getPartnerAuthenticationSettings(): Observable<PartnerAuthentication> {
        const url = `${this.getPAPIRoute()}partner/admin/${this.abstractUserProvider.getCurrentUser().partnerId}/authenticationsettings`;
        return this.http.get<PartnerAuthentication>(url)
            .pipe(
                catchError(error => Utils.handleError(error, 'getPartnerAuthenticationSettings()'))
            );
    }

    getGroupSyncStatus(): Observable<any> {
        const url = `${this.getPAPIRoute()}partner/admin/${this.abstractUserProvider.getCurrentUser().partnerId}/sync/groups/status`;
        return this.http.get<PartnerAuthentication>(url)
            .pipe(
                catchError(error => Utils.handleError(error, 'getGroupSyncStatus()'))
            );
    }

    getM365AuthConnectionStatus(): Observable<any> {
        const url = `${this.getPAPIRoute()}partner/admin/o365login/${this.abstractUserProvider.getCurrentUser().partnerId}/connection-status`;
        return this.http.get<PartnerAuthentication>(url)
            .pipe(
                catchError(error => Utils.handleError(error, 'getM365AuthConnectionStatus()'))
            );
    }

    getM365OAuthUserLink(payload: any): Promise<Observable<any>> {
        const url = `${this.getPAPIRoute()}partner/admin/o365login/${this.abstractUserProvider.getCurrentUser().partnerId}/oauthurl/userauthentication`;
        return this.http.post<any>(url, payload)
            .pipe(
                catchError(error => Utils.handleError(error, 'getM365OAuthUserLink()'))
            ).toPromise();
    }

    getM365OAuthGroupLink(payload: any): Promise<Observable<any>> {
        const url = `${this.getPAPIRoute()}partner/admin/o365login/${this.abstractUserProvider.getCurrentUser().partnerId}/oauthurl/groupauthentication`;
        return this.http.post<any>(url, payload)
            .pipe(
                catchError(error => Utils.handleError(error, 'getM365OAuthGroupLink()'))
            ).toPromise();
    }

    savePartnerAuthenticationSettings(payload: any): Promise<Observable<any>> {
        const url = `${this.getPAPIRoute()}partner/admin/${this.abstractUserProvider.getCurrentUser().partnerId}/authenticationsettings`;
        return this.http.post<any>(url, payload)
            .pipe(
                catchError(error => Utils.handleError(error, 'savePartnerAuthenticationSettings()'))
            ).toPromise();
    }

    saveOAuthFlowState(payload: any): Promise<Observable<any>> {
        const url = `${this.getPAPIRoute()}partner/admin/${this.abstractUserProvider.getCurrentUser().partnerId}/oauthflowstate`;
        return this.http.post<any>(url, payload)
            .pipe(
                catchError(error => Utils.handleError(error, 'saveOAuthFlowState()'))
            ).toPromise();
    }

    getO365SetupLoginInfo(): Promise<O365SetupLoginInfo> {
        const url = `${this.getPAPIRoute()}partner/admin/o365login/${this.abstractUserProvider.getCurrentUser().partnerId}/review`;
        return this.http.get<O365SetupLoginInfo>(url)
            .pipe(
                catchError(error => Utils.handleError(error, 'getO365SetupLoginInfo()'))
            ).toPromise();
    }

    beginM365GroupSync() {
        const url = `${this.getPAPIRoute()}partner/admin/${this.abstractUserProvider.getCurrentUser().partnerId}/sync/groups`;
        return this.http.post<any>(url, null).pipe(
            catchError(error => Utils.handleError(error, 'beginM365GroupSync()'))
        ).toPromise();
    }

    disableM365GroupSync(): Promise<any> {
        const url = `${this.getPAPIRoute()}partner/admin/${this.abstractUserProvider.getCurrentUser().partnerId}/sync/groups`;
        return this.http.delete(url).pipe(
            catchError(error => Utils.handleError(error, 'disableM365GroupSync()'))
        ).toPromise();
    }

    hasValidConnection(): Observable<any> {
        const url = `${this.getPAPIRoute()}partner/admin/${this.abstractUserProvider.getCurrentUser().partnerId}/authentication/connection`;
        return this.http.get<any>(url)
            .pipe(
                catchError(error => Utils.handleError(error, 'hasValidConnection()'))
            );
    }

    searchO365LoginUserNames(searchTerm: string) {
        return this.http.get<any>(`${this.getPAPIRoute()}partner/admin/o365login/${this.abstractUserProvider.getCurrentUser().partnerId}/search?searchTerm=${searchTerm}`)
            .pipe(
                catchError(error => Utils.handleError(error, 'searchO365LoginUserNames()'))
            );
    }

    private getPAPIRoute(): string {
        return this.routeResolverService.generatePipeRoute(AppId.Papi);
    }
}
