import { animate, style, transition, trigger } from '@angular/animations';
import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AbstractUserProvider } from '@skykick/platform-identity-auth-auth0-angular';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Contact } from '../../models/Contact';
import { ContactType } from '../../models/ContactTypeEnum';
import { ProductLevelPreferences } from '../../models/ProductLevelPreferences';
import { ProductType } from '../../models/ProductTypeEnum';
import { NotificationsResourceService } from '../../services/notifications-resource.service';

@Component({
  selector: 'sk-notification-settings-form',
  templateUrl: './notification-settings-form.component.html',
  styleUrls: ['./notification-settings-form.component.scss'],
  animations: [
    trigger(
        'fadeIn',
        [
            transition(
                ':enter',
                [
                    style({ opacity: 0 }),
                    animate('1s ease-out',
                        style({ opacity: 1 }))
                ]
            ),
            transition(
                ':leave',
                [
                    style({ opacity: 1 }),
                    animate('1s ease-in',
                        style({ opacity: 0 }))
                ]
            )
        ]
    )
]
})
export class NotificationSettingsFormComponent implements OnInit {
  @Input() productType: ProductType;

  ProductType = ProductType;


  failedToLoadPreferences: boolean = false;
  savingPreferences: boolean = false;
  form: FormGroup = null;
  private onDestroy$ = new Subject();

  productLevelPreferences: ProductLevelPreferences;

  constructor(
    private notificationResourceService: NotificationsResourceService,
    private toastrService: ToastrService,
    private userProvider: AbstractUserProvider
  ) {}

  ngOnInit(): void {
    var observable =
      this.notificationResourceService.getProductLevelPreferences(
        this.productType
      );

    observable.pipe(takeUntil(this.onDestroy$)).subscribe(
      (preferences: ProductLevelPreferences) =>
        this.initializeForm(preferences),
      () => {
        this.failedToLoadPreferences = true;
      }
    );
  }

  initializeForm(preferences: ProductLevelPreferences): void {
    this.productLevelPreferences = preferences;

    this.form = new FormGroup({
      criticalContacts: new FormControl(
        {
          value: this.getContactValues(preferences.criticalContacts),
          disabled: this.savingPreferences,
        }
      ),
      warningContacts: new FormControl(
        {
          value: this.getContactValues(preferences.warningContacts),
          disabled: this.savingPreferences,
        }
      ),
      informationContacts: new FormControl(
        {
          value: this.getContactValues(preferences.informationContacts),
          disabled: this.savingPreferences,
        }
      ),
      successContacts: new FormControl(
        {
          value: this.getContactValues(preferences.successContacts),
          disabled: this.savingPreferences,
        }
      ),
      billingContacts: new FormControl(
        {
          value: this.getContactValues(preferences.billingContacts),
          disabled: this.savingPreferences,
        }
      ),
    });

  }

  getContactValues(contacts: Contact[]): string[] {
    var contactValues: string[] = [];
    contacts.forEach((x) => contactValues.push(x.value));
    return contactValues;
  }

  setContactValues(contactValues: string[]): Contact[] {
    var contacts: Contact[] = [];
    for (let i = 0; i < contactValues.length; i++) {
      var contact: Contact = {
        name: 'unknown',
        value: contactValues[i],
        contactType: ContactType.email
      };
      contacts.push(contact);
    }
    return contacts;
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  onSubmit() {
    this.savingPreferences = true;
    this.productLevelPreferences.createdBy = this.userProvider?.getCurrentUser().fullName;
    this.productLevelPreferences.billingContacts = this.setContactValues(
      this.form.get('billingContacts').value as string[]
    );
    this.productLevelPreferences.criticalContacts = this.setContactValues(
      this.form.get('criticalContacts').value as string[]
    );
    this.productLevelPreferences.informationContacts = this.setContactValues(
      this.form.get('informationContacts').value as string[]
    );
    this.productLevelPreferences.successContacts = this.setContactValues(
      this.form.get('successContacts').value as string[]
    );
    this.productLevelPreferences.warningContacts = this.setContactValues(
      this.form.get('warningContacts').value as string[]
    );
    this.notificationResourceService
      .syncProductLevelPreferences(this.productLevelPreferences)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        (preferences: ProductLevelPreferences) => {
          this.productLevelPreferences = preferences;
          this.toastrService.success("Notification preferences saved successfully.")
        },
        () => {
          this.toastrService.error("Failed to save notification preferences.");
        }
      )
      .add(() => {
        this.savingPreferences = false;
      });
  }
}
