import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import {
  Observable,
  of,
} from 'rxjs';
import {
  catchError,
  map,
} from 'rxjs/operators';

import {
  AppId,
  RouteResolverService,
} from '@skykick/core';

import { Utils } from '../../../../../services/utils';
import BackupContactResult from '../models/contact/backup-contact-result';
import Contact from '../models/contact/contact';
import ContactSearchFilter from '../models/contact/contact-search-filter';
import MigrationContactResult from '../models/contact/migration-contact-result';
import ContactSearchResult from '../models/contact/contact-search-result';
import { ProductTag } from '../models/order/product-tag';

@Injectable({
    providedIn: 'root'
})
export class ContactsService {

    constructor(
        private http: HttpClient,
        private routeResolverService: RouteResolverService
    ) { }

    getContacts(filter: ContactSearchFilter, productTag: ProductTag): Observable<Contact[]> {
        switch (productTag) {
            case ProductTag.Backup: return this.getBackupContacts(filter);
            case ProductTag.Migration: return this.getMigrationContacts(filter);
            default: return of([]);
        }
    }

    private getMigrationContacts(filter: ContactSearchFilter): Observable<Contact[]> {
        return this.http.get(`${this.routeResolverService.generatePipeRoute(AppId.Papi)}migration/order/${filter.orderId}/contacts?searchTerm=${encodeURIComponent(filter.searchTerm)}&pageSize=${filter.maxResults}`)
            .pipe(
                map((res: MigrationContactResult[]) => res.map(x => ({ id: x.id, fullName: x.fullName, emailAddress: x.emailAddress }))),
                catchError(error => Utils.handleError(error, ContactsService.name)));
    }

    private getBackupContacts(filter: ContactSearchFilter): Observable<Contact[]> {
        return this.http.post<ContactSearchResult>(
                `${this.routeResolverService.generatePipeRoute(AppId.PapiBackup)}backup/${filter.orderId}/emailsearch`,
                { searchTerm: filter.searchTerm, pageSize: filter.maxResults, pageNumber: 1 })
            .pipe(
                map((res: ContactSearchResult) => res.result.map(x => ({ id: '', fullName: x.fullName, emailAddress: x.emailAddress }))),
                catchError(error => Utils.handleError(error, ContactsService.name)));
    }
}