import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
} from '@angular/common/http';
import { Injectable } from '@angular/core';

import {
  Observable,
  throwError,
} from 'rxjs';
import { catchError } from 'rxjs/operators';
import { DnsOrder } from 'src/app/dns-manager/models/DnsOrder';
import { RecordType } from 'src/app/dns-manager/models/RecordType';
import { ZoneRecords } from 'src/app/dns-manager/models/ZoneRecords';

import {
  AppId,
  RouteResolverService,
} from '@skykick/core';

@Injectable({
  providedIn: 'root'
})
export class DnsManagerService {

    constructor(
        private http: HttpClient,
        private routeResolverService: RouteResolverService
    ) { }

    private handleError(errorResponse: HttpErrorResponse): Observable<never> {
        if (errorResponse.error instanceof ErrorEvent) {
            console.error('Client Side Error: ', errorResponse.error.message);
        } else {
            console.error('Server Side Error: ', errorResponse);
        }
        console.log('There is a problem with the service. We are notified & working on it. Please try again later.');
        return throwError(errorResponse);
    }

        private getPAPIRoute(): string {
        return this.routeResolverService.generatePipeRoute(AppId.Papi);
    }

    public getOrders(): Observable<Array<DnsOrder>>{
        const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        const route = `${this.getPAPIRoute()}dns/orders`;

        return this.http.get<Array<DnsOrder>>(route, { headers })
                .pipe(catchError(this.handleError));
    }

    public getZone(order: DnsOrder): Observable<ZoneRecords> {
        const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        const route = `${this.getPAPIRoute()}dns/orders/${order.orderId}/migrationsources/${order.migrationSourceId}/zone/${order.route53HostedZoneId.split('/').pop()}`;

        return this.http.get<ZoneRecords>(route, { headers })
            .pipe(catchError(this.handleError));
    }

    public createRecord(order: DnsOrder, record: any, recordType: RecordType): Observable<any> {
        const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        const recordRoute = this.recordTypeRoutehelper(recordType);
        const route = `${this.getPAPIRoute()}dns/orders/${order.orderId}/migrationSources/${order.migrationSourceId}/zone/${order.route53HostedZoneId.split('/').pop()}/${recordRoute}`;

        return this.http.put<any>(route, record, {headers})
        .pipe(catchError(this.handleError));
    }

    public updateRecord(order: DnsOrder, record: any, recordType: RecordType): Observable<any> {
        const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        const recordRoute = this.recordTypeRoutehelper(recordType);
        const route = `${this.getPAPIRoute()}dns/orders/${order.orderId}/migrationSources/${order.migrationSourceId}/zone/${order.route53HostedZoneId.split('/').pop()}/${recordRoute}`;

        return this.http.post<any>(route, record, {headers})
        .pipe(catchError(this.handleError));
    }

    public removeRecord(order: DnsOrder, record: any, recordType: RecordType): Observable<any> {
        const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        const recordRoute = this.recordTypeRoutehelper(recordType);
        const route = `${this.getPAPIRoute()}dns/orders/${order.orderId}/migrationSources/${order.migrationSourceId}/zone/${order.route53HostedZoneId.split('/').pop()}/${recordRoute}/delete`;

        return this.http.post<any>(route, record, {headers})
        .pipe(catchError(this.handleError));
    }

    private recordTypeRoutehelper(recordType: RecordType): string {
        switch (recordType){
            case RecordType.A: {
              return 'aRecords';
            }
            case RecordType.AAAA: {
                return 'aaaaRecords';
            }
            case RecordType.CNAME: {
                return 'cnameRecords';
            }
            case RecordType.MX: {
                return 'mxRecords';
            }
            case RecordType.SRV: {
                return 'srvRecords';
            }
            case RecordType.TXT: {
                return 'txtRecords';
            }
            case RecordType.PTR: {
                return 'ptrRecords';
            }
            default: {
              return null;
            }
        }
    }
}
