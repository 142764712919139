import * as _ from 'lodash';
import {
  PermissionResult,
} from 'src/app/settings/users/models/permission-result';
import {
  PermissionScope,
} from 'src/app/settings/users/models/permission-scope';

import { MembersAccessProvider } from './members.access.provider';

export class MembersPermissionSummaryProvider {
    public static getPermissionScopes(accessRights: string[], permissions: PermissionResult[]): PermissionScope[] {
        return MembersPermissionSummaryProvider.getFullAccessPermissionScopes(accessRights)
            .concat(MembersPermissionSummaryProvider.getOtherPermissionScopes(permissions));
    }

    private static getFullAccessPermissionScopes(accessRights: string[]): PermissionScope[] {
        return accessRights
            .filter(ac => ac !== MembersAccessProvider.CloudManagerAccess.value)
            .map<PermissionScope>(accessScope => {
                return {
                    displayNameLocKey: MembersAccessProvider.getAccessDisplayNameLocKeyByValue(accessScope),
                    permissionDisplayNameLocKeys: ['settings.members.permissions.full-access']
                };
            });
    }

    private static getOtherPermissionScopes(permissions: PermissionResult[]): PermissionScope[] {
        return Object.entries<PermissionResult[]>(_.groupBy(permissions, x => x.Scope))
            .map(([permissionScope, permissionResultGroup]) => {
                return {
                    displayNameLocKey: `settings.members.access.${permissionScope.toLowerCase().split('_').join('-')}`,
                    permissionDisplayNameLocKeys: permissionResultGroup
                        .map(result => {
                            return `settings.members.permissions.${result.Name.toLowerCase().split('_').join('-')}`;
                        })
                };
            });
    }
}
