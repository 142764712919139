<div class="sk-flex-table">
    <div class="sk-scroll-container">
        <div class="d-flex flex-column min-width-sm max-width-lg py-200">
            <h4>Cloud Backup Customer Accounts</h4>
            <p>
                Data is polled overnight (PST) for all active Cloud Backup subscriptions. If a subscription was
                created or any changes were made since the last polling,
                that data will not appear until the next overnight polling occurs.
            </p>

            <div class="sk-table-cap py-100 m-0">
                <div class="d-inline-flex align-items-center mr-300">
                    <button *ngIf="(partnerData$ | async) as partnerData" (click)="syncBillingInfo()" type="button" 
                        class="btn btn-outline-secondary sk-btn-combo text-truncate" 
                        [disabled]="isSyncBillingInfo || partnerData.prorationSetting === PRORATIONSETTING.Unset">
                        <i class="material-icons md-22">sync</i>
                        <span>Sync Now</span>
                    </button>        
                </div>

                <div class="d-inline-flex flex-shrink-0">
                    <div class="sk-input-icon input-width-sm">
                        <form [formGroup]="form" novalidate>
                            <input type="text" class="form-control" formControlName="search" placeholder="Search" />
                            <i *ngIf="!form.get('search').value" class="material-icons md-18">search</i>
                            <i *ngIf="form.get('search').value" class="material-icons md-18 sk-reset-search cursor-pointer" 
                                (click)="form.get('search').setValue('')" title="Reset and Exit Search"></i>
                        </form>
                    </div>
                </div>
            </div>

            <div class="sk-table-cap m-0 py-50 d-flex justify-content-between">
                <div class="font-medium">Showing {{(filteredCustomerAccounts$ | async)?.length}} of {{totalRecords}}
                    Customers</div>
                <div ngbDropdown container="body" class="btn-group">
                    <button type="button" class="btn btn-basic dropdown-toggle" ngbDropdownToggle>Filter</button>
                    <div ngbDropdownMenu class="dropdown-menu">
                        <a *ngFor="let item of FILTERTYPE; let index = index" 
                            type="button" ngbDropdownItem class="dropdown-item" id="filterType{{index}}"
                            [class.active]="item.value === filterTypeValue"
                            (click)="filterTypeValue = item.value; filterCustomerAccounts(resultsPerPage)">{{item.name}}
                            <i *ngIf="item.value === filterTypeValue" class="material-icons md-22 ml-100">check</i>
                        </a>
                    </div>
                </div>
            </div>

            <table class="table table-hover sk-standard-wrap-table sk-sortable sk-sticky-header sk-min-width-lg ">
                <thead class="thead-white thead-sm thead-border-none">
                    <tr>
                        <th>Customer Name</th>
                        <th>ConnectWise Name</th>
                        <th>Agreement</th>
                        <th>Status</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody *ngIf="(filteredCustomerAccounts$ | async); else loading">
                    <tr *ngIf="!(filteredCustomerAccounts$ | async)?.length">
                        <td colspan="4">
                            No results available
                        </td>
                    </tr>

                    <tr *ngFor="let company of (filteredCustomerAccounts$ | async)  as companies" (click)="selectItem(company, $event)" [class.active]="(selectedCustomer$ | async)?.backupServiceId == company.backupServiceId">
                        <td>{{company.orderName}}</td>
                        <td [class.text-muted]="!company.companyName">{{company.companyName || "Select Customer"}}</td>
                        <td [class.text-muted]="!company.agreementName">{{company.agreementName || "Select Agreement Name"}}</td>
                        <td>
                            <i *ngIf="company.isEnabled" class="material-icons md-22 mr-50 icon-success d-flex">check_circle</i>
                        </td>
                        <td>
                            <div *ngIf="company.orderMappingId" id="sk-actions-menu" ngbDropdown class="btn-group" skStopPropagation>
                                <button ngbDropdownToggle type="button" class="btn sk-btn-icon caret-hidden">
                                    <i class="material-icons md-22">more_horiz</i>
                                </button>
                                <div class="dropdown-menu" ngbDropdownMenu>
                                    <a class="dropdown-item" (click)="removeMapping(company)" ngbDropdownItem>Remove Mapping</a>
                                </div>
                            </div>
                        </td>
                    </tr>
                </tbody>
                <ng-template #loading>
                    <tbody>
                        <tr><td colspan="5">
                            <sk-text-spinner message="Loading customer mappings"></sk-text-spinner>
                        </td></tr>
                    </tbody>
                </ng-template>
            </table>

        </div>
    </div>
</div>
