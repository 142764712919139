import { NgModule } from '@angular/core';
import {
  RouterModule,
  Routes,
} from '@angular/router';

import {
  AccountNotificationSettingsComponent,
} from './components/account-notification-settings/account-notification-settings.component';
import {
  BackupNotificationSettingsComponent,
} from './components/backup-notification-settings/backup-notification-settings.component';
import {
  MigrationNotificationSettingsComponent,
} from './components/migration-notification-settings/migration-notification-settings.component';
import {
  NotificationSettingsComponent,
} from './components/notification-settings.component';
import { HasPermissionGuard } from './guards/has-permission.guard';

export const NOTIFICATIONS_ROUTES: Routes = [
  {
    path: 'notifications',
    redirectTo: 'notifications/account',
    pathMatch: 'prefix',
  },
  {
    path: 'notifications',
    component: NotificationSettingsComponent,
    children: [
      {
        path: 'account',
        component: AccountNotificationSettingsComponent,
        canActivate: [HasPermissionGuard],
        data: {
          name: 'Account',
          type: 'secondary',
          permission: 'PartnerPortalAdmin',
          permissionAbsentRedirect: '/settings/notifications/migrations',
        },
      },
      {
        path: 'migrations',
        component: MigrationNotificationSettingsComponent,
        data: {
          name: 'Migrations',
          type: 'secondary',
          permission: 'Migrations_Full_Access',
          permissionAbsentRedirect: '/settings/notifications/account',
        },
      },
      {
        path: 'backup',
        component: BackupNotificationSettingsComponent,
        data: {
          name: 'Backup',
          type: 'secondary',
          permission: 'Cloud_Backup_Full_Access',
          permissionAbsentRedirect: '/settings/notifications/account',
        },
      },
    ],
  },
];

@NgModule({
  declarations: [],
  imports: [RouterModule.forChild(NOTIFICATIONS_ROUTES)],
  exports: [RouterModule],
})
export class NotificationsRoutingModule {}
