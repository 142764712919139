import {
    Component,
    OnDestroy,
    OnInit,
} from '@angular/core';
import {
    FormBuilder,
    FormControl,
    FormGroup,
    Validators,
} from '@angular/forms';
import {
    ActivatedRoute,
    Router,
    UrlSegment,
} from '@angular/router';

import { ToastrService } from 'ngx-toastr';
import {
    forkJoin,
    merge,
    Observable,
    Subject,
    throwError,
} from 'rxjs';
import {
    catchError,
    debounceTime,
    distinctUntilChanged,
    finalize,
    map,
    switchMap,
    take,
    takeUntil,
    tap,
} from 'rxjs/operators';
import {
    ErrorModalService,
} from 'src/app/settings/shared/services/error-modal.service';
import {
    PublicUrlsProvider,
} from 'src/app/settings/shared/services/public.urls.provider';
import {
    RelativeUrlsProvider,
} from 'src/app/settings/shared/services/relative.urls.provider';

import { NgbTypeaheadSelectItemEvent } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import {
    AppId,
    RouteResolverService,
} from '@skykick/core';
import {
    AbstractUserProvider,
} from '@skykick/platform-identity-auth-auth0-angular';

import {
    AuthenticationType,
    PartnerPortalUserClaims,
} from '../../../models/partner-portal-user-claims';
import { ClaimsService } from '../../../services/claims.service';
import { UsersService } from '../../../services/users.service';
import { AddM365MemberRequest } from '../models/add-m365-member-request';
import { CmLicensePermissions } from '../models/cm-license-permissions';
import { EditMemberRequest } from '../models/edit-member-request';
import { InviteMemberRequest } from '../models/invite-member-request';
import {
    LicenseInfo,
    LicensePermission,
} from '../models/license.info';
import { M365UserSearchFilter } from '../models/m365-user-search-filter';
import { Member } from '../models/member';
import {
    MemberActionEffectService,
} from '../services/member-action-effect.service';
import { MemberEmailValidator } from '../services/member-email-validator';
import { MembersRoleProvider } from '../services/members.role.provider';
import { MembersService } from '../services/members.service';
import PermissionCheckbox from './models/permission.checkbox';
import RoleRadioButton from './models/role.radio.button';

@Component({
    // tslint:disable-next-line: component-selector
    selector: 'sk-app-add-edit-member-form',
    templateUrl: './add-edit-member-form.component.html',
    styleUrls: ['./add-edit-member-form.component.scss']
})
export class AddEditMemberFormComponent implements OnInit, OnDestroy {

    editState: boolean;
    member: Member;
    partnerClaims: PartnerPortalUserClaims;
    updating = false;
    initialLoading: boolean;
    addMemberForm: FormGroup;
    addMemberEmailIsValid = true;
    addMemberEmailIsUnique = true;
    isRoleSelected = true;
    executingMainAction = false;
    isResetPasswordUnavailable = true;
    cloudBackupCheckbox: PermissionCheckbox;
    migrationsCheckbox: PermissionCheckbox;
    rolesRadioButtons: RoleRadioButton[];
    click$ = new Subject<string>();
    private destroy$: Subject<void> = new Subject();

    loadingEmail = false;
    emailQueryIsEmpty = true;

    constructor(
        private router: Router,
        private activeRoute: ActivatedRoute,
        private membersService: MembersService,
        private abstractUserProvider: AbstractUserProvider,
        private partnerService: ClaimsService,
        private translateService: TranslateService,
        private toastrService: ToastrService,
        private formBuilder: FormBuilder,
        private errorModalService: ErrorModalService,
        private membersActionEffectHandler: MemberActionEffectService,
        private routeResolverService: RouteResolverService) {

        this.editState = this.isEditState();
        this.initialLoading = true;

        // Populating roles radio buttons with descriptions
        forkJoin(
            MembersRoleProvider.Roles.map(role => {
                return this.translateService.get(`settings.members.roles.${role.key.toLowerCase()}-desc`).pipe(
                    map(descriptionList => {
                        const descriptions: string[] = [];
                        for (const field of Object.keys(descriptionList)) {
                            descriptions.push(descriptionList[field]);
                        }
                        const rolesRadioButtons: RoleRadioButton = {
                            roleKey: role.key,
                            roleName: `${role.displayNameLocKey}-role`,
                            descriptionList: descriptions
                        };
                        return rolesRadioButtons;
                    }));
            })
        ).subscribe(res => this.rolesRadioButtons = res);

        this.translateService.get('settings.members.licenses.cloud-backup.cloud-backup-desc').pipe(
            map(descriptionList => {
                const descriptions: string[] = [];
                for (const field of Object.keys(descriptionList)) {
                    descriptions.push(descriptionList[field]);
                }
                const rolesRadioButtons: PermissionCheckbox = {
                    permissionName: 'settings.members.access.cloud-backup',
                    descriptionList: descriptions
                };
                return rolesRadioButtons;
            })).subscribe(checkbox => this.cloudBackupCheckbox = checkbox);

        this.translateService.get('settings.members.licenses.migration.migration-desc').pipe(
            map(descriptionList => {
                const descriptions: string[] = [];
                for (const field of Object.keys(descriptionList)) {
                    descriptions.push(descriptionList[field]);
                }
                const rolesRadioButtons: PermissionCheckbox = {
                    permissionName: 'settings.members.access.migration',
                    descriptionList: descriptions
                };
                return rolesRadioButtons;
            })).subscribe(checkbox => this.migrationsCheckbox = checkbox);
    }

    ngOnInit(): void {
        if (this.editState) {
            this.initEditState();
        }
        else {
            this.initAddState();
        }
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    initEditState(): void {
        forkJoin([
            this.activeRoute.paramMap
                .pipe(
                    take(1),
                    map(params => params.get('memberId')),
                    switchMap(memberId => this.membersService.getMember(memberId)),
                    takeUntil(this.destroy$),
                ),
            this.partnerService.getPartnerPortalUserClaimsAsync(this.abstractUserProvider.getCurrentUser().email)
                .pipe(
                    takeUntil(this.destroy$),
                )
        ]).pipe(
            tap(memberWithClaims => {
                const [member, partnerClaims] = memberWithClaims;
                this.partnerClaims = partnerClaims;
                this.member = member;
                this.member.licenses.forEach(license => {
                    if (!license.enabled) {
                        // disabling permissions when license is disabled
                        license.permissions.forEach(permission => permission.enabled = false);
                    }
                });

                this.isResetPasswordUnavailable =
                    partnerClaims === undefined ||
                    partnerClaims.authenticationType === AuthenticationType.O365Auth;

                this.initialLoading = false;
                this.changeDescriptionsOfCmPermissions();
            }),
            catchError(error => {
                return this.handleErrorDuringInitialLoading(error);
            })
        ).subscribe();
    }

    initAddState(): void {
        forkJoin([
            this.membersService.getMember(this.abstractUserProvider.getCurrentUser().userId)
                .pipe(
                    takeUntil(this.destroy$),
                ),
            this.partnerService.getPartnerPortalUserClaimsAsync(this.abstractUserProvider.getCurrentUser().email)
                .pipe(
                    takeUntil(this.destroy$),
                )
        ]).pipe(
            tap(memberWithClaims => {
                const [member, partnerClaims] = memberWithClaims;
                this.partnerClaims = partnerClaims;
                this.initialLoading = false;

                member.licenses.forEach(license => {
                    license.enabled = false;
                    license.permissions.forEach(permission => permission.enabled = permission.initialEnabledState = false);
                });

                this.member = { ... this.getEmptyMember(), licenses: member.licenses };
                this.changeDescriptionsOfCmPermissions();
                this.addMemberEmailIsValid = true;
                this.addMemberEmailIsUnique = true;

                this.addMemberForm = this.formBuilder.group({
                    email: new FormControl(
                        '',
                        [Validators.email, Validators.required],
                        [MemberEmailValidator.createValidator(this.membersService)])
                },
                    { updateOn: 'blur' });
            }),
            catchError(error => {
                return this.handleErrorDuringInitialLoading(error);
            })
        ).subscribe();
    }

    changeRole(role: string): void {
        if (!this.isOwnMember()) {
            this.member.role = role;
        }

        this.validateRole();
    }

    toggleDeveloperStatus(): void {
        this.member.isDeveloper = !this.member.isDeveloper;
    }

    togglePermission(permission: LicensePermission): void {
        permission.enabled = !permission.enabled;
    }

    getAvailableLicenses(license: LicenseInfo): number {
        return license.total - license.assigned;
    }

    isLicenseEnabled(license: LicenseInfo): boolean {
        return license?.enabled;
    }

    isLicenseAvailable(license: LicenseInfo): boolean {
        const availableLicensesCount = this.getAvailableLicenses(license);
        return availableLicensesCount > 0 || (availableLicensesCount === 0 && license.enabled);
    }

    validateForm(): void {
        this.validateRole();
        this.validateEmail();
    }

    validateRole(): void {
        this.isRoleSelected = this.member.role !== '';
    }

    validateEmail(): void {
        this.addMemberEmailIsUnique = !this.addMemberForm.controls.email.errors?.memberEmailExists;
        this.addMemberEmailIsValid = this.addMemberEmailIsUnique ?
            !this.addMemberForm.invalid : true;
    }

    isFormValid(): boolean {
        return this.isRoleSelected && this.addMemberEmailIsValid && this.addMemberEmailIsUnique;
    }

    toggleLicense(license: LicenseInfo): void {
        if (license.enabled) {
            // Disabling license
            license.permissions.forEach(permission => permission.enabled = false);
            license.assigned = license.assigned - 1;
        }
        else {
            // Enabling license
            license.permissions.forEach(permission => permission.enabled = permission.initialEnabledState);
            license.assigned = license.assigned + 1;
        }

        license.enabled = !license.enabled;
    }

    resetEmailValidation(): void {
        this.addMemberEmailIsValid = true;
        this.addMemberEmailIsUnique = true;
    }

    getDevPortalPageUrl(): string {
        return PublicUrlsProvider.DevPortalPageUrl;
    }

    getTermsPageUrl(): string {
        return PublicUrlsProvider.TermsPageUrl;
    }

    getAccountSettingsPageUrl(): string {
        return this.routeResolverService.generateRatRoute(AppId.Portal, RelativeUrlsProvider.ManageAccountPageUrl);
    }

    getAuthTypeLocKey(): string {
        let authTypeLocKey = 'settings.common.authentication.skykick';
        if (this.isM365Authentication()) {
            authTypeLocKey = 'settings.common.authentication.m365';
        }
        else if (this.partnerClaims.isMFAEnabled) {
            authTypeLocKey = 'settings.common.authentication.skykick-mfa';
        }
        return authTypeLocKey;
    }

    isM365Authentication(): boolean {
        return this.partnerClaims.authenticationType === AuthenticationType.O365Auth;
    }

    resetPassword(member: Member): void {
        this.membersActionEffectHandler.resetPassword(
            this.membersService.resetPassword(member.username),
            member
        ).subscribe();
    }

    deactivateAccount(member: Member): void {
        this.membersActionEffectHandler.deactivate(
            this.membersService.deactivateMember(member.id),
            member,
            () => this.goToParentPage(member.id)
        ).subscribe();
    }

    buySubscription(): void {
        // TODO: In Defect 228116 we investigated the problem with not available purchase environment and
        // invalid routing. Instead of `${this.routeResolverService.generateRatRoute(AppId.Purchase)}overview`
        // we decided to use 'route.skykick.com' used previously in old User Management until routing is fixed.
        window.location.href = 'https://route.skykick.com/rat/purchase';
    }

    cancel(): void {
        this.goToParentPage();
    }

    addM365Member(): void {
        this.validateForm();

        if (!this.isFormValid() || this.addMemberForm.pending) {
            return;
        }
        else {
            this.executingMainAction = true;
            const addMemberRequest: AddM365MemberRequest = {
                email: this.addMemberForm.get('email').value,
                isAdmin: this.isAdminMember(),
                isDeveloper: this.member.isDeveloper,
                licenses: this.member.licenses
            };

            this.membersService.addM365Member(addMemberRequest)
                .pipe(
                    tap(result => {
                        if (result) {
                            this.translateService.get('settings.members.actions.add-success').pipe(
                                tap((successText: string) => this.toastrService.success(successText))
                            ).subscribe();
                            this.goToParentPage(result.UserId);
                        }
                        else {
                            throw new Error('Unsuccessful response when adding member');
                        }
                    }),
                    catchError(error => {
                        this.translateService.get('settings.members.actions.add-failed').pipe(
                            tap((errorText: string) => this.toastrService.error(errorText))
                        ).subscribe();
                        return throwError(error);
                    })
                ).subscribe(
                    () => { },
                    () => this.executingMainAction = false
                );
        }
    }

    sendInvite(): void {
        this.validateForm();

        if (!this.isFormValid() || this.addMemberForm.pending) {
            return;
        }
        else {
            this.executingMainAction = true;
            const inviteMemberRequest: InviteMemberRequest = {
                email: this.addMemberForm.get('email').value,
                isAdmin: this.isAdminMember(),
                isDeveloper: this.member.isDeveloper,
                licenses: this.member.licenses
            };
            this.membersService.inviteMember(inviteMemberRequest).pipe(
                tap(result => {
                    if (result) {
                        this.translateService.get('settings.members.actions.add-success').pipe(
                            tap((successText: string) => this.toastrService.success(successText))
                        ).subscribe();
                        this.goToParentPage(result.UserId);
                    }
                    else {
                        throw new Error('Unsuccessful response when adding member');
                    }
                }),
                catchError(error => {
                    return this.handleErrorDuringInitialLoading(error);
                })
            ).subscribe(
                () => { },
                () => this.executingMainAction = false
            );
        }
    }

    searchEmail = (searchTerm$: Observable<string>) => {
        return merge(this.click$, searchTerm$.pipe(debounceTime(200)))
            .pipe(
                distinctUntilChanged(),
                tap(term => {
                    this.loadingEmail = true;
                    term === '' ? this.emailQueryIsEmpty = true : this.emailQueryIsEmpty = false;
                }),
                switchMap(startsWith => this.membersService.getM365Users(new M365UserSearchFilter({ searchTerm: startsWith }))
                    .pipe(
                        map(user => user.map(x => x.userPrincipalName)),
                        catchError(error => {
                            this.translateService.get('settings.members.form.search-email-failed').pipe(
                                tap((errorText: string) => this.toastrService.error(errorText))
                            ).subscribe();
                            return throwError(error);
                        }),
                        finalize(() => this.loadingEmail = false))
                ),
                takeUntil(this.destroy$)
            );
    }

    searchFormatter(m365User: string): string {
        return m365User;
    }

    selectItem(event: NgbTypeaheadSelectItemEvent): void {
        this.addMemberEmailIsValid = true;
        this.emailQueryIsEmpty = false;
    }

    updateMember(): void {
        this.updating = true;

        const editMemberRequest: EditMemberRequest = {
            contactId: this.member.id,
            email: this.member.email,
            firstName: this.member.firstName,
            lastName: this.member.lastName,
            isAdmin: this.isAdminMember(),
            isDeveloper: this.member.isDeveloper,
            licenses: this.member.licenses
        };

        this.membersService.editMember(editMemberRequest).pipe(
            tap(result => {
                if (result) {
                    this.translateService.get('settings.members.actions.edit-success').pipe(
                        tap((successText: string) => this.toastrService.success(successText))
                    ).subscribe();
                }
                else {
                    throw new Error('Unsuccessful response when editing member');
                }
            }),
            catchError(error => {
                this.translateService.get('settings.members.actions.edit-failed').pipe(
                    tap((errorText: string) => this.toastrService.error(errorText))
                ).subscribe();
                return throwError(error);
            })
        ).subscribe(
            () => this.updating = false,
            () => this.updating = false
        );
    }

    private handleErrorDuringInitialLoading(error: any): Observable<never> {
        this.initialLoading = false;
        this.errorModalService.openErrorModal();
        return throwError(error);
    }

    private changeDescriptionsOfCmPermissions(): void {
        const consoleDescLocKey = 'settings.members.licenses.cm.console-desc';
        const workbenchDescLocKey = 'settings.members.licenses.cm.workbench-desc';
        const deviceConnectionsDescLocKey = 'settings.members.licenses.cm.device-connect-desc';
        const commandAdminDescLocKey = 'settings.members.licenses.cm.command-admin-desc';

        this.translateService.get([consoleDescLocKey, workbenchDescLocKey, deviceConnectionsDescLocKey, commandAdminDescLocKey])
            .subscribe(translations => {
                this.member.licenses.forEach(license => {
                    license.permissions.forEach(permission => {
                        switch (permission.name) {
                            case CmLicensePermissions.Console:
                                permission.description = translations[consoleDescLocKey];
                                break;
                            case CmLicensePermissions.Workbench:
                                permission.description = translations[workbenchDescLocKey];
                                break;
                            case CmLicensePermissions.DeviceConnections:
                                permission.description = translations[deviceConnectionsDescLocKey];
                                break;
                            case CmLicensePermissions.CommandAdmin:
                                permission.description = translations[commandAdminDescLocKey];
                                break;
                        }
                    });
                });
            });
    }

    private getEmptyMember(): Member {
        return {
            id: '',
            username: '',
            firstName: '',
            lastName: '',
            fullName: '',
            email: '',
            role: '',
            status: '',
            access: [],
            isUsernameMapped: false,
            permissionScopes: [],
            isDeveloper: false,
            licenses: []
        };
    }

    private goToParentPage(id: string = undefined): void {
        if (this.isEditState()) {
            this.router.navigateByUrl('settings/users/members', { state: { refreshMemberId: id } });
        }
        else {
            this.router.navigateByUrl('settings/users/members', { state: { addMemberId: id } });
        }
    }

    private isEditState(): boolean {
        const segments = this.getUrlSegments();
        return segments[segments.length - 1].path === 'edit';
    }

    isOwnMember(): boolean {
        return this.abstractUserProvider.getCurrentUser().email.toLowerCase() === this.member.email.toLowerCase();
    }

    private isAdminMember(): boolean {
        return MembersRoleProvider.isAdmin(this.member.role);
    }

    private getUrlSegments(): UrlSegment[] {
        const tree = this.router.parseUrl(this.router.url);
        return tree.root.children.primary.segments;
    }

    onEmailSearchClear() {
        this.addMemberForm.controls.email.setValue('');
        this.emailQueryIsEmpty = true;
    }

    isSubmitDisabled() {
        return this.executingMainAction || this.addMemberForm.controls.email.pending ? true : null;
    }
}
