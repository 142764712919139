<div class="sk-page">

    <h3 class="sk-title-block">Email Templates</h3>
    

    <p>{{templates[0].topText}}</p>

    <div class="container communications-modal-container">

        <div class="row">

                
                <sk-communications-modal class="col-2 text-center"
                    *ngFor="let template of templates;"  
                    [communicationDetails]="template">
                </sk-communications-modal>


        </div>

    </div>

    <p class="mt-3 text-muted">{{templates[0].bottomText}}</p>


</div>