import {
    Component,
    OnInit,
} from '@angular/core';
import { Router } from '@angular/router';

import {
    RelativeUrlsProvider,
} from 'src/app/settings/shared/services/relative.urls.provider';
import {
    AuthenticationType,
} from 'src/app/settings/users/models/partner-portal-user-claims';

import {
    AppId,
    RouteResolverService,
    SkyKickPanelTabViewBase,
} from '@skykick/core';

import { MemberStatus } from '../models/member-status';
import { MembersRoleProvider } from '../services/members.role.provider';
import { MembersStatusProvider } from '../services/members.status.provider';
import {
    MemberDetailsPanelContext,
} from './models/member-details-panel-context';
import MemberDetailsViewModel from './models/member-details-view-model';

@Component({
    /* tslint:disable-next-line:component-selector */
    selector: '[sk-member-details-panel]',
    templateUrl: './member-details-panel.component.html',
    styleUrls: ['./member-details-panel.component.scss']
})
export class MemberDetailsPanelComponent extends SkyKickPanelTabViewBase<MemberDetailsPanelContext, void> implements OnInit {

    member: MemberDetailsViewModel;

    constructor(
        private router: Router,
        private routeResolverService: RouteResolverService) {
        super();
    }

    ngOnInit(): void {
        this.member = this.data.viewModel;
    }

    getDisplayName(): string {
        return this.member.status === MembersStatusProvider.getStatusValueByKey(MemberStatus.Pending) ?
            this.member.email : this.member.fullName;
    }

    getAuthTypeLocKey(): string {
        let authTypeLocKey = 'settings.common.authentication.skykick';
    if (this.isM365Authentication()){
            authTypeLocKey = 'settings.common.authentication.m365';
        }
    else if (this.member.partnerClaims.isMFAEnabled){
            authTypeLocKey = 'settings.common.authentication.skykick-mfa';
        }
        return authTypeLocKey;
    }

  isM365Authentication(): boolean{
        return this.member.partnerClaims.authenticationType === AuthenticationType.O365Auth;
    }

    getAccountSettingsPageUrl(): string {
        return this.routeResolverService.generateRatRoute(AppId.Portal, RelativeUrlsProvider.ManageAccountPageUrl);
    }

    getPermissionLocKey(memberRoleKey: string): string {
        return MembersRoleProvider.getRoleDisplayNameLocKeyByKey(memberRoleKey);
    }

    isCurrentUserAdmin(): boolean {
        return this.member.partnerClaims && MembersRoleProvider.isAdmin(this.member.partnerClaims.legacyAccessRole);
    }

    showFooter(): boolean {
        return this.member.status === MembersStatusProvider.getStatusValueByKey(MemberStatus.Active);
    }

    editPermissions(): void {
        this.router.navigate(['settings', 'users', 'members', this.member.id, 'edit']);
    }

}
