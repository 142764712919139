import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { SharedModule } from '../shared/shared.module';
import {
  BackupCardComponent,
} from './components/backup-card/backup-card.component';
import {
  CloudManagerCardComponent,
} from './components/cloud-manager-card/cloud-manager-card.component';
import {
  CreateNewButtonComponent,
} from './components/create-new-button/create-new-button.component';
import {
  MigrationsCardComponent,
} from './components/migrations-card/migrations-card.component';
import { WelcomeComponent } from './components/welcome/welcome.component';
import { HomeComponent } from './home.component';

@NgModule({
  declarations: [
    MigrationsCardComponent,
    HomeComponent,
    MigrationsCardComponent,
    BackupCardComponent,
    CloudManagerCardComponent,
    CreateNewButtonComponent,
    WelcomeComponent,
  ],
  imports: [
    CommonModule,
    SharedModule
  ]
})
export class HomeModule { }
