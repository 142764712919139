<div id="backup_card" class="card h-100">
    <div class="card-header sk-card-header d-flex align-items-center justify-content-between">
        <div class="d-flex align-items-center">
            <i class="material-icons md-24 icon-primary mr-100">restore</i>
            <h4 translate>NAV.CLOUDBACKUP</h4>
        </div>
        <a id="back_card_arrow_button" type="button" class="btn sk-btn-icon sk-btn-icon pr-0" href="https://backup.skykick.com/partner/cloud-backup/manager#/">
            <i class="material-icons md-20 icon-muted">keyboard_arrow_right</i>
        </a>
    </div>
    <div class="card-body">
        <div *ngIf="gettingCounts && gettingAlerts;">
            <div class="d-flex align-items-center flex-column">
                <div class="sk-spin-loader" ng-class="size"></div>
            </div>
        </div>
        <div *ngIf="!gettingCounts && !gettingAlerts;">
            <div *ngIf="showBlurb" [@animateIn]>
                <p translate>home.cards.backup_body_default</p>
                <a id="backup_getting_started_link" href="https://support.skykick.com/hc/en-us/articles/216137837-Cloud-Backup-Getting-Started-Guide" class="mr-3" translate>home.cards.links.getting_started_guide</a>
                <a id="backup_why_365_link" href="https://blog.skykick.com/the-role-of-cloud-backup-in-office-365" translate>home.cards.links.why_365_backup</a>
            </div>
            <div class="row">
                <div *ngIf="!showBlurb" [@animateIn] class="col-md-6">
                    <h1 class="display-4">{{activeBackups}}</h1>
                    <a class="link-primary" (click)="onGoToBackupsClicked()" translate>home.cards.links.active</a>
                </div>
                <div *ngIf="!showBlurb" [@animateIn] class="col-md-6">
                    <h1 class="display-4">{{pendingBackups}}</h1>
                    <a class="link-primary" (click)="onGoToBackupsClicked()" translate>home.cards.links.pending</a>
                </div>
            </div>
        </div>
    </div>
    <div id="sk-backup-critical-alert-link" role="button" class="d-flex px-100 py-50 bg-red-100 align-items-center justify-content-between" 
    (click)="onCritAlertsClick()" 
    *ngIf="!gettingCounts && !gettingAlerts && formattedCritialAlertCount != '0'" [@expandIn]>
        <div class="d-flex align-items-center">
            <i class="material-icons md-22 icon-danger pr-50">error</i>
            <div class="text-dark font-weight-medium" translate>home.cards.critical_notifications</div>
        </div>
        <span id="sk-backup-critical-alert-counts" class="badge badge-pill">{{formattedCritialAlertCount}}</span>
    </div>
    <div class="card-footer d-flex align-items-center justify-content-between">
        <div class="d-flex align-items-center">
            <a id="create_backup_subscription_button" type="button" class="btn btn-secondary-outline" [href]="getBackupPlannerUrl()" translate>home.cards.buttons.create_subscription</a>
        </div>
    </div>
</div>