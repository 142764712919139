<div class="sk-flex-table">
    <div class="sk-scroll-container">
        <div class="d-flex flex-column min-width-sm max-width-lg py-200">
            <div class="row">
                <div class="col-xl-8">
                    <h5>Description</h5>
                    <p>
                        To further empower our partners' success in the Cloud, SkyKick has built an integration between its Cloud Backup 
                        application and ConnectWise Manage.
                        This integration makes it even easier to manage invoicing by pushing the necessary information for all backup projects
                        to ConnectWise Manage, where partners can manage invoices.
                        <a href="https://support.skykick.com/hc/en-us/articles/360004178353-SkyKick-Cloud-Backup-ConnectWise-Manage-integration-overview"
                            target="_blank" class="font-medium">Read more</a>
                    </p>

                    <ng-container *ngIf="!isPartnerActive()">
                        <p>
                            The SkyKick Backup - ConnectWise Manage integration requires that a partner first have a ConnectWise Manage
                            account.
                            For more information, go to <a href="">ConnectWise Manage</a>. For step-by-step instructions on enabling and using
                            the integration,
                            see <a href="https://support.skykick.com/hc/en-us/articles/360003975094-How-to-enable-the-Cloud-Backup-ConnectWise-integration" target="_blank">How to enable SkyKick - ConnectWise integration for Cloud Backup</a>.
                        </p>
                        
                        <h5>Daily data pushes</h5>
                        <p>
                            Daily data pushes from Cloud Backup into ConnectWise Manage include number of migrating objects (licenses) and the
                            total cost to partner.
                            This push occurs at 3:00 AM Pacific Time, or can be initiated on-demand with the Sync Now button.
                        </p>
                        
                        <h5>Tickets created in ConnectWise Manage if data push issues arise</h5>
                        <p>
                            If there are any issues with pushing data to ConnectWise Manage (e.g. expired, inactive, or future-dated agreement),
                            tickets are created in ConnectWise Manage.
                        </p>
                    </ng-container>

                    <div class="card sk-card mb-3">
                        <div class="card-header sk-card-header d-flex justify-content-between">
                            <h4>Map Cloud Backup Customer Accounts</h4>
                        </div>

                        <div *ngIf="isPartnerActive()" class="card-block">
                            <p class="card-text">
                                Data is polled overnight (PST) for all active Cloud Backup subscriptions. If a subscription was created or any changes were made since the last polling, that data will not appear until the next overnight polling occurs.
                            </p>
                            <div class="row">
                                <div class="col-4 d-flex flex-column">
                                    <h1 class="display-2">{{ mappedRecords }}/{{ totalRecords }}</h1>
                                    <span class="text-muted">Customers Mapped</span>
                                </div>
                                <div class="col-4 d-flex flex-column text-center">
                                    <i *ngIf="!loadingConnectionStatus && connectionStatus" class="material-icons md-32 icon-success">check_circle</i>
                                    <i *ngIf="!loadingConnectionStatus && connectionStatus === false" class="material-icons md-32 icon-danger">error</i>
                                    <div *ngIf="loadingConnectionStatus">
                                        <div class="spinner-grow bg-primary" role="status">
                                            <span class="sr-only">Loading...</span>
                                        </div>
                                    </div>
                                    <span class="text-muted">Connection Status</span>
                                </div>
                                <div class="col-4 d-flex align-items-end">
                                    <span class="text-muted text-right pr-50">Proration Setting:</span>
                                    <span *ngIf="prorationSettingEnabled()" class="badge badge-default">Enabled</span>
                                    <span *ngIf="!prorationSettingEnabled()" class="badge badge-danger">Disabled</span>
                                </div>
                            </div>
                        </div>

                        <div *ngIf="!isPartnerActive()" class="card-block">
                            <h5 class="card-text mt-100">What the integration will do:</h5>
                            <p class="card-text text-muted mt-150">
                                Sync the following data:
                                <li>Company name</li>
                                <li>Number of seats migrated</li>
                                <li>SkyKick Invoice #</li>
                                <li>Cost per seat</li>
                                <li>Backup order creation date</li>
                                <li>Total cost</li>
                            </p>
                        </div>

                        <div *ngIf="isPartnerActive()" class="card-footer d-flex align-items-center justify-content-between">
                            <div class="d-flex align-items-center">
                                <span class="text-muted pr-50">Last Sync: </span>
                                <span> {{ lastSyncTime }}</span>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-xl-4">
                </div>
            </div>
        </div>
    </div>
</div>
