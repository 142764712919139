import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Utils } from '../../../../../services/utils';
import { PartnerInfo } from '../models/PartnerInfo';
import { AbstractUserProvider } from '@skykick/platform-identity-auth-auth0-angular';

import {
    AppId,
    RouteResolverService,
} from '@skykick/core';

@Injectable({
    providedIn: 'root',
})
export class PartnerResourcesService {
    constructor(
        private http: HttpClient,
        private abstractUserProvider: AbstractUserProvider,
        private routeResolverService: RouteResolverService
    ) {}

    getPartnerInfo(): Observable<PartnerInfo> {
        const partnerId = this.abstractUserProvider.getCurrentUser().partnerId;
        const url = `${this.getPAPIRoute()}partner/admin/${partnerId}/companyinfo`;
        return this.http.get<PartnerInfo>(url)
        .pipe(
             catchError(error => Utils.handleError(error, partnerId))
        );
    }

    private getPAPIRoute(): string {
        return this.routeResolverService.generatePipeRoute(AppId.Papi);
    }
}
