import { ClipboardModule } from '@angular/cdk/clipboard';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { CookieService } from 'ngx-cookie-service';
import { AvatarModule, LayoutModule, TasksModule, DropdownsModule, ButtonsModule, InputModule } from '@skykick/core';
import { SharedModule } from '../shared/shared.module';
import { LeftNavComponent } from './left-nav/left-nav.component';
import { NotificationSettingsModule } from './notifications/notification-settings.module';
import { SettingsRoutingModule } from './settings-routing.module';
import { SettingsComponent } from './settings.component';
import { UsersModule } from './users/users.module';
import { windowProvider, WindowToken } from './window';
import { IntegrationsModule } from './integrations/integrations.module';
import { UserProfileComponent } from './user-profile/user-profile.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AccountModule } from './account/account.module';

@NgModule({
  declarations: [
    SettingsComponent,
    LeftNavComponent,
    UserProfileComponent,
  ],
  imports: [
    SettingsRoutingModule,
    UsersModule,
    NotificationSettingsModule,
    SharedModule,
    AvatarModule,
    LayoutModule,
    TasksModule,
    DropdownsModule,
    ButtonsModule,
    InputModule,
    NgbModule,
    ReactiveFormsModule,
    ClipboardModule,
    AccountModule,
    IntegrationsModule
  ],
  providers: [
    CookieService,
    { provide: WindowToken, useFactory: windowProvider },
  ]
})
export class SettingsModule { }
