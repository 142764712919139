import { Injectable } from '@angular/core';

import { FilterArea } from 'src/app/settings/models/filter-area';
import {
  FilterAreaWithGroups,
} from 'src/app/settings/models/filter-area-with-groups';
import { FilterItem } from 'src/app/settings/models/filter-item';
import {
  FilterItemWithGroup,
} from 'src/app/settings/models/filter-item-with-group';
import {
  PermissionFilterItemGroup,
} from 'src/app/settings/models/permission-filter-item-group';

import {
  CustomerAccess,
  CustomerPermission,
} from '../models';
import { CustomersAccessProvider } from './customers.access.provider';
import { CustomersPermissionProvider } from './customers.permission.provider';
import { CustomerStatusProvider } from './customers.status.provider';

@Injectable({
  providedIn: 'root'
})
export class FiltersManagerService {
  private accessFilterKey = 'access';
  private permissionsFilterKey = 'permissions';
  private statusFilterKey = 'status';

  getFilterAreas(): FilterArea[] {
    const accessFilterArea: FilterArea = {
      displayName: 'settings.customers.table.access',
      key: this.accessFilterKey,
      selectedFiltersCount: 0,
      filters: [],
    };
    accessFilterArea.filters.push(...CustomersAccessProvider.AccessRights
      .map(role => new FilterItem(role.displayNameLocKey, role.value)));

    const permissionFilterArea: FilterAreaWithGroups<PermissionFilterItemGroup> = {
      displayName: 'settings.customers.table.permissions',
      key: this.permissionsFilterKey,
      selectedFiltersCount: 0,
      filters: [],
      groups: [] = [
        {
          displayName: 'settings.customers.table.filter.groups.cloudBackup',
          group: PermissionFilterItemGroup.CloudBackup
        },
        {
          displayName: 'settings.customers.table.filter.groups.migrations',
          group: PermissionFilterItemGroup.Migrations
        },
      ],
    };
    permissionFilterArea.filters.push(...CustomersPermissionProvider.Permissions
      .map(access => new FilterItemWithGroup(access.displayNameLocKey, access.value, false, true, access.group)));

    const statusFilterArea: FilterArea = {
      displayName: 'settings.customers.table.status',
      key: this.statusFilterKey,
      selectedFiltersCount: 0,
      filters: [],
    };
    statusFilterArea.filters.push(
      ...CustomerStatusProvider.Statuses
        .map(status => new FilterItem(status.displayNameLocKey, status.value)));

    const filterAreas = [accessFilterArea, permissionFilterArea, statusFilterArea];
    return filterAreas;
  }

  handleFilterChecked(parentFilterArea: FilterArea, filterChecked: FilterItem, filterAreas: FilterArea[]): void {
    filterChecked.isSelected = !filterChecked.isSelected;

    if (this.isAccessArea(parentFilterArea)){
      const isOneOfAccessesSelected = parentFilterArea.filters.filter(f => f.isSelected).length === 1;
      const isBackupChecked = filterChecked.value === CustomersAccessProvider.getAccessValueByKey(CustomerAccess.Backup);

      if (isOneOfAccessesSelected && filterChecked.isSelected) { // if selected one access
        this.setBackupFilterItemsEnabled(filterAreas, isBackupChecked);
        this.setMigrationFilterItemsEnabled(filterAreas, !isBackupChecked);
      } else if (isOneOfAccessesSelected && !filterChecked.isSelected) { // if unselected one access
        this.setBackupFilterItemsEnabled(filterAreas, !isBackupChecked);
        this.setMigrationFilterItemsEnabled(filterAreas, isBackupChecked);
      } else { // if selected both or none
          this.setBackupFilterItemsEnabled(filterAreas, true);
          this.setMigrationFilterItemsEnabled(filterAreas, true);
      }
    }

    this.updateSelectedFiltersCount(filterAreas);
  }

  uncheckAllFilters(filterAreas: FilterArea[]): void {
    filterAreas.forEach(area => {
        area.selectedFiltersCount = 0;
        area.filters.forEach(filter => {
          filter.isSelected = false;
        });
    });
  }

  getSelectedFiltersCount(filterAreas: FilterArea[]): number {
    return filterAreas
      .map(x => x.selectedFiltersCount)
      .reduce((partialSum, a) => partialSum + a, 0);
  }

  private updateSelectedFiltersCount(filterAreas: FilterArea[]): void {
    filterAreas.forEach(area => {
      area.selectedFiltersCount = area.filters
        .filter(filter => filter.isSelected).length;
    });
  }

  private setBackupFilterItemsEnabled(filterAreas: FilterArea[], isEnabled: boolean): void {
    const backupFilterValues = [
      CustomersPermissionProvider.getPermissionValueByKey(CustomerPermission.SearchAndRestore),
      CustomersPermissionProvider.getPermissionValueByKey(CustomerPermission.FullAccess),
    ];

    const permissionsFilterArea = filterAreas.filter(x => x.key === this.permissionsFilterKey)[0];

    const backupFilterItems = permissionsFilterArea.filters
      .filter(x => backupFilterValues.indexOf(x.value) > -1);

    if (!isEnabled){
      backupFilterItems.forEach(x => {
        x.isSelected = false;
        x.isEnabled = false;
      });
    }
    else {
      backupFilterItems.forEach(x => {
        x.isEnabled = true;
      });
    }
  }

  private setMigrationFilterItemsEnabled(filterAreas: FilterArea[], isEnabled: boolean): void {
    const migrationsFilterValues = [
      CustomersPermissionProvider.getPermissionValueByKey(CustomerPermission.ReadOnly),
      CustomersPermissionProvider.getPermissionValueByKey(CustomerPermission.ReadAndWrite),
    ];

    const permissionsFilterArea = filterAreas.filter(x => x.key === this.permissionsFilterKey)[0];

    const migrationsFilterItems = permissionsFilterArea.filters
      .filter(x => migrationsFilterValues.indexOf(x.value) > -1);

    if (!isEnabled){
      migrationsFilterItems.forEach(x => {
        x.isSelected = false;
        x.isEnabled = false;
      });
    }
    else {
      migrationsFilterItems.forEach(x => {
        x.isEnabled = true;
      });
    }
  }

  private isAccessArea(filterArea: FilterArea): boolean {
    return filterArea.key === this.accessFilterKey;
  }

  getPermissions(filterAreas: FilterArea[]): string[] {
    return this.getFilterArrayByAreaKey(filterAreas, this.permissionsFilterKey);
  }

  getAccess(filterAreas: FilterArea[]): string[] {
    return this.getFilterArrayByAreaKey(filterAreas, this.accessFilterKey);
  }

  getStatuses(filterAreas: FilterArea[]): string[] {
    return this.getFilterArrayByAreaKey(filterAreas, this.statusFilterKey);
  }

  private getFilterArrayByAreaKey(filterAreas: FilterArea[], filterAreaKey: string): string[] {
    const filterArea: FilterArea = filterAreas.filter(x => x.key === filterAreaKey)[0];

    return filterArea.filters
      .filter(filterItem => filterItem.isSelected)
      .map(filterItem => filterItem.value);
  }

  getUniqueFilterValue(filterAreas: FilterArea[]): string {
    return filterAreas.map(
        filterArea => {
          const filtersString =  filterArea.filters
            .filter(x => x.isSelected).map(x => x.value).join();
          return `${filterArea.key}[${filtersString}]`;
        }
      ).join();
  }
}
