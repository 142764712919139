import {
    Component,
    OnInit,
    OnDestroy,
} from '@angular/core';
import {
    ActivatedRoute,
    Router,
} from '@angular/router';

import { ToastrService } from 'ngx-toastr';
import {
    Observable,
    throwError,
    Subject,
    forkJoin,
} from 'rxjs';
import {
    catchError,
    map,
    switchMap,
    take,
    takeUntil,
    tap,
} from 'rxjs/operators';
import {
    ErrorModalService,
} from 'src/app/settings/shared/services/error-modal.service';

import { TranslateService } from '@ngx-translate/core';

import {
    Customer,
    CustomerAccess,
    CustomerPermission,
} from '../models';
import CustomerUserRequest from '../models/customer-user-request';
import {
    CustomersAccessProvider,
    CustomersPermissionProvider,
    CustomersService,
} from '../services';
import { AbstractUserProvider } from '@skykick/platform-identity-auth-auth0-angular';
import { ClaimsService } from '../../../services/claims.service';
import { CustomerActionEffectService } from '../services/customer-action-effect.service';

@Component({
    templateUrl: './edit-customer.component.html',
    styleUrls: ['./edit-customer.component.scss']
})
export class EditCustomerComponent implements OnInit, OnDestroy {
    public CustomerPermission = CustomerPermission;
    initialLoading = true;
    customer: Customer;
    access: CustomerAccess;
    permission: CustomerPermission;
    isResetPasswordUnavailable = true;
    private destroy$: Subject<void> = new Subject();

    constructor(
        private customerService: CustomersService,
        private router: Router,
        private activeRoute: ActivatedRoute,
        private translateService: TranslateService,
        private toastrService: ToastrService,
        private errorModalService: ErrorModalService,
        private abstractUserProvider: AbstractUserProvider,
        private partnerService: ClaimsService,
        private customerActionEffectService: CustomerActionEffectService) {
    }

    ngOnInit(): void {
        forkJoin([
            this.activeRoute.paramMap
                .pipe(
                    take(1),
                    map(params => params.get('customerId')),
                    switchMap(customerId => this.customerService.getCustomer(customerId)),
                    takeUntil(this.destroy$)
                ),
            this.partnerService.getPartnerPortalUserClaimsAsync(this.abstractUserProvider.getCurrentUser().email)
                .pipe(
                    takeUntil(this.destroy$)
                )
        ]).pipe(
            tap(([customer, claims]) => {
                this.initialLoading = false;
                this.customer = customer;
                this.access = CustomersAccessProvider.getAccessKeyByValue(this.customer.access);
                this.permission = CustomersPermissionProvider.getPermissionKeyByValue(customer.permission);
                this.isResetPasswordUnavailable =
                    claims === undefined ||
                    !claims.isSelfServicePageEnabled;
            }),
            catchError(error => {
                return this.handleErrorDuringInitialLoading(error);
            })
        ).subscribe();
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    updateCustomer(): void {
        this.initialLoading = true;
        const permissionStringValue = CustomersPermissionProvider.getPermissionValueByKey(this.permission);

        const customerUserRequest: CustomerUserRequest = {
            contactId: this.customer.id,
            isReadOnly: this.permission === CustomerPermission.ReadOnly,
            isBackupCustomer: this.isBackupOrder(),
            backupRole: permissionStringValue
        };

        this.customerService.editCustomer(customerUserRequest).pipe(
            tap(result => {
                if (result) {
                    this.navigateToCustomers(customerUserRequest.contactId);
                    this.translateService.get('settings.customers.actions.edit-customer-success').pipe(
                        tap((successText: string) => this.toastrService.success(successText))
                    ).subscribe();
                }
                else {
                    throw new Error('Unsuccessful response when updating customer');
                }
            }),
            catchError(error => {
                this.initialLoading = false;
                this.translateService.get('settings.common.error.something-wrong').pipe(
                    tap((errorText: string) => this.toastrService.error(errorText))
                ).subscribe();
                return throwError(error);
            })).subscribe(() => {
                this.initialLoading = false;
                this.customer.permission = permissionStringValue;
            });
    }

    cancel(): void {
        this.navigateToCustomers();
    }

    changePermission(permission: CustomerPermission): void {
        this.permission = permission;
    }

    isPermissionSelected(permission: CustomerPermission): boolean {
        return this.permission === permission;
    }

    isBackupOrder(): boolean {
        return this.access === CustomerAccess.Backup;
    }

    isMigrationOrder(): boolean {
        return this.access === CustomerAccess.Migration;
    }

    resetPassword(customer: Customer): void {
        this.customerActionEffectService.resetPassword(
            this.customerService.resetPassword(customer.username),
            customer
        ).subscribe();
    }

    deactivateCustomer(customer: Customer): void {
        this.customerActionEffectService.deactivate(
            this.customerService.deactivateCustomer(customer.id),
            customer,
            () => this.navigateToCustomers(customer.id)
        ).subscribe();
      }

    private navigateToCustomers(id: string = undefined): void {
        this.router.navigateByUrl('settings/users/customers', { state: { refreshCustomerId: id } })
    };

    private handleErrorDuringInitialLoading(error: any): Observable<never> {
        this.initialLoading = false;
        this.errorModalService.openErrorModal();
        return throwError(error);
    }
}
