import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'sk-map-customers',
    templateUrl: './map-customers.component.html',
    styleUrls: ['./map-customers.component.scss']
})
export class MapCustomersComponent implements OnInit {
    customerAccountsForm: FormGroup;
    customerAccounts: any = [
        { name: 'ABC Company', isActive: true, accountName: null, agreementName: null, includeProration: true, includeUsernames: false },
        { name: "Bob's Plumbing", isActive: false, accountName: null, agreementName: null, includeProration: false, includeUsernames: true },
        { name: 'Deftronics', isActive: true, accountName: null, agreementName: null, includeProration: true, includeUsernames: false },
        { name: 'Easton Cameras', isActive: true, accountName: null, agreementName: null, includeProration: false, includeUsernames: false },
        { name: 'Franklin', isActive: false, accountName: null, agreementName: null, includeProration: false, includeUsernames: false },
        { name: 'GoTechnologies', isActive: true, accountName: null, agreementName: null, includeProration: false, includeUsernames: false },
        { name: 'Insta Tech', isActive: true, accountName: null, agreementName: null, includeProration: false, includeUsernames: false },
        { name: 'Jasper IT', isActive: true, accountName: null, agreementName: null, includeProration: false, includeUsernames: false }
    ];

    constructor(
        private formBuilder: FormBuilder,
        public activeModal: NgbActiveModal
    ) { }

    get accountsFormArray() {
        return this.customerAccountsForm.get('accounts') as FormArray;
    }

    get account(): FormGroup {
        return this.formBuilder.group({
            accountNameSelect: [null, [Validators.required]],
            agreementNameSelect: [null, [Validators.required]],
            includeProration: [null],
            includeUsernames: [null]
        });
    }

    ngOnInit(): void {
        this.customerAccountsForm = this.formBuilder.group({
            accounts: this.formBuilder.array([])
        });
        this.customerAccounts.forEach((account, index) => {
            this.accountsFormArray.push(this.account);
            const currentForm = this.accountsFormArray.at(index);
        });
        this.accountsFormArray.patchValue(this.customerAccounts);
    }

}
