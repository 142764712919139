export class MembersAccessProvider {

    public static readonly CloudManagerAccess = {
        key: 0,
        displayNameLocKey: 'settings.members.access.cloud-manager',
        value: 'CloudManager'
    };

    public static readonly AccessRights = [
        { key: 1, displayNameLocKey: 'settings.members.access.cloud-backup', value: 'CloudBackup'},
        MembersAccessProvider.CloudManagerAccess,
        { key: 3, displayNameLocKey: 'settings.members.access.migration', value: 'Migration'},
        { key: 2, displayNameLocKey: 'settings.members.access.billing', value: 'Billing'},
    ];

    public static getAccessValueByKey(key: number): string {
        return this.AccessRights.find(x => x.key === key).value;
    }

    public static getAccessDisplayNameLocKeyByValue(value: string): string {
        return this.AccessRights.find(x => x.value === value).displayNameLocKey;
    }
}
