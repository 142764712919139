import { Injectable } from '@angular/core';

// HttpClient and related imports.
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

// For getting to PAPI.
import {
  AppId,
  RouteResolverService,
} from '@skykick/core';

// For debug and error handling.
import { Utils } from '../../services/utils';
// import { RouteInterceptor } from '../../interceptors/route-interceptor';

// Models.
import { MpnModel } from '../models/MpnModel';
export interface RegistrationInfo {
  addressRequired: boolean;
  mpnRequired: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class PapiHttpService {
  papiRoute = "";

  constructor(private http: HttpClient, private routeResolverService: RouteResolverService) { 
    this.papiRoute = this.getPAPIRoute();
  }

  // Called when the user accepts the terms. No parameters needed.
  postTandCCallback(): Observable<any> {
    // SAMPLE RESPONSE: {"success":true,"result":false,"SaveOnly":false}
    const url = this.papiRoute + 'webapi/partner/termsandconditions/accept';
    return this.http.get<any>(url).pipe(
      catchError(error => Utils.handleError(error, 'postTandCCallback()'))
    );
  }

  // Gets information whether or not they have accepts the T&C.
  getTandCAccepted(): Observable<any> {
    // Note: This API call returns more than we need;
    // there was a note on this in portal v1 that we could refactor...
    // it is now ported over to portal v2.
    const url = this.papiRoute + 'webapi/partner/info/me';
    return this.http.get<any>(url).pipe(
      catchError(error => Utils.handleError(error, 'getTandCAccepted()'))
    );
    // SAMPLE RESPONSE:
    // {
    //   "success": true,
    //   "result": {
    //     "Id": "2403891c-ca87-47e9-8953-08daea9a4b87",
    //     "Name": "Skyking Pizza",
    //     "POCFirstName": "Matt",
    //     "POR": "3285645",
    //     "PartnerDomain": "skykick.com",
    //     "IURAvailable": true,
    //     "PromptPartnerForDistiId": false,
    //     "DistributorAffiliation": "Unknown",
    //     "ForceTsCsAcceptance": false,
    //     "Location": "772370000",
    //     "LanguageCultureName": "en",
    //     "LanguageCultureId": 9,
    //     "BetaFlights": {
    //       "BackupIntegration": false,
    //       "CrossDomainAliases": false,
    //       "RulesAndResources": false,
    //       "SKOAInternational": false,
    //       "GoogleLabels": false,
    //       "CommunicationTemplates": false,
    //       "BackupRestOfWorld": false,
    //       "SelfServiceMigrationOrder": false,
    //       "DataOnlyPlus": false,
    //       "SkoaDeviceDashboard": false,
    //       "CloudBackupOneDriveBrowse": false,
    //       "CloudBackupSharepointBrowse": false,
    //       "CloudBackupActiveLicenses": false,
    //       "CloudBackupOffice365Groups": false,
    //       "SkoaBetaDownload": false,
    //       "CloudBackupPointInTimeRestore": false,
    //       "SyndicationBackupSelfService": false,
    //       "SyndicationOrderConfiguration": false
    //     },
    //     "IsApiDeveloper": false,
    //     "MigrationProductVersion": "VNOW",
    //     "ApimDeveloperPortalUrl": "",
    //     "RequiresPONumber": false,
    //     "DashboardStatus": 1,
    //     "Disabled": false,
    //     "DashboardStatusDisplayName": "NewDashboard"
    //   },
    //   "SaveOnly": false
    // }
  }

  // Returns whether mpn and/or address is required.
  getRegistrationInfo(): Observable<RegistrationInfo> {
    // SAMPLE RESPONSE: {"addressRequired":false,"mpnRequired":false}
    const url = this.papiRoute + 'partner/register/info';
    return this.http.get<any>(url).pipe(
      catchError(error => Utils.handleError(error, 'getRegistrationInfo()'))
    );
  }

  // Saves new MPN registration information.
  saveMpnRegistrationInformation(mpnRegistrationRequest: MpnModel) {
    // This returns a 204 on success.
    const url = this.papiRoute + 'partner/register/mpn';
    return this.http.post<any>(url, mpnRegistrationRequest).pipe(
      catchError(error => Utils.handleError(error, 'saveMpnRegistrationInformation()'))
    );
  }
  
  // Gets current MPN data.
  getMpnData(mpn:string): Observable<any> {
    const url = this.papiRoute +'partner/mpn/'+ mpn +'/';
    return this.http.get<any>(url).pipe(
      catchError(error => Utils.handleError(error, 'getMpnData()'))
    );
    // SAMPLE RESPONSE:
    // {
    //   "id": "3cb2a944-4691-46fe-9b10-7985b1a98ca9",
    //   "mpnNumber": "3285645",
    //   "organizationName": "SkyKick, Inc",
    //   "address1": "200 West Thomas St",
    //   "address2": "Suite 400",
    //   "address3": null,
    //   "city": "Seattle",
    //   "stateCode": "WA",
    //   "stateDisplayName": "Washington",
    //   "zipCode": "98119-4215",
    //   "countryOrRegion": "United States",
    //   "technicalContactEmail": "eric.jewett@skykick.com",
    //   "phoneNumber": null,
    //   "languagePreferenceCode": "en",
    //   "languagePreferenceDisplayName": "English",
    //   "createDateTime": "2019-06-11T21:38:33.59",
    //   "lastUpdateDateTime": "2019-06-11T21:38:33.59",
    //   "lastValidatedDateTime": "2019-06-11T21:38:33.59",
    //   "mpnProfileUrl": "https://portal.office.com/CompanyManagement/PublicCompanyProfile.aspx?id=17c827da-147d-406c-a201-24ea6b6e5b21&msppid=3285645"
    // }
  }

  // Go to PAPI through the SkyKick.Web.Routing project.
  private getPAPIRoute(): string {
    return this.routeResolverService.generatePipeRoute(AppId.Papi);
  }
}
