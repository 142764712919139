import { Component, HostBinding, OnInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { TaskManagerService } from '@skykick/core';
import { ToastrService } from 'ngx-toastr';
import { forkJoin, Observable, of, Subject, fromEvent, combineLatest } from 'rxjs';
import { tap, skipWhile, takeUntil, map, switchMap, distinctUntilChanged, startWith } from 'rxjs/operators';
import { TaskType } from 'src/app/settings/models/task-type';
import { ConnectwiseResourcesService } from '../core/services/connectwise-resources.service';

@Component({
  selector: '[sk-tickets]',
  templateUrl: './tickets.component.html',
  styleUrls: ['./tickets.component.scss']
})
export class TicketsComponent implements OnInit, OnDestroy {
  @HostBinding('class.sk-content-primary') isActive = true;
  private onDestroy$ = new Subject();
  isLoadingServiceBoards = true;
  isBusy: boolean;
  connectWiseServiceBoards$: Observable<any>;
  ticketMapping: any;
  selectedTicketMappingName: string;
  oldTicketMappingName: string;
  @ViewChild('searchBoardsInput')
  searchBoardsInput: ElementRef;

  constructor(
    private connectwiseResourcesService: ConnectwiseResourcesService,
    private toastrService: ToastrService,
    private taskManagerService: TaskManagerService) { }

  ngOnInit(): void {
    const serviceBoards$ = this.connectwiseResourcesService.getServiceBoards().pipe(
      skipWhile(val => !val),
      tap(res => {
          this.isLoadingServiceBoards = false;
      })
    );

    const ticketMapping$ = this.connectwiseResourcesService.getTicketMapping();

    forkJoin([serviceBoards$, ticketMapping$]).pipe(
        takeUntil(this.onDestroy$)
    ).subscribe(([serviceBoards, ticketMapping]) => {
        this.connectWiseServiceBoards$ = combineLatest(
          of(serviceBoards),
          this.searchInputObservable().pipe(startWith(''))
        ).pipe(
          switchMap(([boards, searchTerm]) => {
            return searchTerm && searchTerm !== '' ?
              of(boards.filter(x => x.name.toLowerCase().includes(searchTerm.toLowerCase()))) :
              of(boards);
          })
        );

        if (ticketMapping) {
          this.ticketMapping = ticketMapping;
          this.selectedTicketMappingName = ticketMapping.board;
          this.oldTicketMappingName = ticketMapping.board;
        }
    });
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
  }

  selectBoard(serviceBoard: any): void {
    this.selectedTicketMappingName = serviceBoard.name;
  }

  addMapping(): void {
    if (this.oldTicketMappingName === this.selectedTicketMappingName){
      this.toastrService.info('This Ticket Mapping is already active');
      return;
    }

    this.isBusy = true;

    const addTicketMappingObservable = this.oldTicketMappingName ?
      this.connectwiseResourcesService.deleteTicketMapping(this.ticketMapping.ticketMappingId).pipe(
        switchMap(_ => this.connectwiseResourcesService.addTicketMapping(this.selectedTicketMappingName))) :
      this.connectwiseResourcesService.addTicketMapping(this.selectedTicketMappingName);

    addTicketMappingObservable.subscribe(res =>
      {
        this.ticketMapping = res;
        this.selectedTicketMappingName = this.ticketMapping.board;
        this.oldTicketMappingName = this.selectedTicketMappingName;
        this.toastrService.success('Ticket Mapping Activated');
        this.isBusy = false;
      },
      _ =>
      {
        this.isBusy = false;
        this.toastrService.error('Couldn\'t Activate Ticket Mapping');
      }
    );
    this.taskManagerService.abortTask(TaskType.ConnectWiseInfo);
  }

  private searchInputObservable(): Observable<string> {
    return fromEvent<any>(this.searchBoardsInput.nativeElement, 'keyup')
      .pipe(
        map(event => event.target.value),
        distinctUntilChanged()
      );
  }
}
