import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ConnectwiseComponent } from './connectwise/connectwise.component';
import { ConnectWisePartnerResolver } from './connectwise/core/resolvers/connectwise-partner.resolver';
import { CustomersComponent } from './connectwise/customers/customers.component';
import { DetailsComponent } from './connectwise/details/details.component';
import { SettingsComponent } from './connectwise/settings/settings.component';
import { TicketsComponent } from './connectwise/tickets/tickets.component';
import { HomeComponent } from './home/home.component';
import { IntegrationsComponent } from './integrations.component';
import { MicrosoftPartnerCenterComponent } from './microsoft-partner-center/microsoft-partner-center.component';

export const INTEGRATIONS_ROUTES: Routes = [
    {
        path: 'integrations/connectwise',
        redirectTo: 'integrations/connectwise/details',
        pathMatch: 'prefix',
    },
    {
        path: 'integrations',
        component: IntegrationsComponent,
        pathMatch: 'prefix',
        children: [
            {
                path: '',
                component: HomeComponent
            },
            {
                path: 'connectwise',
                component: ConnectwiseComponent,
                resolve: {
                    partnerData: ConnectWisePartnerResolver
                },
                children: [
                    {
                        path: 'details',
                        component: DetailsComponent
                    },
                    {
                        path: 'customers',
                        component: CustomersComponent
                    },
                    {
                        path: 'tickets',
                        component: TicketsComponent
                    },
                    {
                        path: 'settings',
                        component: SettingsComponent
                    }

                ]
            },
            {
                path: 'mpc',
                component: MicrosoftPartnerCenterComponent
            }
        ]
    }
];

@NgModule({
    imports: [RouterModule.forChild(INTEGRATIONS_ROUTES)],
    exports: [RouterModule]
})
export class IntegrationsRoutingModule { }
