import { HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { BannerModule, HeaderModule, LayoutModule, NavigationModule } from '@skykick/core';
import { AbstractUserProvider, AuthModule, SkyKickUserProvider } from '@skykick/platform-identity-auth-auth0-angular';
import { AuthService } from '../auth.service';
import { PapiHttpService } from '../mpn-registration/services/papi-http.service';
import { MpnCheckService } from '../mpn-registration/services/mpn-check.service';
import { DeveloperModule } from '../developer/developer.module';
import { HomeModule } from '../home/home.module';
import { ConfigurationModule, ConfigurationService } from '../services/configuration.service';
import { SettingsModule } from '../settings/settings.module';
import { PortalRoutingModule } from './portal-routing.module';
import { PortalComponent } from './portal.component';

@NgModule({
  declarations: [
    PortalComponent
  ],
  imports: [
    HomeModule,
    DeveloperModule,
    SettingsModule,
    PortalRoutingModule,
    BannerModule,
    BrowserModule,
    BrowserAnimationsModule, // required by ToastrModule
    ToastrModule.forRoot(),
    NavigationModule,
    HeaderModule,
    LayoutModule,
    HttpClientModule,
    NgbModule,
    AuthModule.forRoot({
      LicenseAssignmentServiceBaseUrl: '',
      SewrSystemDomain: ''
    })
  ],
  providers: [
    ConfigurationService,
    ConfigurationModule.init(),
    AuthService,
    {
      provide: AbstractUserProvider,
      useClass: SkyKickUserProvider, // 'useExisting: environment.userProvider' can be used for local run
    },
    PapiHttpService,
    MpnCheckService,
    {
      provide: APP_INITIALIZER,
      useFactory: (authService: AuthService) => {
        return () => {
          authService.run();
        };
      },
      multi: true,
      deps: [AuthService]
    }
  ],
  bootstrap: [PortalComponent]
})
export class PortalModule { }
