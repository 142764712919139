  <main class="sk-content-pane">




	<div class="sk-page-title sk-title-border">
		<h1 class="ng-scope">{{'settings.profile.user-profile' | translate}}</h1>
	</div>

    <div class="sk-page">


        <!-- Personal Info -->
        <div class="sk-page-block fixed4"  name="personalInfo" novalidate="">
            <h3 class="sk-title-block" >{{'settings.profile.personal-info' | translate}}</h3>
            <form class="form-group"
                  [formGroup]="personalInfoForm"
                  (ngSubmit)="onSubmitPersonalInfoForm()">

                <label for="firstNameInput">{{'settings.profile.name' | translate}}</label>
                <input id="firstNameInput" class="form-control mb-3" formControlName="firstNameInput" type="text" placeholder="first name">

                <label for="lastNameInput">{{'settings.profile.last-name' | translate}}</label>
                <input id="lastNameInput" class="form-control mb-3" formControlName="lastNameInput" type="text" placeholder="last name">

                <label for="emailInput">{{'settings.profile.email' | translate}}</label>
                <input id="emailInput" class="form-control mb-3" formControlName="emailInput" type="text" placeholder="email">

                <label for="phoneInput">{{'settings.profile.phone' | translate}}</label>
                <input id="phoneInput" class="form-control mb-3" formControlName="phoneInput" type="text" placeholder="phone">

                <label for="cellPhoneInput">{{'settings.profile.cell-phone' | translate}}</label>
                <input id="cellPhoneInput" class="form-control mb-3" formControlName="cellPhoneInput" type="text" placeholder="cell phone">

                <label for="usernameInput">{{'settings.profile.username' | translate}}</label>
                <input id="usernameInput" class="form-control mb-3" formControlName="usernameInput" type="text" placeholder="username" disabled>

                <div class="float-right">
                    <button type="submit"
                            sk-spinner-button [active]="savingPersonalInfo" [disabled]="savingPersonalInfo || personalInfoForm.invalid || !personalInfoForm.dirty"
                            color="primary"
                            class="btn btn-primary mr-3">{{'settings.profile.update' | translate}}
                    </button>
                    <button type="button"
                            (click)="onCancel()"
                            [disabled]="savingPersonalInfo || personalInfoForm.invalid || !personalInfoForm.dirty"
                            color="secondary"
                            class="btn btn-secondary">{{'settings.profile.cancel' | translate}}
                    </button>
                </div>
		    </form>
        </div>


        <!-- Language & Region -->
        <div class="sk-page-block fixed4" name="languagesAndRegion" novalidate="">
            <h3 class="sk-title-block" >{{'settings.profile.language-and-region' | translate}}</h3>
            <label for="sk-languageDropdown"><b>{{'LANGUAGE' | translate}}</b></label>
            <sk-dropdown class="btn-block" id="sk-languageDropdown"
                        (optionSelected)="onChangeLanguage($event)"
                        [label]=chosenLanguage
                        [options]="languageOptions"
                        type="outline-primary"
                        [wide]="true"
                        >
            </sk-dropdown>
        </div>


        <!-- Change Password -->
        <div class="sk-page-block fixed4" name="changePassword" novalidate="">
            <h3 class="sk-title-block ng-scope" >{{'settings.profile.change-password' | translate}}</h3>
            <form class="form-group"
                  [formGroup]="changePasswordForm"
                  (ngSubmit)="onSubmitSavePassword()"
                  (keydown.enter)="$event.preventDefault()">

                <div>
                    <label for="oldPassword">{{'settings.profile.old-password' | translate}}</label>
                    <div class="sk-input-icon sk-input-group  mb-3">
                        <input [type]="showOldPassword ? 'text' : 'password'" class="form-control" id="oldPassword" formControlName="oldPassword" placeholder="{{'settings.profile.old-password' | translate}}">
                        <button type="button" class="btn sk-btn-icon-basic sk-password-toggle">
                            <i class="material-icons md-18 sk-password-show" (click)="showOldPassword = !showOldPassword">{{showOldPassword ? 'visibility_off' : 'visibility'}}</i>
                        </button>
                    </div>

                    <label for="newPassword">{{'settings.profile.new-password' | translate}}</label>
                    <div class="sk-input-icon sk-input-group  mb-3">
                        <input
                          [type]="showNewPassword ? 'text' : 'password'"
                          class="form-control"
                          id="newPassword"
                          formControlName="newPassword"
                          placeholder="{{'settings.profile.new-password' | translate}}"
                          [ngbPopover]="passwordPopover"
                          placement="bottom"
                          [autoClose]="'outside'"
                          popoverClass="password-popover" >
                        <button type="button" class="btn sk-btn-icon-basic sk-password-toggle">
                            <i class="material-icons md-18 sk-password-show" (click)="showNewPassword = !showNewPassword">{{showNewPassword ? 'visibility_off' : 'visibility'}}</i>
                        </button>
                    </div>
                    <ng-template #passwordPopover>
                      <div class="password-popover-title">{{'platform.password.requirements' | translate}}:</div>
                      <div class="password-rule-error" [class.password-rule-valid]="isPasswordRuleValid('minlength') && isPasswordRuleValid('maxlength')">{{ 'platform.password.length' | translate }}</div>
                      <div class="password-rule-error" [class.password-rule-valid]="!hasPasswordPatternErrors()">{{ 'platform.password.pattern' | translate }}</div>
                      <div class="password-pattern-error" [class.password-pattern-valid]="isPasswordRuleValid('hasLowerCase')">{{ 'platform.password.lowercase' | translate }}</div>
                      <div class="password-pattern-error" [class.password-pattern-valid]="isPasswordRuleValid('hasUpperCase')">{{ 'platform.password.uppercase' | translate }}</div>
                      <div class="password-pattern-error" [class.password-pattern-valid]="isPasswordRuleValid('hasNumber')">{{ 'platform.password.number' | translate }}</div>
                      <div class="password-pattern-error" [class.password-pattern-valid]="isPasswordRuleValid('hasSpecialCharacters')">{{ 'platform.password.special_character' | translate }}</div>
                    </ng-template>
                </div>
                <br>
                <div class="float-right">
                    <button type="submit"
                            sk-spinner-button [active]="savingPassword" [disabled]="savingPassword || changePasswordForm.invalid || !changePasswordForm.dirty"
                            color="primary"
                            class="btn btn-primary">
                        <span>{{'settings.profile.change-password' | translate}}</span>
                    </button>
                </div>
            </form>
        </div>
        <br>


        <!-- Developer API Access User Profile -->
        <div class="sk-page-block fixed5" name="developerAPI" novalidate="">
            <!-- <h3 class="sk-title-block ng-scope" >Developer API Access</h3> -->

            <div sk-id-gen="DeveloperApiAccess" class="container" id="sk-adminUserProfileDeveloperApiAccess" aria-hidden="false">
                <h3 class="sk-title-block sk-title-border ng-scope" >{{'settings.profile.user-profile' | translate}}</h3>

                <dl class="sk-dl">
                    <dt  class="ng-scope">{{'settings.profile.user-id' | translate}}</dt>
                    <dd class="ng-binding">{{partnerInfoModel.ApiDeveloperKey}}</dd>
                </dl>

                <dl class="sk-dl" *ngFor="let subscription of partnerInfoModel.SubscriptionList; let i=index">

                    <dt class="ng-scope">{{subscription.Name}}</dt>

                    <dd class="row ng-scope">
                        <label class="col-md-3 ng-scope">{{'settings.profile.primary-key' | translate}}</label>
                        <span class="col">
                            {{toggleKeys[(i*2)] ? [subscription.PrimaryKey] : [keyMask]}}
                            <a class="ng-scope" (click)="toggleKeys[(i*2)] = !toggleKeys[(i*2)]">{{toggleKeys[(i*2)] ? "Hide" : "Show"}}</a>
                        </span>
                    </dd>

                    <dd class="row ng-scope">
                        <label class="col-md-3 ng-scope">{{'settings.profile.secondary-key' | translate}}</label>
                        <span class="col">
                            {{toggleKeys[(i*2+1)] ? [subscription.SecondaryKey] : [keyMask]}}
                            <a class="ng-scope" (click)="toggleKeys[(i*2+1)] = !toggleKeys[(i*2+1)]">{{toggleKeys[(i*2+1)] ? "Hide" : "Show"}}</a>
                        </span>
                    </dd>

                </dl>

            </div>

        </div>

    </div>

</main>
