import { Injectable } from '@angular/core';
import { AuthenticateComponent } from '../../connection-wizard/authenticate/authenticate.component';
import { MapProductsComponent } from '../../connection-wizard/map-products/map-products.component';
import { MapTicketComponent } from '../../connection-wizard/map-ticket/map-ticket.component';
import { SyncSettingsComponent } from '../../connection-wizard/sync-settings/sync-settings.component';
import { WizardStepItem } from '../models/WizardStepItem';
import { WIZARDTYPES } from 'src/app/settings/integrations/connectwise/core/constants/wizard-types.const'

@Injectable()
export class WizardStepsService {
    getWizardSteps() {
        return [
            new WizardStepItem(
                AuthenticateComponent,
                { name: 'Authenticate', type: WIZARDTYPES.authenticate }
            ),
            new WizardStepItem(
                MapProductsComponent,
                { name: 'Map Products', type: WIZARDTYPES.mapproducts }
            ),
            new WizardStepItem(
                MapTicketComponent,
                { name: 'Map Tickets', type: WIZARDTYPES.maptickets }
            ),
            // new WizardStepItem(
            //     SyncSettingsComponent,
            //     { name: 'Sync Settings', type: WIZARDTYPES.syncsettings }
            // )
        ]
    }
}
