<!-- CONTENT COLUMN -->
<div class="sk-content-column">
    <div class="flex-center-center pb-100" [ngClass]="{'d-none': !initialLoading}">
        <div class="sk-spin-loader"></div>
    </div>
    <!-- Heading  -->
    <div *ngIf="editState && member" class="d-flex flex-row align-items-center justify-content-between py-100 px-200 border-bottom">
        <!-- Heading Left -->
        <div class="d-flex flex-row align-items-center">
            <!-- Avatar -->
            <sk-avatar class="d-flex align-items-center square-56 mr-100" size="lg" [fullName]="member.fullName">
            </sk-avatar>

            <div class="d-flex flex-column">
                <h2 class="font-weight-normal">{{ member.fullName }}</h2>
                <span class="text-muted">{{ member.email }}</span>
            </div>
        </div>
        <!-- Heading Right -->
        <div class="d-flex align-items-center">
            <sk-actions-menu
                [showOutline] = "true"
                *ngIf="member?.status === 'Active'">
                <a (click)="resetPassword(member)"
                    class="dropdown-item"
                    [hidden]="isResetPasswordUnavailable"
                    ngbDropdownItem>{{ 'settings.members.actions.reset-password' | translate }}</a>
                <a *ngIf="!isOwnMember()"
                    (click)="deactivateAccount(member)"
                    class="dropdown-item text-danger"
                    ngbDropdownItem>{{ 'settings.members.actions.deactivate-account' | translate }}</a>
            </sk-actions-menu>
        </div>
    </div>
    <!-- /.flex-row -->

    <!-- CONTENT PRIMARY - SCROLLABLE -->
    <div *ngIf="member" class="sk-content-primary">
        <div class="sk-flex-table">
            <div id="scrollWrap" class="sk-scroll-container">
                <div class="d-flex flex-column min-width-lg max-width-lg pt-150">
                    <div class="row">
                        <div class="col-10">
                            <div class="pb-200">

                                <!-- Email -->
                                <div *ngIf="!editState" class="align-items-center pb-100">
                                    <h3 class="font-400 pb-50">{{ 'settings.members.add-a-member' | translate }}</h3>
                                    <div class="d-inline-flex">
                                        <p>{{ 'settings.members.add-member-desc' | translate }}</p>
                                    </div>
                                    <form [formGroup]="addMemberForm" novalidate>
                                        <fieldset class="form-group" [class.has-danger]="!addMemberEmailIsValid || !addMemberEmailIsUnique">
                                            <label>{{ 'settings.common.email.address' | translate }}</label>
                                            <div *ngIf="!isM365Authentication()" class="sk-input-icon input-width-xl">
                                                <input
                                                    (keyup)="resetEmailValidation()"
                                                    type="text"
                                                    class="form-control"
                                                    formControlName="email"/>
                                                <i *ngIf="addMemberForm.controls.email.pending" class="sk-spin-loader loader-sm material-icons spin-component-custom"></i>
                                            </div>
                                            <div *ngIf="isM365Authentication()" class="sk-input-icon input-width-xl">
                                                <input
                                                    class="form-control"
                                                    aria-label="Search"
                                                    formControlName="email"
                                                    [ngbTypeahead]="searchEmail"
                                                    [inputFormatter]="searchFormatter"
                                                    [resultFormatter]="searchFormatter"
                                                    (click)="click$.next($any($event).target.value)"
                                                    (selectItem)="selectItem($event)"
                                                    (keyup)="resetEmailValidation()"
                                                    placeholder="{{ 'settings.members.form.search-for-member' | translate }}" />
                                                <i *ngIf="loadingEmail" class="sk-spin-loader loader-sm material-icons spin-component-custom"></i>
                                                <i *ngIf="!emailQueryIsEmpty && !loadingEmail" (click)="onEmailSearchClear()" class="material-icons md-18 sk-reset-search-btn ng-star-inserted" title="Reset and Exit Search"></i>
                                                <i *ngIf="emailQueryIsEmpty && !loadingEmail" class="material-icons md-18">search</i>
                                            </div>
                                            <small *ngIf="!addMemberEmailIsValid"
                                                class="form-control-feedback">{{ 'settings.common.email.invalid' | translate }}</small>
                                            <small *ngIf="!addMemberEmailIsUnique"
                                                class="form-control-feedback">{{ 'settings.common.email.exists' | translate }}</small>
                                        </fieldset>
                                    </form>
                                </div>

                                <div class="align-items-center pb-100">
                                    <h3 *ngIf="editState" class="font-400">{{ 'settings.members.form.permissions' | translate }}</h3>
                                    <h3 *ngIf="!editState" class="font-400">{{ 'settings.members.form.set-permissions' | translate }}</h3>
                                    <p class="pt-50">{{ 'settings.members.form.permissions-desc' | translate }}</p>
                                </div>

                                <!-- Roles -->
                                <div class="border-bottom">
                                    <h4 class="pb-50">{{ 'settings.members.form.skykick-account' | translate }}</h4>
                                    <fieldset class="form-group" [class.has-danger]="!isRoleSelected">
                                        <ng-container *ngFor="let roleRadio of rolesRadioButtons; let i = index">
                                            <div class="custom-control custom-radio sk-radio">
                                                <input type="radio" id="roleRadio{{i}}" name="roleRadio" class="custom-control-input"
                                                    [value]="roleRadio.roleKey" [checked]="member.role === roleRadio.roleKey"
                                                    [disabled]="isOwnMember() ? true : null">
                                                <label class="custom-control-label" for="roleRadio{{i}}"
                                                    (click)="changeRole(roleRadio.roleKey)">{{ roleRadio.roleName | translate }}</label>
                                                <small class="sk-form-text">
                                                    <ul class="sk-ul pl-100">
                                                        <li *ngFor="let desc of roleRadio.descriptionList">{{ desc }}</li>
                                                    </ul>
                                                </small>
                                            </div>
                                        </ng-container>
                                        <small *ngIf="!isRoleSelected" class="form-control-feedback">
                                            {{ 'settings.members.form.value-required' | translate }}
                                        </small>
                                    </fieldset>
                                </div>

                                <!-- Cloud Backup -->
                                <div class="border-bottom py-150">
                                    <h4 class="pb-50">{{ cloudBackupCheckbox.permissionName | translate }}</h4>

                                    <div class="custom-control custom-checkbox sk-checkbox">
                                        <input [checked]="true" [disabled]="true"
                                            type="checkbox" class="custom-control-input" id="backupFullAccess">
                                        <label class="custom-control-label" for="backupFullAccess">
                                            {{ 'settings.members.permissions.full-access' | translate }}
                                            <div class="alert badge-info d-inline-flex px-25 p-0 ml-25 font-500">
                                                {{ 'settings.members.form.coming-soon' | translate }}
                                            </div>
                                        </label>
                                        <small class="sk-form-text">
                                            <ul class="sk-ul pl-100">
                                                <li *ngFor="let desc of cloudBackupCheckbox.descriptionList">{{ desc }}</li>
                                            </ul>
                                        </small>
                                    </div>
                                </div>

                                <!-- Migrations -->
                                <div class="border-bottom py-150">
                                    <h4 class="pb-50">{{ migrationsCheckbox.permissionName | translate }}</h4>

                                    <div class="custom-control custom-checkbox sk-checkbox">
                                        <input [checked]="true" [disabled]="true"
                                            type="checkbox" class="custom-control-input" id="migrationFullAccess">
                                        <label class="custom-control-label" for="migrationFullAccess">
                                            {{ 'settings.members.permissions.full-access' | translate }}
                                            <div class="alert badge-info d-inline-flex px-25 p-0 ml-25 font-500">
                                                {{ 'settings.members.form.coming-soon' | translate }}
                                            </div>
                                        </label>
                                        <small class="sk-form-text">
                                            <ul class="sk-ul pl-100">
                                                <li *ngFor="let desc of migrationsCheckbox.descriptionList">{{ desc }}</li>
                                            </ul>
                                        </small>
                                    </div>
                                </div>

                                <!-- Cloud Manager -->
                                <div class="border-bottom py-150">
                                    <h4 class="--pb-50">{{ 'settings.members.form.licenses.cloud-manager' | translate }}</h4>
                                    <p class="text-muted">{{ 'settings.members.form.licenses.add-cloud-manager' | translate }}</p>

                                    <!-- Buy CM subscription -->
                                    <div *ngIf="member.licenses && member.licenses.length === 0" class="alert sk-alert-icon-24 alert-info d-flex align-items-center justify-content-between w-75 p-50" role="alert">
                                        <div class="d-flex align-items-center ">
                                            <i class="material-icons md-22 pl-50">info</i>
                                            <div class="mx-50">
                                                <span class="sk-alert-text">
                                                    <strong>{{ 'settings.members.form.licenses.purchase-skykick' | translate }}</strong>
                                                </span>
                                            </div>
                                        </div>
                                        <div class="flex-shrink-0">
                                            <button (click)="buySubscription()" type="button" class="btn btn-outline d-inline-block">
                                                {{ 'settings.members.form.licenses.buy-subscription' | translate }}
                                            </button>
                                        </div>
                                    </div>

                                    <!-- CM licenses -->
                                    <ng-container *ngFor="let license of member.licenses">
                                        <div class="row mb-200">
                                            <div class="col-sm-6">
                                                <div class="card ">
                                                    <div class="card-header sk-card-header d-flex align-items-center border-bottom-0 pt-100">
                                                        <div class="">
                                                            <span class="font-weight-medium">{{ license.displayName }}</span>
                                                        </div>
                                                        <div class="sk-onoffswitch sk-switch-sm ml-auto">
                                                            <input type="checkbox" name="sk-onoffswitch" class="sk-onoffswitch-checkbox"
                                                                id="myonoffswitch11" [checked]="isLicenseEnabled(license)"
                                                                (change)="toggleLicense(license)"
                                                                [disabled]="!isLicenseAvailable(license) ? true : null">
                                                            <label class="sk-onoffswitch-label" for="myonoffswitch11">
                                                                <span class="sk-onoffswitch-icon-inner"></span>
                                                                <span class="sk-onoffswitch-switch"></span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div class="card-block pt-50">
                                                        <p class="card-text">{{ 'settings.members.form.licenses.available' | translate }}
                                                            <span class="badge badge-pill badge-info">{{ getAvailableLicenses(license) }}</span>
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <ng-container *ngFor="let permission of license.permissions">
                                            <fieldset class="form-group">
                                                <div class="custom-control custom-checkbox sk-checkbox">
                                                    <input type="checkbox" class="custom-control-input" [id]="permission.id" [checked]="permission.enabled"
                                                        (change)="togglePermission(permission)" [disabled]="!isLicenseEnabled(license) ? true : null">
                                                    <label class="custom-control-label" [for]="permission.id">{{ permission.name }}</label>
                                                    <small class="sk-form-text ">{{ permission.description }}</small>
                                                </div>
                                            </fieldset>
                                        </ng-container>
                                    </ng-container>
                                </div>

                                <!-- Billing -->
                                <div class="border-bottom py-150">
                                    <h4 class="pb-50">{{ 'settings.members.access.billing' | translate }}</h4>

                                    <div class="custom-control custom-checkbox sk-checkbox">
                                        <input [checked]="true" [disabled]="true"
                                            type="checkbox" class="custom-control-input" id="billingFullAccess">
                                        <label class="custom-control-label" for="billingFullAccess">
                                            {{ 'settings.members.permissions.full-access' | translate }}
                                            <div class="alert badge-info d-inline-flex px-25 p-0 ml-25 font-500">
                                                {{ 'settings.members.form.coming-soon' | translate }}
                                            </div>
                                        </label>
                                        <small class="sk-form-text">
                                            {{ 'settings.members.licenses.billing.billing-desc' | translate }}
                                        </small>
                                    </div>
                                </div>

                                <!-- Developer API -->
                                <div class="border-bottom py-150">
                                    <h4 class="pb-50">{{ 'settings.members.form.developer.portal' | translate }}</h4>
                                    <fieldset class="form-group mb-0">
                                        <div class="custom-control custom-checkbox sk-checkbox">
                                            <input [checked]="member.isDeveloper" (change)="toggleDeveloperStatus()"
                                                type="checkbox" class="custom-control-input" id="isDeveloperCheck">
                                            <label class="custom-control-label" for="isDeveloperCheck">
                                                {{ 'settings.members.form.developer.access-api' | translate }}
                                            </label>
                                            <small class="sk-form-text" [innerHtml]="'settings.members.form.developer.agreement-text'
                                                | translate: { devPortalPageUrl: getDevPortalPageUrl(), termsPageUrl: getTermsPageUrl() }">
                                            </small>
                                        </div>
                                    </fieldset>
                                </div>

                                <!-- Authentication -->
                                <div class="py-150">
                                    <h4 class="pb-50">{{ 'settings.common.authentication.authentication' | translate }}</h4>
                                    <fieldset class="form-group mb-0">
                                        <div class="d-inline-flex">
                                            <p class="badge badge-default" [class.badge-info]="isM365Authentication()">
                                                {{ getAuthTypeLocKey() | translate }}
                                            </p>
                                        </div>
                                        <small class="sk-form-text" [innerHtml]="'settings.common.authentication.to-change-auth'
                                            | translate: { accountSettingsPageUrl: getAccountSettingsPageUrl() }">
                                        </small>
                                    </fieldset>
                                </div>
                            </div>
                        </div>
                        <!-- /.col -->
                        <div class="col-2">
                        </div>
                        <!-- /.col -->
                    </div>
                    <!-- /.row -->
                </div>
            </div>
        </div>
        <!-- /.sk-flex-table -->
    </div>
    <!-- /.sk-content-primary -->

    <div *ngIf="member" class="sk-page-footer">
        <!-- Large Buttons -->
        <div class="sk-footer-content">
            <button *ngIf="!isM365Authentication() && !editState" (click)="sendInvite()" [disabled]="isSubmitDisabled()" type="button"
                class="btn btn-lg btn-primary mr-100">{{ 'settings.members.form.send-invite' | translate }}</button>
            <button *ngIf="isM365Authentication() && !editState" (click)="addM365Member()" [disabled]="isSubmitDisabled()" type="button"
                class="btn btn-lg btn-primary mr-100">{{ 'settings.members.form.add-member' | translate }}</button>
            <button *ngIf="editState" (click)="updateMember()" [disabled]="updating ? true : null" type="button"
                class="btn btn-lg btn-primary mr-100">{{ 'settings.members.actions.update-member' | translate }}</button>
            <button (click)="cancel()" type="button" class="btn btn-lg btn-outline mr-150">{{ 'settings.common.cancel' | translate }}</button>
            <div *ngIf="!isFormValid()" class="d-flex align-items-center">
                <i class="material-icons md-20 icon-danger">error</i>
                <small class="text-danger ml-50">{{ 'settings.members.form.complete-form' | translate }}</small>
            </div>
        </div>
    </div>
</div>
