import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
} from '@angular/common/http';
import { Injectable } from '@angular/core';

import {
  Observable,
  throwError,
} from 'rxjs';
import { catchError } from 'rxjs/operators';

import {
  AppId,
  RouteResolverService,
} from '@skykick/core';

import { PartnerInfoResponse } from '../models/PartnerInfoResponse';

@Injectable({
  providedIn: 'root'
})
export class DeveloperService {

  constructor(
    private http: HttpClient,
    private routeResolverService: RouteResolverService
  ) { }

  private handleError(errorResponse: HttpErrorResponse): Observable<never> {
    if (errorResponse.error instanceof ErrorEvent) {
        console.error('Client Side Error: ', errorResponse.error.message);
    } else {
        console.error('Server Side Error: ', errorResponse);
    }
    console.log('There is a problem with the service. We are notified & working on it. Please try again later.');
    return throwError(errorResponse);
}

public getPartnerInfo (): Observable<PartnerInfoResponse>{
  const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

      return this.http.get<PartnerInfoResponse>(`${this.routeResolverService.generatePipeRoute(AppId.Papi)}webapi/partner/info/me`, { headers })
          .pipe(catchError(this.handleError));
}
}
