import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppId, RouteResolverService } from '@skykick/core';
import { AbstractUserProvider } from '@skykick/platform-identity-auth-auth0-angular';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ProductLevelPreferences } from '../models/ProductLevelPreferences';

@Injectable({
  providedIn: 'root',
})
export class NotificationsResourceService {
  constructor(
    private http: HttpClient,
    private userProvider: AbstractUserProvider,
    private routeResolverService: RouteResolverService
  ) {}

  private handleError(errorResponse: HttpErrorResponse) {
    if (errorResponse.error instanceof ErrorEvent) {
      console.error('Client Side Error: ', errorResponse.error.message);
    } else {
      console.error('Server Side Error: ', errorResponse);
    }
    console.log(
      'There is a problem with the service. We are notified & working on it. Please try again later.'
    );
    return throwError(errorResponse);
  }

  private getNotificationsApiRoute():string{
    return this.routeResolverService.generatePipeRoute(AppId.PlatformApis) + "notifications"
  }

  getProductLevelPreferences(
    productId: number
  ): Observable<ProductLevelPreferences> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    return this.http
      .get<ProductLevelPreferences>(
        `${this.getNotificationsApiRoute()}/api/v1/notifications/partners/${
          this.userProvider?.getCurrentUser().partnerId
        }/preferences/products/${productId}`,
        { headers, withCredentials: true }
      )
      .pipe(catchError(this.handleError));
  }

  syncProductLevelPreferences(
    payload: ProductLevelPreferences
  ): Observable<ProductLevelPreferences> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    return this.http
      .post<ProductLevelPreferences>(
        `${this.getNotificationsApiRoute()}/api/v1/notifications/partners/${
          this.userProvider?.getCurrentUser().partnerId
        }/preferences/products`,
        JSON.stringify(payload),
        { headers }
      )
      .pipe(catchError(this.handleError));
  }
}
