import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { TaskManagerService } from '@skykick/core';
import { ToastrService } from 'ngx-toastr';
import { forkJoin, merge, Observable, of, Subject } from 'rxjs';
import { catchError, map, takeUntil, debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { TaskType } from 'src/app/settings/models/task-type';
import { SKYKICKPRODUCTS } from '../../core/constants/skykick-products.const';
import { ConnectwiseResourcesService } from '../../core/services/connectwise-resources.service';
import { ConnectwiseStateManagerService } from '../../core/services/connectwise-state-manager.service';

@Component({
    selector: 'sk-product-mapping',
    templateUrl: './product-mapping.component.html',
    styleUrls: ['./product-mapping.component.scss']
})
export class ProductMappingComponent implements OnInit, OnDestroy {
    private onDestroy$ = new Subject();
    skykickProducts = SKYKICKPRODUCTS.slice();//hard-coded, who knew?
    productMappingForm: FormGroup;
    isLoadingProductMappings: boolean;
    connectWiseProducts: Array<string>;
    productMappings: Array<any>;
    focus$ = [0,1,2].map(_=>new Subject<string>());

    private updateSkyKickProducts(productMappings: Array<any>) {
        productMappings.forEach(item => {
            let productIndex = this.skykickProducts.findIndex(({source}) => source === item.source);
            this.skykickProducts[productIndex] = { ...this.skykickProducts[productIndex], ...item };
        });
    }

    private validProductMapping(): ValidatorFn {
        return (controlGroup: FormGroup): ValidationErrors => {
            const fa =  controlGroup.get('products') as FormArray;
            const controls = fa.controls;
            if (controls) {
                let theOne = Object.keys(controls).find(key => controls[key].value.destination !== undefined || controls[key].value.destination !== null);
                //TODO this should probabably be on the Form level and check to make sure all "destination" exist in the CW Service Name array
                if (!theOne) {
                    return { atLeastOneRequired: true }
                }
            }
            return null;
        };
    }

    constructor(
        private formBuilder: FormBuilder,
        private connectwiseResourcesService: ConnectwiseResourcesService,
        private toastrService: ToastrService,
        private connectwiseStateManagerService: ConnectwiseStateManagerService,
        private taskManagerService: TaskManagerService
    ) { }

    get productMappingFormArray() {
        return this.productMappingForm.get('products') as FormArray;
    }

    get product(): FormGroup {
        return this.formBuilder.group({
            destination: [null, [Validators.required]]
        });
    }

    ngOnInit(): void {
        this.productMappingForm = this.formBuilder.group(
            { products: this.formBuilder.array([]) },
            //{ validators: [this.validProductMapping()] }
        );
        this.skykickProducts.forEach(() => {
            this.productMappingFormArray.push(this.product);
        });


        //grab product mappings
        this.isLoadingProductMappings = true;
        this.skykickProducts.forEach(item => {
            item.partnerId = this.connectwiseStateManagerService.getConnectWisePartner().partnerId;
        });
        forkJoin([
            this.connectwiseResourcesService.searchConnectWiseProducts('*').pipe(
                catchError(() => {
                    return of([]);
                })
            ),
            this.connectwiseResourcesService.getProductMappings().pipe(
                catchError(() => {
                    return of([]);
                })
            )
        ]).pipe(
            takeUntil(this.onDestroy$)
        ).subscribe(res => {
            this.connectWiseProducts = res[0];
            this.productMappings = res[1];
            this.updateSkyKickProducts(this.productMappings);
            this.productMappingFormArray.patchValue(this.skykickProducts);
            this.isLoadingProductMappings = false;
        });
    }

    createProductMappings() {
        this.skykickProducts.forEach((item, index) => {
            if (item.destination && !item.productMappingId) {
                let payload = { partnerId: item.partnerId, destination: item.destination, source: item.source };
                this.connectwiseResourcesService.createProductMapping(payload).pipe(
                    takeUntil(this.onDestroy$)
                ).subscribe(res => {
                    this.skykickProducts[index] = { ...this.skykickProducts[index], ...res };
                    this.toastrService.success('Update successful');
                });
            }
        });
        this.taskManagerService.abortTask(TaskType.ConnectWiseInfo);
    }

    removeMapping(mapping: any) {
        mapping.active = true;
        this.connectwiseResourcesService.deleteProductMapping(mapping).pipe(
            takeUntil(this.onDestroy$)
        ).subscribe(() => {
            mapping.destination = mapping.mappingDate = mapping.productMappingId = null;
            mapping.active = false;
            this.toastrService.success('Update successful');
        });
        this.taskManagerService.abortTask(TaskType.ConnectWiseInfo);
    }

    searchType(index: number) {
        return (text$: Observable<string>) => {
            const debouncedText$ = text$.pipe(
                debounceTime(200),
                distinctUntilChanged()
            );
            const inputFocus$ = this.focus$[index];

            return merge(debouncedText$, inputFocus$).pipe(
                map(term =>
                    (term === ""
                        ? this.connectWiseProducts
                        : this.connectWiseProducts.filter(
                            v => v.toLowerCase().indexOf(term.toLowerCase()) > -1
                        )
                    ).slice(0, 10)
                )
            );
        };
    }

    ngOnDestroy(): void {
        this.onDestroy$.next();
    }

}
