import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AbstractUserProvider } from '@skykick/platform-identity-auth-auth0-angular';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { CONNECTWISE_CONSTANTS } from 'src/app/settings/integrations/connectwise/core/constants/connect-wise.const'
import { AppId, RouteResolverService } from '@skykick/core';
import { CompanyMapping } from '../models/CompanyMapping'

@Injectable({
    providedIn: 'root'
})
export class ConnectwiseResourcesService {
    private handleError(errorResponse: HttpErrorResponse) {
        if (errorResponse.error instanceof ErrorEvent) {
            console.error('Client Side Error: ', errorResponse.error.message);
        } else {
            console.error('Server Side Error: ', errorResponse);
        }
        console.log('There is a problem with the service. We are notified & working on it. Please try again later.');
        return throwError(errorResponse);
    }

    constructor(
        private http: HttpClient,
        private abstractUserProvider: AbstractUserProvider,
        private routeResolverService: RouteResolverService
    ) { }

    getCredentials(): Observable<any> {
        return this.http.get<any>(`${this.routeResolverService.generatePipeRoute(AppId.Papi)}connectwise/${this.abstractUserProvider.getCurrentUser().partnerId}/credentials`)
            .pipe(catchError(this.handleError));
    }

    validateCredentials(payload: any): Observable<any> {
        let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        return this.http.put(`${this.routeResolverService.generatePipeRoute(AppId.Papi)}connectwise/${this.abstractUserProvider.getCurrentUser().partnerId}/credentials/validate`, JSON.stringify(payload), { headers: headers })
            .pipe(catchError(this.handleError));
    }

    getConnectionStatus(): Observable<boolean> {
        return this.http.get<boolean>(`${this.routeResolverService.generatePipeRoute(AppId.Papi)}connectwise/${this.abstractUserProvider.getCurrentUser().partnerId}/credentials/connection-status`)
            .pipe(catchError(this.handleError));
    }

    updateCredentials(payload: any): Observable<any> {
        let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        return this.http.put(`${this.routeResolverService.generatePipeRoute(AppId.Papi)}connectwise/${this.abstractUserProvider.getCurrentUser().partnerId}/credentials`, JSON.stringify(payload), { headers: headers })
            .pipe(catchError(this.handleError));
    }

    saveCredentials(payload: any): Observable<any> {
        let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        return this.http.post(`${this.routeResolverService.generatePipeRoute(AppId.Papi)}connectwise/${this.abstractUserProvider.getCurrentUser().partnerId}/credentials`, JSON.stringify(payload), { headers: headers })
            .pipe(catchError(this.handleError));
    }

    getPartner(): Observable<any> {
        return this.http.get<any>(`${this.routeResolverService.generatePipeRoute(AppId.Papi)}connectwise/${this.abstractUserProvider.getCurrentUser().partnerId}`)
            .pipe(catchError(this.handleError));
    }

    createPartner(payload: any): Observable<any> {
        let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        return this.http.post(`${this.routeResolverService.generatePipeRoute(AppId.Papi)}connectwise`, JSON.stringify(payload), { headers: headers })
            .pipe(catchError(this.handleError));
    }

    updatePartner(payload: any): Observable<any> {
        let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        return this.http.put(`${this.routeResolverService.generatePipeRoute(AppId.Papi)}connectwise`, JSON.stringify(payload), { headers: headers })
            .pipe(catchError(this.handleError));
    }

    deletePartner(): Observable<any> {
      return this.http.delete(`${this.routeResolverService.generatePipeRoute(AppId.Papi)}connectwise/${this.abstractUserProvider.getCurrentUser().partnerId}`)
          .pipe(catchError(this.handleError));
    }

    getSkyKickProducts(): Observable<any> {
        return this.http.get<any>(`${this.routeResolverService.generatePipeRoute(AppId.Papi)}connectwise/${this.abstractUserProvider.getCurrentUser().partnerId}/skykick-products`)
            .pipe(catchError(this.handleError));
    }

    searchConnectWiseProducts(searchTerm: string) {
        return this.http.get<Array<string>>(`${this.routeResolverService.generatePipeRoute(AppId.Papi)}connectwise/${this.abstractUserProvider.getCurrentUser().partnerId}/connectwise-products?searchTerm=${encodeURIComponent(searchTerm)}`)
          .pipe(
              map(result => result
                  ? result.sort((x:any, y:any) => x.toLowerCase() < y.toLowerCase() ? -1 : 1)
                  : []),
              catchError(this.handleError)
          );;
    }

    getProductMappings(): Observable<any> {
        return this.http.get<any>(`${this.routeResolverService.generatePipeRoute(AppId.Papi)}connectwise/${this.abstractUserProvider.getCurrentUser().partnerId}/product-mappings`)
            .pipe(catchError(this.handleError));
    }

    deleteProductMapping(mapping: any): Observable<any> {
        return this.http.delete<any>(`${this.routeResolverService.generatePipeRoute(AppId.Papi)}connectwise/${this.abstractUserProvider.getCurrentUser().partnerId}/product-mappings/${mapping.productMappingId}`)
            .pipe(catchError(this.handleError));
    }

    createProductMapping(payload: any): Observable<any> {
        let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        return this.http.post(`${this.routeResolverService.generatePipeRoute(AppId.Papi)}connectwise/${this.abstractUserProvider.getCurrentUser().partnerId}/product-mappings`, JSON.stringify(payload), { headers: headers })
            .pipe(catchError(this.handleError));
    }

    getCompanyMappings(payload: any): Observable<any> {
        let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        return this.http.post(`${this.routeResolverService.generatePipeRoute(AppId.Papi)}connectwise/${this.abstractUserProvider.getCurrentUser().partnerId}/companies/search`, JSON.stringify(payload), { headers: headers })
            .pipe(catchError(this.handleError));
    }

    getOrderMappings(): Observable<any> {
        return this.http.get<Array<any>>(`${this.routeResolverService.generatePipeRoute(AppId.Papi)}connectwise/${this.abstractUserProvider.getCurrentUser().partnerId}/companies/search/order-mappings`)
          .pipe(catchError(this.handleError));
    }

    saveOrderMapping(payload: any): Observable<any> {
        let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        return this.http.post(`${this.routeResolverService.generatePipeRoute(AppId.Papi)}connectwise/${this.abstractUserProvider.getCurrentUser().partnerId}/companies/order`, JSON.stringify(payload), { headers: headers })
            .pipe(catchError(this.handleError));
    }

    updateOrderMapping(payload: any): Observable<any> {
        let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        return this.http.put(`${this.routeResolverService.generatePipeRoute(AppId.Papi)}connectwise/${this.abstractUserProvider.getCurrentUser().partnerId}/companies/order`, JSON.stringify(payload), { headers: headers })
            .pipe(catchError(this.handleError));
    }

    deleteOrderMapping(mapping: any): Observable<any> {
        let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        return this.http.delete(`${this.routeResolverService.generatePipeRoute(AppId.Papi)}connectwise/${this.abstractUserProvider.getCurrentUser().partnerId}/companies/order/${mapping.orderMappingId}`, { headers: headers })
            .pipe(catchError(this.handleError));
    }

    searchConnectWiseAccountNames(searchTerm: string, pageSize: number): Observable<CompanyMapping[]> {
        return this.http.get<any>(`${this.routeResolverService.generatePipeRoute(AppId.Papi)}connectwise/${this.abstractUserProvider.getCurrentUser().partnerId}/companies?searchTerm=${encodeURIComponent(searchTerm)}&itemLimit=${pageSize}`)
            .pipe(
                map(result => result
                    ? (result as CompanyMapping[])
                        .sort((x:any, y:any) => x.companyName.toLowerCase() < y.companyName.toLowerCase() ? -1 : 1)
                    : [] as CompanyMapping[]),
                catchError(this.handleError)
            );
    }

    getConnectWiseAgreements(companyIdentifier: string) {
        return this.http.get<Array<any>>(`${this.routeResolverService.generatePipeRoute(AppId.Papi)}connectwise/${this.abstractUserProvider.getCurrentUser().partnerId}/companies?companyIdentifier=${companyIdentifier}`)
            .pipe(catchError(this.handleError));
    }

    syncBillingInfo(): Observable<any> {
        let payload = { partnerId: this.abstractUserProvider.getCurrentUser().partnerId };
        let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        return this.http.post(`${this.routeResolverService.generatePipeRoute(AppId.Papi)}connectwise/${this.abstractUserProvider.getCurrentUser().partnerId}/sync-billing/${CONNECTWISE_CONSTANTS.Backup}`, JSON.stringify(payload), { headers: headers })
            .pipe(catchError(this.handleError));
    }

    getServiceBoards(): Observable<any> {
        return this.http.get<any>(`${this.routeResolverService.generatePipeRoute(AppId.Papi)}connectwise/${this.abstractUserProvider.getCurrentUser().partnerId}/boards?searchTerm=*`)
            .pipe(catchError(this.handleError));
    }

    getTicketMapping(): Observable<any> {
        return this.http.get<any>(`${this.routeResolverService.generatePipeRoute(AppId.Papi)}connectwise/${this.abstractUserProvider.getCurrentUser().partnerId}/tickets?ticketMappingType=1`)
            .pipe(catchError(this.handleError));
    }

    deleteTicketMapping(ticketMappingId: string): Observable<any> {
        return this.http.delete<any>(`${this.routeResolverService.generatePipeRoute(AppId.Papi)}connectwise/${this.abstractUserProvider.getCurrentUser().partnerId}/tickets/${ticketMappingId}`)
            .pipe(catchError(this.handleError));
    }

    addTicketMapping(board: string): Observable<any> {
        let payload = { partnerId: this.abstractUserProvider.getCurrentUser().partnerId, board: board };
        let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        return this.http.post(`${this.routeResolverService.generatePipeRoute(AppId.Papi)}connectwise/${this.abstractUserProvider.getCurrentUser().partnerId}/tickets`, JSON.stringify(payload), { headers: headers })
            .pipe(catchError(this.handleError));
    }

}
