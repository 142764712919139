import { Component, HostBinding, OnInit } from '@angular/core';

import { AbstractUserProvider } from '@skykick/platform-identity-auth-auth0-angular';

import { MembersRoleProvider } from '../users/components/members/services/members.role.provider';

@Component({
  selector: 'sk-left-nav',
  templateUrl: './left-nav.component.html',
  styleUrls: ['./left-nav.component.scss'],
})
export class LeftNavComponent implements OnInit {
  @HostBinding('class') classes = 'sk-panel-left sk-panel-primary panel-xs-1';

  isCurrentUserAdmin: boolean;

  constructor(private abstractUserProvider: AbstractUserProvider) {}

  ngOnInit(): void {
    this.isCurrentUserAdmin = this.abstractUserProvider
      .getCurrentUser()
      .hasPermission(MembersRoleProvider.PartnerPortalAdmin.key);
  }
}
