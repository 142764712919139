import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subject } from 'rxjs';
import { switchMap, takeUntil, tap, map } from 'rxjs/operators';
import { CompanyInfo } from '../../../models/company-info';
import { Distributor } from '../../../models/distributor';
import { DistributorRelationship } from '../../../models/distributor-relationship';
import { AccountResourcesService } from '../../../services/account-resources.service';
import { DistributorsResourcesService } from '../../../services/distributors-resources.service';

@Component({
    selector: 'sk-add-edit-distributor-relationship',
    templateUrl: './add-edit-distributor-relationship.component.html',
    styleUrls: ['./add-edit-distributor-relationship.component.scss']
})
export class AddEditDistributorRelationshipComponent implements OnInit, OnDestroy {
    private onDestroy$ = new Subject();
    @Input() distributorRelationships: DistributorRelationship[];
    @Input() distributorRelationship: DistributorRelationship;
    pageTitle = this.translate.instant('settings.account.DISTRIBUTOR_ADD_DISTRIBUTOR');
    positiveButton = this.translate.instant('settings.account.DISTRIBUTOR_ADD_DISTRIBUTOR');
    negativeButton = this.translate.instant('settings.account.DISTRIBUTOR_CANCEL');
    form: FormGroup;
    companyInfo: CompanyInfo;
    isLoading = true;
    availableDistributors$: Observable<Distributor[]>;
    errors: Array<string> = [];
    isUpdating: boolean;

    constructor(
        public activeModal: NgbActiveModal,
        private formBuilder: FormBuilder,
        private toastrService: ToastrService,
        private translate: TranslateService,
        private accountResourcesService: AccountResourcesService,
        private distributorsResourcesService: DistributorsResourcesService
    ) { }

    ngOnInit(): void {
        this.form = this.formBuilder.group({
            distributorId: [{ value: null, disabled: true }, Validators.required],
            resellerId: [null, Validators.required]
        });

        if (this.distributorRelationship) {
            this.pageTitle = this.translate.instant('settings.account.DISTRIBUTOR_EDIT_DISTRIBUTOR');
            this.positiveButton = this.translate.instant('settings.account.DISTRIBUTOR_SAVE_CHANGES');
            this.form.patchValue(this.distributorRelationship);
        }

        this.availableDistributors$ = this.accountResourcesService.getCompanyInfo().pipe(
            takeUntil(this.onDestroy$),
            tap(companyInfo => this.companyInfo = companyInfo),
            switchMap(companyInfo => this.distributorsResourcesService.getDistributorsByCountry(companyInfo.selectedCountryId).pipe(
                map(distributors => {
                    if (this.distributorRelationship) {
                        return distributors;
                    } else {
                        let availableDistributors = distributors.filter(distributor => {
                            if (!this.distributorRelationships.map(item => item.distributorAffliation).includes(distributor.id)) {
                                return distributor;
                            }
                        });
                        return availableDistributors;    
                    }
                }),
                tap(res => {
                    if (this.distributorRelationship) {
                        this.form.get('distributorId').setValue(this.distributorRelationship.distributorAffliation);
                        let distributor = res.find(item => item.id === this.distributorRelationship.distributorAffliation);
                        if (distributor) {
                            distributor.name === this.distributorRelationship.distributorName;
                        }
                    } else {
                        this.form.get('distributorId').enable();
                    }
                    this.isLoading = false;
                })    
            ))
        );

    }

    isFieldValid(field: string, type?: string) {
        if (type) {
            return this.form.get(field).hasError(type) && !this.form.get(field).pristine && !this.form.disabled;
        }
        return !this.form.get(field).valid && !this.form.get(field).pristine && !this.form.disabled;
    }

    displayFieldCss(field: string) {
        return {
            'has-danger': this.isFieldValid(field)
        };
    }

    submit() {
        if (this.form.valid) {
            this.errors.length = 0;
            if (this.distributorRelationship) {
                this.isUpdating = true;
                let payload = {
                    previousDistributorId: this.distributorRelationship.distributorId,
                    newDistributorId: this.distributorRelationship.distributorId,
                    previousDistributorPartnerId: this.distributorRelationship.resellerId,
                    newDistributorPartnerId: this.form.get('resellerId').value
                };
                this.distributorsResourcesService.updateDistributorRelationship(payload).pipe(
                    takeUntil(this.onDestroy$)
                ).subscribe(res => {
                    this.isUpdating = false;
                    this.toastrService.success('Successfully updated.');
                }, res => {
                    if (res.error?.message) {
                        this.errors.push(res.error.message);
                    }
                    this.isUpdating = false;
                });
            } else {
                this.isUpdating = true;
                this.distributorsResourcesService.getDistributorIdFromRelationship(this.companyInfo.selectedCountryId, this.form.get('distributorId').value).pipe(
                    takeUntil(this.onDestroy$),
                    switchMap(distributorId => this.distributorsResourcesService.addDistributorRelationship({
                        distributorId: distributorId.id,
                        distributorPartnerId: this.form.get('resellerId').value
                    }).pipe(
                        takeUntil(this.onDestroy$)
                    ))
                ).subscribe(() => {
                    this.isUpdating = false;
                    this.toastrService.success('Successfully added.');
                    this.activeModal.close();
                }, res => {
                    if (res.error?.message) {
                        this.errors.push(res.error.message);
                    }
                    this.isUpdating = false;
                });
            }
        }
    }

    ngOnDestroy(): void {
        this.onDestroy$.next();
    }

}
